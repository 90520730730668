import React, { forwardRef, memo, useEffect, useReducer, useState } from "react";
import {  
  Box,  
  Button,  
  CircularProgress,  
  LinearProgress,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { ArrowBack, Delete, HideImageOutlined, Image, Place, Upload } from "@mui/icons-material";
import { CenteredBox } from "../../../../components/layouts/OneViewBox";
import ImageComponent from "../../../../components/ImageComponent";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../../store/actions/commonAction";
import { callSnackBar } from "../../../../store/actions/snackbarAction";

import {
  SNACK_BAR_VARIETNS,
} from "../../../../utils/constants/constants";

import {
  toTitleCase,
} from "../../../../utils/helpers/helper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageUploadComponent = memo(
  ({ loading, onChange, percentage, onDeleteFile, files, name, camp_id, disabled, subtitle }) => {
    // const selectedComponet = useSelector((state) => state.selectedComponent.ivalue);

    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);

    const deleteRecordFromDb = async (callBack = () => { }) => {
      dispatch(
        callApiAction(
          // async () => await deleteCampImagesField({ camp_id, image_name: name }),
          async (response) => {
            callBack(response);
          },
          (err) => {
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
            callBack();
          }
        )
      );
    };

    const onDelete = () => {
      if (disabled)
        return
      setDeleteLoading(true);
      onDeleteFile(files[0], () => {
        deleteRecordFromDb(() => {
          setDeleteLoading(false);
        });
      });
    };

    return (
      <Box component={Paper} sx={{ width: "100%", background: "#e5e5e5" }}>
        <Box px={2} pt={1} pb={1}>
          <Typography variant="subtitle2" fontWeight={600} lineHeight="100%">
            {toTitleCase(name?.replace(/_/g, " "))}
          </Typography>
        </Box>
        <Box
          component={Paper}
          elevation={0}
          mt={1}
          mb={1}
          sx={{
            display: "flex",
            width: "100%",
            position: "relative",
            paddingTop: "75%",
            border: "1px solid gray",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              width: "100%",
              height: "100%",
            }}
          >
            {files[0] && !loading && !deleteLoading ? (
              <ImageComponent
                imgStyle={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={files[0]}
              />
            ) : (
              <CenteredBox>
                {loading ? (
                  <LinearProgress
                    sx={{ zIndex: 1, width: "100%" }}
                    variant="determinate"
                    value={percentage}
                  />
                ) : deleteLoading ? (
                  <CircularProgress />
                ) : (
                  subtitle ? <Typography variant="caption" >{subtitle}</Typography> : <HideImageOutlined />
                )}
              </CenteredBox>
            )}
          </Box>
        </Box>

        <Box>
          <Box>
            {!files[0] && (
              <Button
                sx={{ width: "100%" }}
                component="label"
                mt={2}
                startIcon={<Upload />}
                disableElevation
                disabled={loading || disabled || deleteLoading}
                variant="contained"
              >
                {/* <Upload />Upload */}
                <Typography sx={{ fontSize: "12px" }}>Upload</Typography>
                <VisuallyHiddenInput
                  // accept="image/*"
                  accept="image/*"
                  capture="camera"
                  onChange={onChange}
                  type="file"
                />
              </Button>
            )}

            {files[0] && (
              <Button
                onClick={onDelete}
                component="label"
                color="error"
                mt={2}
                startIcon={<Delete />}
                disableElevation
                disabled={loading || disabled || deleteLoading}
                variant="contained"
                size="small"
                fullWidth
              >
                {/* <Upload />Upload */}
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);
export default ImageUploadComponent