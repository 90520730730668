import { Navigate } from "react-router-dom";
import { moduleAccessCondition } from "./helper";
import { Dashboard } from "@mui/icons-material";

export const defaultRedirect = (user) => {
  if (!user.isLoggedIn) {
    return "/sign-in";
  }
  if (user.isLoggedIn) {
    return "/jobs";
  }

  return "/not-allowed";
};
export const routeObjGenerator = (userObj, redirectDefault) => {
  return (
    path,
    title,
    Icon,
    element,
    children,
    customCondition,
    redirectComponent = <Navigate replace to={redirectDefault} />
  ) => {
    let pathIsAllowed = true;

    if (typeof customCondition != "undefined") {
      pathIsAllowed = customCondition;
    }

    return {
      path,
      title,
      icon: Icon ? <Icon /> : <Dashboard />,
      element: pathIsAllowed ? element : redirectComponent,
      children,
    };
  };
};
