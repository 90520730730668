import { actions } from "../../utils/constants/constants";

export const hardRefreshInspectionData = () => {
  return { type: actions.HARD_REFRESH_INSPECTION_DATA}
}
export const updateInspectionFilters = (filters) => {
  return { type: actions.UPDATE_INSPECTION_DATA_FILTERS,value:filters }
}
export const updateInspectionCountsData = (data) => {
  return { type: actions.UPDATE_INSPECTION_DATA_COUNTS,value:data }
}
export const updateInspectionListData = (data) => {
  return { type: actions.UPDATE_INSPECTION_DATA_LIST,value:data }
}
