import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { Fragment, memo, useEffect, useState } from "react";
import moment from "moment";
import { AccessTime, Business, Campaign, LockClock, Person, Schedule } from "@mui/icons-material";
import SubmitButton from "../../components/button/SubmitButton";
import { useTheme } from "@emotion/react";
import UpdateSignatureController from "../signature/UpdateSignatureController";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import { getAggrementByIdApi } from "../../apis/aggrement.api";
import { callApiAction } from "../../store/actions/commonAction";
import { Link, useParams } from "react-router-dom";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { groupByArray } from "../../utils/helpers/helper";
import ImageComponent from "../../components/ImageComponent";
import DownloadPdfButton from "./DownloadPdfButton";

const PriceListView = ({ pricelist_array }) => {


  const priceListGroupedByCategoryObj = groupByArray(pricelist_array, 'category')
  return <>

    <Typography variant="h6" sx={{ marginY: "10px" }}>
      Price List :
    </Typography>
    {
      Object.keys(priceListGroupedByCategoryObj).length==0&&<CenteredBox>
        No Price list
      </CenteredBox>
    }
    {
     Object.keys(priceListGroupedByCategoryObj)?.length>0 && Object.keys(priceListGroupedByCategoryObj).map((category) => {
        return <Fragment key={category} >
          <Box component={Paper} mb={2} border={1}  borderLeft={5} elevation={0} borderColor="divider">
            <Box p={2} borderBottom={1}  >
              <Typography >
                {category}
              </Typography>
            </Box>
            <TableContainer>
              <Table size="small">

                <TableBody>
                  {priceListGroupedByCategoryObj[category].map((item, index) => (
                    <TableRow>
                      <TableCell  width="40%"> <Typography variant="caption"> {item.key}</Typography> </TableCell>
                      <TableCell><Typography variant="caption">{item.value}</Typography></TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Fragment>
      })
    }

  </>
}
const AggrementDetailViewUi = ({ }) => {
  const theme = useTheme();
  const dispatch = useDispatch()

  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [aggrementData, setAggrementData] = useState({})
  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getAggrementByIdApi({ id }),
        async (response) => {
          // setFields({ ...fields, ...response });
          setAggrementData(response);
          setLoading(false);
        },
        (err) => {
          setAggrementData({ err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (params.id) fetchById(params.id);
  }, [params.id]);

  const onSignature = (id) => {
    dispatch(openModal(<UpdateSignatureController id={id} callBack={()=>{fetchById(params.id)}} />, 'xs', undefined, "signature"))
  }
  if (loading) {
    return <CenteredBox component={Paper}>
      <CircularProgress />
    </CenteredBox>
  }
  return (

    <>

      <Box sx={{ minHeight: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}  >
        <Box
          mb={2}
          sx={{
            padding: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
            border: "1px solid #ccc",

          }}
        >
          <Breadcrumbs>
            <Link to={"/aggrement"} sx={{ cursor: "pointer" }}>
              <Typography sx={{ color: "text.primary" }}>Aggrement</Typography>
            </Link>
            <Typography sx={{ color: "text.primary" }}>Details</Typography>
          </Breadcrumbs>
        </Box>
        <Box component={Paper} display="flex" flexDirection="column" flex={1} p={3}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "5px" }}>
              <Typography
                variant="body1"
                lineHeight="100%"
                fontWeight={600}
                mb={2}
              >
                {aggrementData.title}
              </Typography>
              <Typography component={Box} variant="subtitle2" display="flex" alignItems="center" lineHeight="100%" ml={2} mb={2}>
                <Business fontSize="inherit" />&nbsp;{aggrementData.company_id?.name}
              </Typography>
              <Typography component={Box} variant="subtitle2" display="flex" alignItems="center" lineHeight="100%" ml={2} >
                <LockClock fontSize="inherit" />&nbsp;{moment(aggrementData.date).format("DD/MM/YYYY")} - {moment(aggrementData.expiry_date).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Box>

          <Box mt={1}>
            <Typography variant="body1">Aggrement Words :</Typography>
            <Typography
              variant="body2"
              sx={{
                border: "1px solid",
                borderLeft: "5px solid",
                borderColor: "divider",
                overflowY: "scroll",
                padding: "5px",
                maxHeight: "50vh",
                "*": {
                  color: "grey"
                }
              }}
              dangerouslySetInnerHTML={{
                __html: aggrementData.aggrement_words,
              }}
            >

            </Typography>
          </Box>
          <PriceListView pricelist_array={aggrementData?.price_list ?? []} />
          {aggrementData.signature &&
            <Box>
              <Typography>
                Signature
              </Typography>
              <Box sx={{width:"100%",maxWidth:"200px"}} >
                <ImageComponent src={aggrementData.signature} />
              </Box>
              <Typography>{aggrementData.signed_date?moment(aggrementData.signed_date).format("DD/MM/YYYY HH:mm"):""}</Typography>
            </Box>}
          <Box py={2} component={Paper} elevation={0} sx={{ display: "flex", position: "sticky", bottom: "0px", gap: '5px' }}>
            {/* <SubmitButton title="Download" />
             */}
             <DownloadPdfButton title={aggrementData?.title} id={aggrementData?._id} />
            {!aggrementData.signature && <SubmitButton title="e-sign" onClick={() => onSignature(aggrementData._id)} />}
          </Box>
          {/* </Paper> */}
        </Box>
      </Box>

    </>

  );
};
// });

export default AggrementDetailViewUi;
