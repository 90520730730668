import { Box } from "@mui/material"
import { CenteredBox } from "./boxes"
import { useSelector } from "react-redux"
import ImageComponent from "../../ImageComponent"
// import LogoImage from './../../../assets/images/logo.png'
const Logo = ({ customHeight = "30px", customWidth = "65px", ...rest }) => {

    const { user } = useSelector(state => state)
    const LogoImage = process.env.PUBLIC_URL + '/' + process.env.REACT_APP_LOGO_SLUG
    return <CenteredBox  {...rest}>
        {
            user?.data?.company_id?.logo ? <ImageComponent cachePermanent={true} noClickEffect src={user?.data?.company_id?.logo} sx={{ width: "100%",  height: customHeight, objectFit: "contain" }} /> :
                <img src={LogoImage} style={{ width: "100%", maxWidth: customWidth, height: customHeight, objectFit: "contain" }} alt="" />}
    </CenteredBox>
}
export default Logo