import { Box, Button, ButtonBase, Divider, IconButton, LinearProgress, Paper, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { CALENDAR_ITEMS_TYPES, LOG_TYPE, SNACK_BAR_VARIETNS } from "../../utils/constants/constants"

import { useDispatch, useSelector } from "react-redux"

import { CenteredBox } from "../../components/layouts/OneViewBox"
import { memo, useContext, useEffect, useMemo, useState } from "react"
import moment from "moment"
import { addShiftApi, deleteShiftApi } from "../../apis/shift.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { callApiAction } from "../../store/actions/commonAction"

import { getShiftIcon } from "../../utils/helpers/installershift.helper"
import { INSTALLATION_CALENDAR_ITEM_TYPE, jobItemColor } from "../../utils/constants/installercalendar.constant"
import { addAvailablityAction, removedAvailablityAction } from "../../store/actions/installationcalendarAction"
import { groupByArray } from "../../utils/helpers/helper"
import { useNavigate } from "react-router-dom"



const SmallCalendarButton = styled(ButtonBase)(({ theme }) => ({
    height: "35px",
    width: "35px",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    lineHeight: "100%",
    backgroundColor: "#f5f5f5",
    flexDirection: "column"

}))
const LeaveViewBox = styled(ButtonBase)(({ theme, color, background }) => ({


    position: "relative",
    zIndex: 1,
    width: "100%",

    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: '100%',
        width: "100%",
        background: background ?? color,
        // opacity: 0.2,
        zIndex: 0,

    },
    "*": {
        color: color + "!important"
    }
}))
const MobileViewDesktop = ({ data, date, weekDay, calendarDate, weeklyOf, forEmployee, isMobile, onClick, isHoliday, dailyUpdates, ...props }) => {



    return <CenteredBox sx={{ width: "100%", flexDirection: "column" }} p={1} >

        <SmallCalendarButton


            onClick={onClick}>
            <JobsComponent isMobile={true} date={date} calendarDate={calendarDate} data={data?.filter(item => item?.calendarItemType != INSTALLATION_CALENDAR_ITEM_TYPE.AVAILABILITY) ?? []} />

            {date}


            <ShiftAvailblityComponent isMobile={true} date={date} calendarDate={calendarDate} shiftData={data?.filter(item => item?.calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.AVAILABILITY) ?? []} />
        </SmallCalendarButton>
    </CenteredBox>
}

const JobButton = styled(ButtonBase)(({ theme, color }) => (
    {
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
        marginBottom: 0,
        color,
        position: "relative",
        "::before": {
            borderRadius: 0,
            content: "' '",
            position: "absolute",
            opacity: "0.2",
            top: "0px",
            left: "0px",
            zIndex: 0,
            background: color,
            height: "100%",
            width: "100%"
        }
    }
))

const JobItem = ({ isMobile, project_id, _id, calendarItemType, ...props }) => {
    const navigate = useNavigate()
    const onClick = () => {
        if (calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.INSPECTION)
            navigate("/site-installer-view", {
                state: { data: { _id } },
            });
            if (calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.ASSIGNED_INSTALLATION)
                navigate("/installation-view", {
                    state: { data: { _id } },
                });
        if (calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.POST_INSTALLATION)
            navigate("/post-installation-view", {
                state: { data: { _id } },
            });
    }

    if (isMobile) {
        return <Box height="5px" flex="none" width="5px" borderRadius="100%" bgcolor={jobItemColor[calendarItemType] ?? "gray"} ></Box>
    }

    return <JobButton color={jobItemColor[calendarItemType] ?? "gray"} onClick={onClick} >
        {project_id?.uid}
    </JobButton>
}
const JobsComponent = memo(({ date, isMobile, calendarDate, data }) => {
    const { user } = useSelector(state => state)
    const customMoment = (dateToBeCheck) => {

        return moment(dateToBeCheck).set('date', date).set("month", moment(calendarDate).get("month")).set("year", moment(calendarDate).get("year"))
    }
    const findShift = (startDate) => {

        return user?.data?.shifts?.find((shift) => customMoment(shift.from).valueOf() <= moment(startDate).valueOf() && customMoment(shift.to).valueOf() >= moment(startDate).valueOf())?.title ?? 'Other'
    }
    const dataToBerendered = useMemo(() => {
        const groupedData = groupByArray(data?.map((item) => ({ ...item, shift: findShift(item.start) })), 'shift')
        return [
            ...user?.data?.shifts?.map((item) => ({ ...item, data: (groupedData[item?.title] ?? []) })),
            ...(groupedData["Other"] ? [{
                title: "Other",
                data: groupedData["Other"]
            }] : [])
        ]
    }, [data])

    if (isMobile) {
        return <>
            <Box display="flex" mb={1} alignItems="center" justifyContent="space-around" height="15px" >
                {
                    data?.map((item) => <JobItem isMobile={isMobile} key={item?._id} {...item} />)
                }
            </Box>
        </>
    }
    return <>
        {
            <Box p={2}>
                {
                    dataToBerendered?.map((shift) => {
                        return <Box component={Paper} mb={1} p={1} sx={{ minHeight: "50px" }} border={1} borderColor="divider" >
                            <Typography variant="caption" >{shift?.title}</Typography>
                            <Divider />

                            {
                                shift?.data?.map((item) => <JobItem key={item?._id} {...item} />)
                            }
                        </Box>
                    })
                }
            </Box>
        }

    </>
})
const ShiftAvailblityComponent = memo(({ isMobile, date, calendarDate, shiftData }) => {


    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [shifts, setShifts] = useState(user?.data?.shifts?.map((item) => ({ ...item, availability: null })))
    const [loading, setLoading] = useState(false)

    const handleShiftClick = (installer_shift, availablityId) => {


        setLoading(true)

        if (availablityId) {
            dispatch(
                callApiAction(
                    async () => await deleteShiftApi({ id: availablityId }),
                    (response) => {
                        dispatch(removedAvailablityAction(availablityId))
                        dispatch(callSnackBar("Your shift is unavailable", SNACK_BAR_VARIETNS.suceess));
                        setLoading(false)
                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                    }
                )
            );
        } else {
            const year = calendarDate.getFullYear();
            const month = calendarDate.getMonth();
            const newDate = moment().set('date', date).set('month', month).set('year', year).toISOString()

            dispatch(
                callApiAction(
                    async () => await addShiftApi({ installer_shift: installer_shift, date: newDate }),
                    (response) => {
                        dispatch(addAvailablityAction(response))
                        dispatch(callSnackBar("Your shift is available", SNACK_BAR_VARIETNS.suceess));
                        setLoading(false)
                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                    }
                )
            );
        }
    }
    const execute = () => {

        const shiftsDataToBeupdated = [...shifts]
        let i = 0
        for (let item of shiftsDataToBeupdated) {

            const availabilityId = shiftData.find(shiftItem => shiftItem?.installer_shift == item?._id)?._id ?? null
            shiftsDataToBeupdated[i].availability = availabilityId
            i++
        }
        setShifts(shiftsDataToBeupdated)
    }

    useEffect(() => {
        execute()
    }, [shiftData])

    if (isMobile) {
        return <>
            <CenteredBox  >
                {
                    shifts?.map((item) => <Tooltip key={item.title} title={item?.title} >
                        <Typography fontSize="10px" lineHeight="100%" color={
                            item.availability ? "success.main" : "grey.main"} >
                            {getShiftIcon(item?.icon)}
                        </Typography>
                    </Tooltip>

                    )
                }
            </CenteredBox>
        </>
    }
    return <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flex: 1 }} pt={5}  >
        {
            !loading &&
            <>
                {
                    shifts?.map((item) => <Tooltip key={item.title} title={item?.title} >
                        <IconButton color={
                            item.availability ? "success" : "default"} onClick={() => handleShiftClick(item._id, item.availability)}>
                            {getShiftIcon(item?.icon)}
                        </IconButton>
                    </Tooltip>

                    )
                }

            </>
        }
        {
            loading && <CenteredBox>
                <Box sx={{ width: "100%" }} >
                    <LinearProgress color="primary" />
                </Box>
            </CenteredBox>
        }
    </Box>
})

const CalendarInnerBox = ({ data, date, calendarDate, CallBack, isMobile, ...props }) => {

    return (
        <>

            {!isMobile ?
                <>
                    <JobsComponent date={date} calendarDate={calendarDate} data={data?.filter(item => item?.calendarItemType != INSTALLATION_CALENDAR_ITEM_TYPE.AVAILABILITY) ?? []} />
                    <ShiftAvailblityComponent date={date} calendarDate={calendarDate} shiftData={data?.filter(item => item?.calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.AVAILABILITY) ?? []} />
                </>
                :
                <MobileViewDesktop
                    calendarDate={calendarDate}
                    key={JSON.stringify(data)}
                    data={data}
                    date={date}
                    {...props}
                />}

        </>)


}
export default memo(CalendarInnerBox)