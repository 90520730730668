import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInspectionDataCountrApi } from "../../apis/inspection.api";
import { updateInspectionCountsData } from "../../store/actions/inspectionAction";
import { callApiAction } from "../../store/actions/commonAction";
import {
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const CountTabs = ({ activeStatus, setStatus, ...itemProps }) => {
  const theme = useTheme();
  return (
    <Grid item xs={6}>
      <Card
        onClick={() => setStatus(itemProps.status)}
        variant="outlined"
        style={{
          height: "100%",
          backgroundColor:
            activeStatus === itemProps.status
              ? theme.palette.primary.main
              : "white",
          border: "1px solid " + theme.palette.primary.main,
          cursor: "pointer",

          color: activeStatus === itemProps.status ? "white" : "unset",
        }}
      >
        <CardContent>
          <Typography variant="h6" align="center">
            {itemProps?.count}
          </Typography>
          <Typography variant="body2" align="center">
            {itemProps?.label}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const SiteInspectionCountsController = ({ filters, setFilters }) => {
  const location = useLocation();

  const { inspection } = useSelector((state) => state);
  console.log("filterss",filters)
  console.log("inspsectiosns",inspection)
  const { filters: appLevelFilters, counts: defaultCounts } = inspection;
  const [counts, setCounts] = useState(defaultCounts ?? []);
  console.log("countss",counts)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchCounts = () => {
    const filtersToBepassed = { ...filters };
    console.log("fitlers to be passed",filtersToBepassed)
    delete filtersToBepassed["status"];
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getInspectionDataCountrApi(filtersToBepassed),
        (response) => {
          console.log("responsne",response)
          setCounts(response);
          dispatch(updateInspectionCountsData(response));
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  useEffect(() => {
    const applevelFiltersInstance = { ...appLevelFilters };
    console.log("apple LEvel filteres",applevelFiltersInstance)
    const localFiltersInstance = { ...filters };
    console.log("localFilter instance",localFiltersInstance)
    delete applevelFiltersInstance["status"];
    delete localFiltersInstance["status"];
    // if (
    //   JSON.stringify(applevelFiltersInstance) !=
    //   JSON.stringify(localFiltersInstance)
    // ) {
      fetchCounts();
    // }
  }, [filters]);

  return (
    <>
      <Grid container spacing={2} display={"flex"} flexDirection={"row"}>
        {loading
          ? [0, 1, 2, 3].map((row) => (
              <Grid item xs={5.8}>
                <Skeleton
                  variant="rectangular"
                  width={"auto"}
                  height={"15vh"}
                />
              </Grid>
            ))
          : counts?.map((item) => (
              <CountTabs
                key={item.status}
                activeStatus={filters.status}
                setStatus={() =>
                  setFilters({ ...filters, status: item.status })
                }
                {...item}
              />
            ))}
      </Grid>
    </>
  );
};

export default SiteInspectionCountsController;
