import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import useValidate from "../../../store/hooks/useValidator";
import { updateInstallerFormField } from "../../../apis/installer.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import {
  CATEGORY,
  CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE,
  SNACK_BAR_VARIETNS,
} from "../../../utils/constants/constants";
import CompleteInstallationFormUi from "./CompleteInstallationFormUi";
import { dataURItoBlob, installa, installationCompleteFormStorage, installationCompleteFormStoragetionCompleteFormStorage } from "../../../utils/helpers/helper";
import { completeInstallationApi, getCompleteInstallationDataApi, getStartInstallationDataApi, startInstallationApi, updateAssignedInstallationApi } from "../../../apis/assignedinstallation.api";
import { hardRefreshAssignedInstallationData } from "../../../store/actions/assignedInstallationAction";

const CompleteInstallationFormController = () => {
  const validate = useValidate();
  const location = useLocation();
  const id = location.state?.id;

  const [installationData, setInstallationData] = useState({});
  const [questions, setQuestions] = useState([])
  const installerSignatureCanavsRef = useRef()
  const customerSignatureRef = useRef()
  const [dataFetchLoading, serDataFetchLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    err: "",
    id: id,
    selfie: null,
    qr_scanned: false,
    customer_remarks: "",
    step: 1,
    ...(installationCompleteFormStorage.get(id) ?? {})
  });

  useEffect(() => {
    installationCompleteFormStorage.set(id, JSON.stringify(fields));
  }, [fields, id]);



  const validationSchemaForUpdate = useMemo(
    () => [
      [
        {
          required: true,
          value: fields.selfie,
          field: "Selfie",
        },
        {
          required: true,
          value: fields.qr_scanned,
        },
        {
          required: true,
          value: fields?.installer_remarks,
          field: "Installer Remakrs",
        },
        {

          value: fields?.signature,
          field: "Installer",
          custom: () => {

            return !installerSignatureCanavsRef.current?.isEmpty?.()
          }
        },
      ],
      [{
        required: true,
        value: fields?.acceptTerms,
        field: "Terms & Condition",

      },
      {

        value: fields?.signature,
        field: "Customer Siganture",
        custom: () => {

          return !customerSignatureRef.current?.isEmpty?.()
        }
      },
      ]
    ],
    [fields, installerSignatureCanavsRef.current, customerSignatureRef.current]
  );



  const validateFun = (stepNo) => {
    const validationResponse = validate(validationSchemaForUpdate[stepNo ?? 0]);

    return validationResponse === true
  }

  const submitFun = async () => {
    const validationResponse = validate(validationSchemaForUpdate[1]);
    let updatedData = { id: id, ...fields };


    if (validationResponse === true) {
      setLoading(true);
      const formData = new FormData()

      const customerSignature = customerSignatureRef.current.getCanvas().toDataURL('image/png')
      const customerSignatureBlob = dataURItoBlob(customerSignature)
      const customeSignaturePNG = new File([customerSignatureBlob], "signature-" + new Date().getTime() + ".png", { type: "image/png" })


      const installerSignature = installerSignatureCanavsRef.current.getCanvas().toDataURL('image/png')
      const installerSignatureBlob = dataURItoBlob(installerSignature)
      const installerSignaturePNG = new File([installerSignatureBlob], "signature-" + new Date().getTime() + ".png", { type: "image/png" })


      formData.append('customer_signature', customeSignaturePNG)
      formData.append('installer_signature', installerSignaturePNG)
      
      formData.append('questions', JSON.stringify(questions))
      for (let fieldKey in updatedData) {
        formData.append(fieldKey, updatedData[fieldKey])
      }
      dispatch(
        callApiAction(
          async () =>
            await completeInstallationApi(formData),
          async (response) => {
            installationCompleteFormStorage.remove(id)
            setLoading(false);
            navigate("/installation");
            dispatch(hardRefreshAssignedInstallationData())

            dispatch(
              callSnackBar(
                "Installation completed successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    } else {
      dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error));
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (id) submitFun();
  };

  const fetchById = () => {
    serDataFetchLoading(true);
    dispatch(
      callApiAction(
        async () => await getCompleteInstallationDataApi({ id: id }),
        async (response) => {
          setQuestions(response?.configs?.questions?.map(item => ({ ...item, answer: item?.type == CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE.RATING ? 4 : null })) ?? [])
          setInstallationData({ ...response });
          serDataFetchLoading(false);
        },
        (err) => {
          // setFields({ ...fields, err });
          serDataFetchLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchById();
  }, []);
  return (
    <CompleteInstallationFormUi
      questions={questions}
      setQuestions={setQuestions}
      installerSignatureCanavsRef={installerSignatureCanavsRef}
      customerSignatureRef={customerSignatureRef}
      validateFun={validateFun}
      setFields={setFields}
      fields={fields}
      onSubmit={onSubmit}
      dataFetchLoading={dataFetchLoading}
      loading={loading}
      data={installationData}

    />
  );
};

export default CompleteInstallationFormController;
