import { memo } from "react";
import { Chip } from "@mui/material";
import {
  findObjectKeyByValue,
} from "../../../utils/helpers/helper";
import { POST_INSTALLATION_STATUS } from "../../../utils/constants/constants";
import { postInstallationStatusColor } from "../../../utils/helpers/helper";

const PostInstallationStatusComponent = memo(({ params, setParams, onlyview }) => {
  return (
    <Chip
    sx={{borderRadius:1}}
      size="small"
      color={postInstallationStatusColor(params?.status)}
      label={findObjectKeyByValue(params?.status, POST_INSTALLATION_STATUS)}
    />
  );
});
export default PostInstallationStatusComponent;
