import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsUI from "./DetailViewUi";
import { getInstallerByIdApi } from "../../apis/installer.api";
import { callApiAction } from "../../store/actions/commonAction";
import { useParams } from "react-router-dom";

const DetailsViewController = ({ callBack = () => {}}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  console.log("USER", user);
  const modalKey = "installer-details";
  const title = "Installer Details";
  const id=useParams().id

  const getByIdApi = getInstallerByIdApi;

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [state, setState] = useState([]);
  const [fields, setFields] = useState({
    err: "",
    id,
    // id:user.data._id,
  });

  const fetchById = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          console.log("responce", response);
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    console.log("this is id", id);
    fetchById();
  }, [id]);

  return (
    <DetailsUI
      id={id}
      callBack={callBack}
      modalKey={modalKey}
      title={title}
      list={list}
      setList={setList}
      state={state}
      setState={setState}
      loading={loading}
      fields={fields}
      setFields={setFields}
    />
  );
};
export default memo(DetailsViewController);
