import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { AccountCircle, CalendarMonth, Cancel, CheckCircle, MoneyOff, Place } from "@mui/icons-material";
import {
    Alert,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from "moment";
import { findObjectKeyByValue } from "../../utils/helpers/helper";
import { INVOICE_TYPE } from "../../utils/constants/constants";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import useInovice from "./useInvoice";

const Container = styled(Box)(({ theme, invoiceColor }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid " + invoiceColor?.color,
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    borderRadius: theme.shape.borderRadius * 4,
    "::after": {
        content: "' '",
        background: invoiceColor?.color,
        opacity: "0.1",
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "0px",
        left: "0px",
        zIndex: 0
    }


}))

const InvoiceDetailsUi =({data})=>{
    const location = useLocation();
    const props = location.state?.data;
    const navigate = useNavigate()
    const invoiceColor = useInovice(data ?? {})

console.log("DATAEEE",data)
console.log("propsss",props)
    return (
        <>
        
        <Box
          mb={2}
          sx={{
            padding: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
            border: "1px solid #ccc",
            // position:"fixed"
          }}
        >
          <Breadcrumbs>
            <Link
              underline="hover"
              color="black"
              onClick={() => navigate("/invoice")}
              sx={{ cursor: "pointer", color: "black" }}
            >
              <Typography sx={{ color: "text.primary" }}>Invoice</Typography>
            </Link>
            <Typography sx={{ color: "text.primary" }}>View</Typography>

          </Breadcrumbs>
        </Box>
    {/* {loading && <CenteredBox><CircularProgress /></CenteredBox>} */}
    <Paper component={Box} p={3} mt={2} width="100%" maxHeight="100%">
    {
        props && props?._id &&
        <>
        {
            props?.disabled && <Alert severity="error">
                This invoice is disabled
                <Box>
                <Typography variant="caption" >This invoice will not count for any further calculations, it is here just to show past actions</Typography>
                </Box>
            </Alert>
        }
            {/* {props?.installer_id && <Typography textTransform="capitalize" mb={3}>Installer: {props?.installer_id?.name} ({props?.project?.uid})</Typography>} */}

            {/* {props?.supplier_id && <Typography textTransform="capitalize" mb={3} >Supplier: {props?.supplier_id?.name}</Typography>} */}
            <Container id="invoice-detail" p={3} pb={1} invoiceColor={invoiceColor}>
                <Box sx={{ width: "100%" }} >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", zIndex: 1 }} mb={2}>
                        <Box sx={{display:"flex",flexDirection:"column"}}>
                        <Typography variant="body1" > {props.project.uid}</Typography>
                        </Box>
                     
                        {/* <Box display="flex" flexDirection="column" alignItems="flex-end" > */}
                        {/* <Chip size="small" color="info" label={findObjectKeyByValue(props.type, INVOICE_TYPE)} /> */}
                            {/* <Typography variant="caption">{(props.type==INVOICE_TYPE.INCENTIVES_INVOICE?`${props.incentive_type}`:"")}</Typography> */}
                        {/* </Box> */}
                    </Box>
                       <Box sx={{ display: "flex" }}>
                            <Typography variant="body2" > {props.invoice_no} </Typography>
                            <Typography variant="body2" > ({moment(props.creation_date).format("DD MMM YYYY")})</Typography>
                        </Box>
                    <Box sx={{ width: "100%" }}>
                        <Typography variant="caption" align="center">Due Date</Typography>
                        <Typography variant="caption" align="center"> ({moment(props.creation_date).format("DD MMM YYYY")})</Typography>
                    </Box>
                    {props.remarks && <Box mt={3} elevation={0} component={Paper} p={2} sx={{ width: "100%", display: "flex", background: "white", position: "relative", zIndex: 1 }} mb={2}>
                        <Typography variant="caption" align="center" >Remarks:</Typography>
                        <Typography variant="caption" align="center" >{props.remarks}</Typography>

                    </Box>}

                    <Box mt={3} elevation={0} component={Paper} p={2} sx={{ width: "100%", display: "flex", background: "white", position: "relative", zIndex: 1 }} mb={2}>

                        <Grid container>
                            <Grid item xs={6}>
                                <Box sx={{ width: "100%", background: "", zIndex: 1 }} >
                                    <Typography variant="body1" align="center" > {props.amount}</Typography>
                                    <Typography variant="caption" component="div" align="center" >Total Amount</Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ width: "100%", background: "", zIndex: 1, borderLeft: 1,height:"7vh" }} >
                                    <Typography variant="body1"  align="center"> {props.paid_amount}</Typography>
                                    <Typography component="div" variant="caption" align="center" >Paid Amount</Typography>
                                </Box>

                            </Grid>
                            {/* <Grid item xs={4}>
                                <Box sx={{ width: "100%", background: "", zIndex: 1, borderLeft: 1,height:"8vh" }} >
                                    <Typography variant="body1" align="center" > {props.amount - props.paid_amount}</Typography>
                                    <Typography component="div" variant="caption" align="center" >Remained Amount</Typography>
                                </Box>

                            </Grid> */}

                        </Grid>


                    </Box>
                    <Box >
                        <Typography variant="body1" fontWeight="bold" mt={1}>Payment Details</Typography>
                        {
                            props.payments && Array.isArray(props.payments) && props.payments.map((item) => {
                                return <Box elevation={0} component={Paper} sx={{ borderBottom: 1, borderBottomColor: "divider", zIndex: 11, position: "relative" }} key={item._id} mb={2} p={2} >
                                    <Grid container spacing={3} mt={1} >
                                        <Grid item xs={6} md={4} >
                                            <Typography variant="caption" display="block" fontWeight="600" align="center" > {item.amount}</Typography>
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" > Amount Paid</Typography>
                                        </Grid>
                                        {/* <Grid item xs={6} md={4} >
                                            <Typography variant="caption" display="block" fontWeight="600" align="center" > {titleCase(findObjectKeyByValue(item.payment_by, PAYMENT_OPTIO))}</Typography>
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" >Payment Type</Typography>
                                        </Grid> */}
                                        <Grid item xs={6} md={4} >
                                            <Typography variant="caption" display="block" fontWeight="600" align="center" > {item.verified ? <CheckCircle fontSize="inherit" color="success" /> : <Cancel fontSize="inherit" color="error" />}</Typography>
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" >Verification Status</Typography>
                                        </Grid>

                                        <Grid item xs={6}  md={4}  >
                                            <Typography variant="caption" display="block" fontWeight="600" align="center" >{moment(item.payment_date).format("DD MMM YYYY")}</Typography>
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" > Transaction Date</Typography>
                                        </Grid>

                                        <Grid item xs={6}  md={4}  >
                                            <Typography variant="caption" display="block" fontWeight="600" align="center" > {item.transaction_id}</Typography>
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" >Transaction Id</Typography>
                                        </Grid>
                                       {/* {item?.transaction_proof && item?.transaction_proof!='' && <Grid item xs={6}  md={4}  >
                                            <Typography variant="caption" display="block" fontWeight="600" align="center" ><FileDownloadComponent src={item?.transaction_proof}  /></Typography>
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" >Transaction Proof</Typography>
                                        </Grid>} */}
                                    </Grid>

                                    {/* {item.is_in_bulk &&
                                        <>
                                            <Divider />
                                            <Typography variant="caption" sx={{ display: "block" }} align="center" >In Bulk</Typography>
                                        </>
                                    } */}
                                </Box>
                            })
                        }
                        {
                            props.payments && props.payments.length == 0 && <CenteredBox elevation={0} mb={2} sx={{ position: "relative", zIndex: 1 }} component={Paper} >
                                <NoDataComponent iconVariant="h3" variant="body1" Icon={MoneyOff} message="No Transactions Available" />
                            </CenteredBox>
                        }
                    </Box>
                </Box>
           

            </Container>
        </>

    }
    </Paper>
</>
)
}

export default InvoiceDetailsUi;