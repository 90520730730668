import React, { useEffect, useMemo, useState } from "react";
import InspectionFormUI from "./PostInstallationFormUi";
import { useLocation, useNavigate } from "react-router-dom";
import useValidate from "../../../store/hooks/useValidator";
import { updateInstallerFormField } from "../../../apis/installer.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import {
  CATEGORY,
  SNACK_BAR_VARIETNS,
} from "../../../utils/constants/constants";
import PostInstallationFormUI from "./PostInstallationFormUi";
import { updatePostInstallationApi } from "../../../apis/postinstallation.api";

const PostInstallationFormController = () => {
  const validate = useValidate();
  const location = useLocation();
  const userId = location.state?.userId;
  console.log("userIdsss", userId);
  const [originalDocument, setOriginalDocument] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    err: "",
    id: userId,
    installation_data: {
      category: CATEGORY.INVERTER_ISSUE,
      old_inverter_photos: {
        front_photo: "",
        inverter_screen_photo: "",
        serial_number_label: "",
      },
      new_inverter_photos: {
        front_photo: "",
        inverter_screen_photo: "",
        serial_number_label: "",
        ac_voltage_photo: "",
        dc_voltage_photo: "",
      },

      images_ceiling_leakage: [],
      leakage_reason: null,
      issue_description: "",
      issue_photos: [],
      repair_photos: [],
      repair_description: "",

      ac_dc_power_voltage_photos: [],
      panel_damage: null,
      panel_damage_photos: [],
      reason_panel_not_working: "",

      wifi_configuration: "",
      reason: "",
      photos: [],
      resolution: "",
    },
  });

  useEffect(() => {
    localStorage.setItem("inspectionFields", JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    const storedFields = localStorage.getItem("inspectionFields");
    if (storedFields) {
      const fields = JSON.parse(storedFields);
      console.log("this is localpsaldl", fields);
      setFields({ ...fields });
    }
  }, []);

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields?.installation_data?.category,
        field: "Category",
      },

      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value: fields?.installation_data?.old_inverter_photos?.front_photo,
        field: "Front Photo",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value:
          fields?.installation_data?.old_inverter_photos?.inverter_screen_photo,
        field: "Inverter Screen Photo",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value:
          fields?.installation_data?.old_inverter_photos?.serial_number_label,
        field: "Serial Number Label",
      },

      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value: fields?.installation_data?.new_inverter_photos?.front_photo,
        field: "Front Photo",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value:
          fields?.installation_data?.new_inverter_photos?.inverter_screen_photo,
        field: "Inverter Screen Photo",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value:
          fields?.installation_data?.new_inverter_photos?.serial_number_label,
        field: "Serial Number Label",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value: fields?.installation_data?.new_inverter_photos?.ac_voltage_photo,
        field: "AC Voltage Photo",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.INVERTER_ISSUE && true,
        value: fields?.installation_data?.new_inverter_photos?.dc_voltage_photo,
        field: "DC Voltage Photo",
      },

      {
        required:
          fields.installation_data.category === CATEGORY.LEAKAGE_ISSUE && true,
        value: fields.installation_data.images_ceiling_leakage,
        field: "Image Ceiling Leakage",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.LEAKAGE_ISSUE && true,
        value: fields.installation_data.leakage_reason,
        field: "Leakage Reason",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.LEAKAGE_ISSUE && true,
        value: fields.installation_data.issue_description,
        field: "Issue Descripiton",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.LEAKAGE_ISSUE && true,
        value: fields.installation_data.issue_photos,
        field: "Issue Photo",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.LEAKAGE_ISSUE && true,
        value: fields.installation_data.repair_description,
        field: "Repair Descripiton",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.LEAKAGE_ISSUE && true,
        value: fields.installation_data.repair_photos,
        field: "Repair Photo",
      },
      {
        required:
          fields.installation_data.category ===
            CATEGORY.SOLAR_PANAL_NOT_WORKING && true,
        value: fields.installation_data.ac_dc_power_voltage_photos,
        field: "AC DC Power Voltage Photos",
      },
      {
        required:
          fields.installation_data.category ===
            CATEGORY.SOLAR_PANAL_NOT_WORKING && true,
        value: fields.installation_data.panel_damage,
        field: "Panel Damage",
      },
      {
        required:
          fields.installation_data.category ===
            CATEGORY.SOLAR_PANAL_NOT_WORKING && true,
        value: fields.installation_data.panel_damage_photos,
        field: "Panel Damage Photos",
      },
      {
        required:
          fields.installation_data.category ===
            CATEGORY.SOLAR_PANAL_NOT_WORKING && true,
        value: fields.installation_data.reason_panel_not_working,
        field: "Reason For Panel Not Working",
      },
      {
        required:
          fields.installation_data.category === CATEGORY.WIFI_CONFIGRATION &&
          true,
        value: fields.installation_data.wifi_configuration,
        field: "Wifi Configuration",
      },
      {
        required: fields.installation_data.category === CATEGORY.OTHERS && true,
        value: fields.installation_data.reason,
        field: "Reason",
      },
      {
        required: fields.installation_data.category === CATEGORY.OTHERS && true,
        value: fields.installation_data.photos,
        field: "Photos",
      },
      {
        required: fields.installation_data.category === CATEGORY.OTHERS && true,
        value: fields.installation_data.resolution,
        field: "Resolution",
      },
    ],
    [fields]
  );
  console.log("object error msg show", fields.err);
  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id: userId };
    for (let field in originalDocument) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
    }
    const installation_data = fields.installation_data;
    console.log("installation DATASs", installation_data);
    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () =>
            await updatePostInstallationApi({
              id: userId,
              installation_data,
            }),
          async (response) => {
            console.log("Responsee", response);
            setLoading(false);
            navigate("/Post Installation");

            dispatch(
              callSnackBar(
                "Data Update successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
          },
          (err) => {
            setLoading(false);
            dispatch(callSnackBar(fields.err, SNACK_BAR_VARIETNS.error));
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  useEffect(() => {
    if (fields.err) {
      dispatch(callSnackBar(fields.err, SNACK_BAR_VARIETNS.error));
    }
  }, [fields.err]);

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("erroree");
    console.log("userID", userId);
    if (userId) updateFunction();
  };

  return (
    <PostInstallationFormUI
      setFields={setFields}
      fields={fields}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default PostInstallationFormController;
