import { act } from "react-dom/test-utils"
import { getCalendarDataApi } from "../../apis/installercalendar.api"
import { actions, SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import { callApiAction } from "./commonAction"
import { getShiftApi } from "../../apis/shift.api"
import { callSnackBar } from "./snackbarAction"

export const setCalendarFiltersValue = (field, value) => {

    return { type: actions.SET_CALENDAR_DATA_FILTERS, filters: { [field]: value } }
}
export const setCalendarFiltersValueForMultiples = (filters) => {
    return { type: actions.SET_CALENDAR_DATA_FILTERS, filters }
}

export const fetchInstallerCalendaData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.SET_CALENDAR_LOADING, value: true })
        const data = await Promise.all([
            new Promise((resolve, reject) => {
                dispatch(
                    callApiAction(
                        async () =>
                            await getShiftApi({
                                ...getState()?.installerCalendar?.filters,
                            }),
                        (response) => {
                            resolve(response?.map((item) => ({ ...item, calendarItemType: 'availability' })))
                        },
                        (err) => {
                            resolve([])
                            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                        }
                    )
                );
            }),
            new Promise((resolve, reject) => {
                dispatch(
                    callApiAction(
                        async () =>
                            await getCalendarDataApi({
                                ...getState()?.installerCalendar?.filters,
                            }),
                        (response) => {
                            resolve(response)
                        },
                        (err) => {
                            resolve([])
                            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                        }
                    )
                );
            })
        ])

        if (data) {
            dispatch({ type: actions.SET_CALENDAR_DATA, data: data.flat(1) })
        } else {
            dispatch({ type: actions.SET_CALENDAR_LOADING, value: false })
        }


    }
}
export const addAvailablityAction = ( value) => {

    return { type: actions.ADD_AVAILABLITY, value }
}
export const removedAvailablityAction = (id) => {

    return { type: actions.REMOVE_AVAILABLITY, id }
}