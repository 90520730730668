import { memo } from "react";
import { Chip } from "@mui/material";
import { STATUS_TYPE } from "../../../utils/constants/constants";
import {
  findObjectKeyByValue,
  inspectionStatusColor,
  paymentStatusColor,
} from "../../../utils/helpers/helper";
import { APPROVAL_REQUEST_STATUS } from "../../../utils/constants/approvalRequest.constant";

const PaymentStatusComponent = memo(({ item, setParams, onlyview }) => {
  return (
    <Chip
      sx={{ borderRadius: 1 }}
      size="small"
      color={paymentStatusColor(item.status)}
      label={findObjectKeyByValue(item.status, APPROVAL_REQUEST_STATUS)}
    />
  );
});
export default PaymentStatusComponent;
