import {

  Dashboard,

} from "@mui/icons-material";

import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";

import SignInController from "./pages/signin/SignInController";
import PagenotFound from "./components/layouts/PagenotFound";
import DashboardController from "./pages/dashboard/DashboardController";

import {
  defaultRedirect,
  routeObjGenerator,
} from "./utils/helpers/route.helper";
import DetailViewController from "./pages/installer/DetailViewController";
import SiteInstallerCreateUi from "./pages/jobs/SiteInspectionListUi";
import ShiftCalendarController from "./pages/installer-shift/ShiftCalendarController";
import CreateUi from "./pages/jobs/CreateUi";
import SiteInstallerController from "./pages/jobs/SiteInspectionController";
import InspectionFormController from "./pages/jobs/InspectionFormController";
import ListController from "./pages/aggrement/ListController";
import AggrementDetailViewUi from "./pages/aggrement/AggrementDetailsUi";
import ViewDetailsUi from "./pages/jobs/ViewDetailsUi";
import CreateApprovalRequestPage from "./pages/approval-request/CreateApprovalRequestPage";
import PostInstallationController from "./pages/jobs/post-installation/PostInstallationController";
import PostInstallationFormController from "./pages/jobs/post-installation/PostInstallationFormController";
import PostInstallationViewDetailsUi from "./pages/jobs/post-installation/ViewDetailsUi";
import InstallationViewDetailsUi from "./pages/jobs/assigned-installation/ViewDetailsUi";
import InvoiceController from "./pages/invoice/InvoiceController";
import InvoiceDetailsUi from "./pages/invoice/InvoiceDetailsUi";
import AssignedInstallationController from "./pages/jobs/assigned-installation/AssignedInstallationController";
import StartInstallationFormController from "./pages/jobs/assigned-installation/StartInstallationFormController";
import CompleteInstallationFormController from "./pages/jobs/assigned-installation/CompleteInstallationFormController";

const defineRoutes = (user) => {
  const redirectDefault = defaultRedirect(user);
  const generateRouterObjFun = routeObjGenerator(user, redirectDefault);
  return [
    // generateRouterObjFun("jobs", "jobs", Dashboard, <AppContainer />,[],[], [
    //   generateRouterObjFun("site-installer", "Site-Installer",<SiteInstallerCreateUi />,undefined,user.isLoggedIn),

    //   // generateRouterObjFun("payment-verification", "Payment Verification", CreditScore, <PendingVerificationListController />, [MODULES.PAYMENT_VERIFICATION_MODULE]),
    //   // generateRouterObjFun("pending-payments", "Pending Payments", WorkHistoryOutlined, <PendingPaymentListController />, [MODULES.PAYMENT_VERIFICATION_MODULE]),
    // ]),

    generateRouterObjFun("sign-in", "Sign In", undefined, <SignInController />,  undefined,  !user.isLoggedIn),
    generateRouterObjFun("", "", undefined, <Navigate replace to={redirectDefault} />,  undefined,  user.isLoggedIn, <Navigate replace to="/sign-in" />),
    generateRouterObjFun("dashboard", "Dashboard", Dashboard, <AppContainer overflow="auto"><DashboardController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("jobs", "jobs", Dashboard, <AppContainer overflow="auto"><CreateUi /> </AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("calendar", "Calendar", Dashboard, <AppContainer overflow="auto"><ShiftCalendarController /> </AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("profile/:id", "Profile", Dashboard, <AppContainer overflow="auto"><DetailViewController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("site-installer", "Site-Installer", Dashboard, <AppContainer overflow="auto"><SiteInstallerController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("site-installer-PendingForm", "Form", Dashboard, <AppContainer overflow="auto"><InspectionFormController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("site-installer-view", "Form", Dashboard, <AppContainer overflow="auto"><ViewDetailsUi /></AppContainer>,undefined,user.isLoggedIn),

    // generateRouterObjFun("site-installer", "Site-Installer", Dashboard, <AppContainer overflow="auto"><SiteInstallerCreateUi /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("aggrement", "Aggrement", undefined, <AppContainer overflow="auto"><ListController /></AppContainer>,undefined,user.isLoggedIn),
    // generateRouterObjFun("aggrement-details", "Aggrement", undefined, <AppContainer overflow="auto"><AggrementDetailViewUi /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("aggrement/:id", "Aggrement", undefined, <AppContainer overflow="auto"><AggrementDetailViewUi /></AppContainer>,undefined,user.isLoggedIn),

    generateRouterObjFun("request-approval/:type/:id", "Approvals", Dashboard, <AppContainer overflow="auto"><CreateApprovalRequestPage /></AppContainer>,undefined,user.isLoggedIn),
    
    generateRouterObjFun("post-installation", "Post Installation", Dashboard, <AppContainer overflow="auto"><PostInstallationController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("post-installation-form", "Form", Dashboard, <AppContainer overflow="auto"><PostInstallationFormController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("post-installation-view", "View", Dashboard, <AppContainer overflow="auto"><PostInstallationViewDetailsUi/></AppContainer>,undefined,user.isLoggedIn),

    generateRouterObjFun("installation", "Installation", Dashboard, <AppContainer overflow="auto"><AssignedInstallationController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("installation-form", "Installation", Dashboard, <AppContainer overflow="auto"><StartInstallationFormController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("completion-form", "Installation", Dashboard, <AppContainer overflow="auto"><CompleteInstallationFormController /></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("installation-view", "Installation", Dashboard, <AppContainer overflow="auto"><InstallationViewDetailsUi /></AppContainer>,undefined,user.isLoggedIn),

    generateRouterObjFun("invoice", "Invoice", Dashboard, <AppContainer overflow="auto"><InvoiceController/></AppContainer>,undefined,user.isLoggedIn),
    generateRouterObjFun("invoice-view", "View", Dashboard, <AppContainer overflow="auto"><InvoiceDetailsUi/></AppContainer>,undefined,user.isLoggedIn),

    generateRouterObjFun(
      "*",
      "",
      undefined,
      <Navigate replace to={"/sign-in"} />,
      undefined,
      undefined,
      undefined,
      true,
      !user.isLoggedIn,
      <PagenotFound />
    ),
  ];
};
export default defineRoutes;
