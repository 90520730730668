import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { POST_INSTALLATION_STATUS, SNACK_BAR_VARIETNS, STATUS_TYPE } from "../../../utils/constants/constants";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { useLocation } from "react-router-dom";
import AssignedInstallationUi from "./AssignedInstallationUi";
import { getAssignedInstallationListApi } from "../../../apis/assignedinstallation.api";
import { updateAssignedInstallationFilters, updateAssignedInstallationListData } from "../../../store/actions/assignedInstallationAction";

const AssignedInstallationController = () => {
  const { assignedInstallation } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(assignedInstallation.data ?? {})
  const [filters, setFilters] = useState({
    refresh:true,
    pageNo: 1,
    pageSize: 10,
    sort: "assigned_date",
    sortDirection: -1,
    status: POST_INSTALLATION_STATUS.PENDING,
    ...assignedInstallation.filters,
  });


  const handleChangePage = (e, newVal) => {
    setFilters({ ...filters, pageNo: newVal + 1 });
  };
  const handleChangeRowsPerPage = (e) => {
    setFilters({ ...filters, pageNo: 1, pageSize: e.target.value });
  };

  const getFetchList = () => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await getAssignedInstallationListApi(filters),
        (response) => {
          setData(response)
          dispatch(updateAssignedInstallationListData(response))
          setLoading(false)
        },
        (err) => {
          setLoading(false)
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        }
      )
    );
  };
  useEffect(() => {
    
    if ((JSON.stringify(filters) != JSON.stringify(assignedInstallation.filters)) || (filters.refresh && !assignedInstallation.filters?.refresh))
      getFetchList();
  }, [filters]);

  useEffect(() => {
    dispatch(updateAssignedInstallationFilters({ ...filters }))
  }, [filters])


  return (
    <AssignedInstallationUi
      loading={loading}
      list={data}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      filters={filters}
      setFilters={setFilters}

    />
  );
};

export default AssignedInstallationController;
