import { Autocomplete, Box, Button, Checkbox, CircularProgress, MenuItem, Typography } from "@mui/material"
import { forwardRef, Fragment, memo, useRef, useState } from "react"
import SignaturePad from 'react-signature-canvas'
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import ImageComponent from "../../components/inputs/ImageComponent"





const UpdateSignatureUI = forwardRef(({ title, oldSignature, onClear, modalKey, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }, ref) => {

    const [checked, setChecked] = useState(false);

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            disabledSubmit={!checked}
            onSubmit={onSubmit}
            title={`Sign Aggrement`}
            closeText="Close"
            confirmText={`Submit`}
        >

            {(loading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!loading &&
                <>
                    <CenteredBox sx={{ flexDirection: "column" }}   >

                        <Box>                            
                            <SignaturePad height="100px" width="300px" canvasProps={{ height: "100px", width: "300px", style: { border: "1px solid black", } }}
                                ref={ref}
                            />
                        </Box>
                        <Box sx={{ height: "100%" }} >
                            <Button variant="contained" onClick={onClear}>
                                Clear
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex" }} alignItems="flex-start" width="100%">
                            <Checkbox
                            sx={{padding:0}}
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            //   inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography ml={2} variant="body2">I aggree that, I read aggrement and agree with this prices .</Typography>
                        </Box>
                    </CenteredBox>
                </>}

        </CustomDialog>


    </>
})
export default memo(UpdateSignatureUI)