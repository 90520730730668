import { Link, useNavigate, useParams } from "react-router-dom"

import { Button } from "@mui/material"

const RequestApprovalButton = ({ type, id, title = "Request Payment" }) => {

    return <Button disableElevation LinkComponent={Link} to={`/request-approval/${type}/${id}`} size="small" variant="contained" >
        {title}
    </Button>
}
export default RequestApprovalButton