import {
  Apps,
  CalendarMonth,
  Favorite,
  LocalAtm,
  Policy,
  Restore,
} from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { openModal } from "../../../store/actions/modalAction";
import { useDispatch } from "react-redux";
import Calendar from "../Calendar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const searchWordInSentence=(sentence, word)=> {
  // Create a case-insensitive regex pattern for the word
  const regex = new RegExp(`\\b${word}\\b`, 'i');
  
  // Check if the word is in the sentence
  return regex.test(sentence)
}
const useRouteTab = () => {
  const location = useLocation()
  return () => {
    if (searchWordInSentence(location.pathname,'installation')) {
      return 0
    }
    if (searchWordInSentence(location.pathname,'jobs')) {
      return 0
    }
    if (searchWordInSentence(location.pathname,'calendar')) {
      return 1
    }
    if (searchWordInSentence(location.pathname,'invoices')) {
      return 2
    }
    if (searchWordInSentence(location.pathname,'aggrement')) {
      return 3
    }
    return -1
  }
}

const NavigationComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const tab = useRouteTab()
  const [value, setValue] = useState(params.tab ? params.tab : 0);

  return (
    <BottomNavigation
      showLabels
      value={tab()}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label="Jobs"
        icon={<Apps />}
        onClick={() => navigate("/jobs")}
      />
      <BottomNavigationAction
        label="Calendar"
        icon={<CalendarMonth />}
        onClick={() => navigate("/calendar")}
      />
      <BottomNavigationAction
        label="Invoices"
        icon={<LocalAtm />}
        onClick={() => navigate("/invoice")}
      />
      <BottomNavigationAction
        label="Aggrement"
        icon={<Policy />}
        onClick={() => navigate("/aggrement")}
      />
    </BottomNavigation>
  );
};
export default NavigationComponent;
