import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import {
  addInstallerApi,
  getInstallerByIdApi,
  updateInstallerField,
} from "../../apis/installer.api";
import CreateUi from "./CreateUi";
import moment from "moment";

const CreateController = ({ callBack = () => {}, id }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Installer ";
  const createApi = addInstallerApi;
  const updateApi = updateInstallerField;
  const getByIdApi = getInstallerByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    name: "",
    email: "",
    password: "",
    phone: "",
    license_number: "",
    license_expiry: moment().valueOf(),
    license_file: "",
    subcontractor_name: "",
    subcontractor_acn: "",
    subcontractor_phone: null,
    subcontractor_landPhone: null,
    subcontractor_email: "",
    subcontractor_address: "",
    guaeantor_name: "",
    guaeantor_address: "",
    rec_license_no: "",
    rec_license_date: moment().toISOString(),
    rec_license_expiry_date: moment().toISOString(),
    electrician_license_no: "",
    electrician_license_date: moment().toISOString(),
    electrician_license_expiryDate: moment().toISOString(),
    accreditation_license_no: "",
    accreditation_license_date: moment().toISOString(),
    accreditation_license_expiryDate: moment().toISOString(),
    design_license_no: "",
    design_license_date: moment().toISOString(),
    design_license_expiryDate: moment().toISOString(),
    liability_company: "",
    liability_policy_no: "",
    liability_policy_date: moment().toISOString(),
    liability_policy_expiryDate: moment().toISOString(),
    wic_company: "",
    wic_policy_no: "",
    wic_policy_date: moment().toISOString(),
    wic_policy_expiryDate: moment().toISOString(),
    pasi_company: "",
    pasi_policy_no: "",
    pasi_policy_date: moment().toISOString(),
    pasi_policy_expiryDate: moment().toISOString(),
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },

      {
        required: true,
        value: fields.email,
        field: "Email",
        isEmail: true,
      },
      {
        required: true,
        value: fields.password,
        field: "Password",
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },

      {
        required: true,
        value: fields.email,
        field: "Email",
        isEmail: true,
      },
      {
        required: true,
        value: fields.password,
        field: "Password",
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone",
      },
    ],
    [fields]
  );

  // const checkAllFieldsFilled = () => {
  //   for (let field in fields) {
  //     if (
  //       fields[field] === null ||
  //       fields[field] === "" ||
  //       fields[field] === undefined
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
