import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Modal, Paper, Skeleton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { setZeroPrefix } from '../../utils/helpers/helper'

import { center } from '../../assets/css/theme/common'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { CALENDAR_ITEMS_TYPES } from '../../utils/constants/constants'

export const CalendarContext = createContext({})

const HolidayItem = styled(Box)(({ theme, isFirst, isLast, isMobile }) => ({
  width: '100%',
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0),
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
}))
const DayTitleComponent = styled(Box)(({ theme, isFirst, isLast, isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  borderTopLeftRadius: isFirst ? theme.shape.borderRadius : 'none',
  borderTopRightRadius: isLast ? theme.shape.borderRadius : 'none',
  background: '#F9F9F9',
  border: isMobile ? "none" : `1px solid ${theme.palette.grey[600]}`,


}))
const CalendarGridBox = styled(Grid)(({ theme, isFirst, isLast, isMobile }) => ({
  border: isMobile ? "none" : '1px solid #BBBBBB',

}))

const MobileCalendarBox = ({ weekDay, date, data, Component }) => {
  const { dateObj } = useContext(CalendarContext)
  const [open, setOpen] = useState(false)
  const handleEvent = () => {
    setOpen(!open)
  }
  return <>
    <Dialog
      open={open}
      onClose={handleEvent}
      fullWidth
    >
      <DialogTitle variant='body1'>{date}  {moment(dateObj.getTime()).format("MMM YYYY")}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", position: "relative" }}>
          <Component weekDay={weekDay} date={date} data={data} dateObj={dateObj} />
        </Box>

      </DialogContent>


    </Dialog>
    <Box sx={{ ...center }} >
      {/* <IconButton onClick={handleEvent}><Typography variant='body1' color="dark">{date}</Typography></IconButton> */}
      <Component weekDay={weekDay} isMobile={true} onClick={handleEvent} date={date} data={data} dateObj={dateObj} />
    </Box>
  </>
}

const CalendarBox = memo(({ date, data, Component, weekDay }) => {


  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const holidays = useMemo(() => (data && Array.isArray(data) && data.filter(item => item.type == CALENDAR_ITEMS_TYPES.HOLIDAYS)) ?? [], [data])
  const mainData = useMemo(() => (data && Array.isArray(data) && data.filter(item => item.type != CALENDAR_ITEMS_TYPES.HOLIDAYS)) ?? [], [data])
  if (!date)
    return <CalendarGridBox isMobile={isMobile} item xs={1.7}></CalendarGridBox>
  return (
    <CalendarGridBox isMobile={isMobile} item xs={1.7} key={date}>
      {
        isMobile && <MobileCalendarBox Component={Component} weekDay={weekDay} date={date} data={data} />
      }
      {!isMobile && <Box
        sx={{
          minHeight: '120px',
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          background:
            'white',
        }}
      >
        <Box sx={{}}>
          <Typography
            p={1}
            zIndex={1}
            color="grey"
            variant="subtitle1"
            align="right"
          >
            {date}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} >
          <Component weekDay={weekDay} date={date} data={mainData} />
        </Box>
        <Box sx={{ width: "100%" }} >
          {
            holidays && holidays.map((item) => <HolidayItem key={item.title} color={(theme.palette.dark.main)} >
              <Typography variant="caption" >{item?.title}</Typography>
            </HolidayItem>)
          }

        </Box>



      </Box>}
    </CalendarGridBox>
  )
})
const CalendarRow = memo(({ valArr, Component }) => {

  const arr = JSON.parse(valArr)

  if (!arr[0] && !arr[6])
    return <></>
  return arr?.map((item, index) => <CalendarBox weekDay={index} Component={Component} key={item ? 'date' + item?.date : index} date={item?.date} data={item?.propsData} />)
})
function Calendar({ data, calendarTimeObj, Component, loading }) {
  const { user } = useSelector(state => state)
  const [date, setDate] = useState(new Date())
  const [calendarArr, setCalendarArr] = useState([
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
  ])

  const days = useMemo(() => [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ], [])

  const getDaysInMonth = useCallback((year, month) => {
    return new Date(year, month + 1, 0).getDate()
  }, [])
  const isEventOnCurrentDate = useCallback((currentDate, eventStartDate, eventEndDate) => {
    const currentDateObj = new Date(currentDate);
    const startDateTime = new Date(eventStartDate);
    const endDateTime = new Date(eventEndDate);

    // Extract date components
    const currentYear = currentDateObj.getFullYear();
    const startYear = startDateTime.getFullYear();
    const endYear = endDateTime.getFullYear();

    if (currentYear < startYear || currentYear > endYear) {
      return false; // Event is not within the current year range
    }


    const currentMonth = currentDateObj.getMonth();
    const startMonth = startDateTime.getMonth();
    const endMonth = endDateTime.getMonth();

    if (currentMonth < startMonth || currentMonth > endMonth)
      return false

    const currentDateDate = currentDateObj.getDate();
    const startDateDate = startDateTime.getDate();
    const endDateDate = endDateTime.getDate();

    return currentDateDate >= startDateDate && currentDateDate <= endDateDate;
  }, [])

  const renderCalendar = useCallback(() => {
    const month = date.getMonth()
    const year = date.getFullYear()
    const daysInMonth = getDaysInMonth(year, month)

    let calendar = [
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null],
    ]

    let i = 0
    let j = new Date(year, month).getDay()
    let day = 1

    let dataIndex = 0

    const tempData = [...(data ?? [])]

    const holidayList = user.data?.holiday
    if (Array.isArray(holidayList)) {
      holidayList.forEach((item) => {
        tempData.push({
          title: item.name,
          start: new Date(item.date),
          end: new Date(item.date),
          relatedId: item._id,
          type: CALENDAR_ITEMS_TYPES.HOLIDAYS,
        })
      })
    }

    while (i < 6 && day <= daysInMonth) {
      while (j < 7 && day <= daysInMonth) {
        calendar[i][j] = {
          date: setZeroPrefix(day),
          propsData: [],
        }

        while (dataIndex < tempData.length) {
          if (tempData[dataIndex] && tempData[dataIndex].start && tempData[dataIndex].end) {
            const dataStartDate = new Date(tempData[dataIndex].start)
            const dataEndDate = new Date(tempData[dataIndex].end)

            if (

              isEventOnCurrentDate(new Date(year, month, day), dataStartDate, dataEndDate)
            ) {
              const isStarting = day == dataStartDate.getDate()
              const isEnding = day == dataEndDate.getDate()

              calendar[i][j].propsData.push({
                ...tempData[dataIndex],
                isStarting,
                isEnding,
              })
            }
          }
          dataIndex++
        }
        dataIndex = 0
        day++
        j++
      }
      j = 0
      i++
    }

    setCalendarArr(calendar)
  }, [data, user.data?.holiday, date, setCalendarArr])

  useEffect(() => {
    if (calendarTimeObj) setDate(calendarTimeObj)
  }, [calendarTimeObj])

  useEffect(() => {
    renderCalendar()
  }, [data, date])

  // const Component = dayComponent
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <CalendarContext.Provider value={{
      dateObj: calendarTimeObj,
      data,
    }} >
      <Grid container sx={{ background: 'none' }}>
        {days.map((day, index) => (
          <Grid item xs={1.7} key={day}>
            <DayTitleComponent
              isMobile={isMobile}
              isFirst={index == 0}
              isLast={index == days.length - 1}

            >
              {!isMobile && <Typography variant="body1" color="primary.calender">
                {day}
              </Typography>}

              {isMobile && <Typography fontWeight="600" variant="body1" color="black" >
                {day.slice(0, 2)}
              </Typography>}
            </DayTitleComponent>
          </Grid>
        ))}

        {!loading &&
          calendarArr &&
          calendarArr.map((val, index) => (
            <CalendarRow valArr={JSON.stringify(val)} key={index} Component={Component} />
          ))}

        {loading &&
          calendarArr &&
          calendarArr.map((val) => (
            <React.Fragment key={(val[0] && val[0].date) || Math.random()}>
              {val &&
                (val[0] || val[6]) &&
                val.map((tmp, index) =>
                  tmp ? (
                    <CalendarGridBox isMobile={isMobile} item xs={1.7} key={index + Math.random()}>
                      <Box
                        sx={{
                          minHeight: '120px',
                          width: '100%',
                          display: 'flex',
                        }}
                      >
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          height={'inherit'}
                          width="100%"
                        />
                      </Box>
                    </CalendarGridBox>
                  ) : (
                    <CalendarGridBox isMobile={isMobile} item xs={1.7}></CalendarGridBox>
                  ),
                )}
            </React.Fragment>
          ))}
      </Grid>
    </CalendarContext.Provider>
  )
}

export default memo(Calendar)
