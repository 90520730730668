import React, { forwardRef, memo, useEffect, useReducer, useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ArrowBack, Delete, HideImageOutlined, Image, Place, Upload } from "@mui/icons-material";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import ImageComponent from "../../../components/ImageComponent";
import FileInputImage from "../../../components/inputs/FileInputImage";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import CustomInput from "../../../components/inputs/CustomInput";
import {

  CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE,
  DEFAULT_PRODUCT_CATEGORY,
  SNACK_BAR_VARIETNS,
} from "../../../utils/constants/constants";

import {
  groupByArray,
  titleCase,
  toTitleCase,
} from "../../../utils/helpers/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NoDataComponent from "../../../components/layouts/NoDataComponent";
import SignatureCanvas from "../../../components/inputs/CustomSignatureCanvas";
import SubmitButton from "../../../components/button/SubmitButton";
import ImageUploadComponent from "./components/ImageUploadComponent";
import { StyledInput } from "../../../components/inputs/SearchBar";

const RatingButton = styled(ButtonBase)(({ theme, isActive }) => ({
  borderRadius: theme.shape.borderRadius,
  border:'1px solid',
  flex: 1,
  // marginLeft: theme.spacing(1),
  // marginRight: theme.spacing(1),
  borderColor: "primary",
  background:isActive?theme.palette.primary.main:"unset",
  color:isActive?theme.palette.light.main:"unset",
}))

const changeVal = (id, value) => {
  return { type: "CHANGE_VAL", id, value }
}
const questionsReducer = ((state = [], action) => {

  if (action.type == "CHANGE_VAL") {
    const { id, value } = action
    const arr = [...state]
    const index = state?.findIndex((item) => item?._id == id)
    if (index !== -1) {
      arr[index]['answer'] = value
    }
    return arr
  }
})

const QuestionItem = memo(({ id, question, type, answer, dispatchFun }) => {
  return <Box>
    <Typography variant="caption" >{question}</Typography>
    <Box display="flex" gap={1} mt={1} mb={3} >
      {type == CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE.RATING && <>

        <RatingButton isActive={answer === 1} onClick={() => dispatchFun(changeVal(id, 1))}    > <Typography variant="caption" sx={{ p: 1 }} >Poor</Typography></RatingButton>
        <RatingButton isActive={answer === 2} onClick={() => dispatchFun(changeVal(id, 2))}    > <Typography variant="caption" sx={{ p: 1 }} >Average</Typography></RatingButton>
        <RatingButton isActive={answer === 3} onClick={() => dispatchFun(changeVal(id, 3))}    > <Typography variant="caption" sx={{ p: 1 }} >Great</Typography></RatingButton>
        <RatingButton isActive={answer === 4} onClick={() => dispatchFun(changeVal(id, 4))}    > <Typography variant="caption" sx={{ p: 1 }} >Good</Typography></RatingButton>
        <RatingButton isActive={answer === 5} onClick={() => dispatchFun(changeVal(id, 5))}    > <Typography variant="caption" sx={{ p: 1 }} >Excellent</Typography></RatingButton>
      </>}
      {type == CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE.TEXT_INPUT && <>

<StyledInput 
fullWidth
placeholder="write your answer"
value={answer}
onChange={(e)=>dispatchFun(changeVal(id, e.target.value))}
/>

      </>}
    </Box>
  </Box>

})

const QuestionsAsnwerComponents = memo(({ defaultQuestions, setQuestions }) => {

  const [quenstions, dispatchFun] = useReducer(questionsReducer, defaultQuestions ?? [])

  useEffect(() => {
    setQuestions(quenstions)
  }, [quenstions])

  return <Box>
    <Typography fontWeight="600" >Give your feedback</Typography>
    {
      quenstions?.filter(item => item.type == CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE.RATING)?.map((item, index) => <QuestionItem id={item?._id} question={item?.question} dispatchFun={dispatchFun} key={item?._id} type={item?.type} answer={item.answer} />)
    }
    {
      quenstions?.filter(item => item.type != CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE.RATING)?.map((item, index) => <QuestionItem id={item?._id} question={item?.question} dispatchFun={dispatchFun} key={item?._id} type={item?.type} answer={item.answer} />)
    }
  </Box>
})
const FormStepFirst = forwardRef(({ fields, setFields, data, dataFetchLoading, loading, customerSignatureRef, installerSignatureCanavsRef, validateFun }, ref) => {


  return (

    <Box component={Paper} p={3} flexDirection="column" sx={{ display: "flex", flex: 1, overflow: "auto" }}>

      <Box width="50%" mb={3} >
        <FileInputImage
          disabled={loading}
          onlyImage
          defaults={
            fields?.selfie ? [fields?.selfie] : undefined
          }
          componentProps={{
            name: "Selfie :",
            url: fields?.selfie,
          }}
          onChange={(newUrl) => {
            setFields({
              ...fields,
              selfie: newUrl
            });
          }}
          onDelete={() => {
            setFields({ ...fields, selfie: "" });
          }}
          FileComponent={ImageUploadComponent}
        />

      </Box>

      <FormControlLabel control={<Checkbox checked={!!fields.qr_scanned} onChange={(e) => setFields({ ...fields, qr_scanned: e.target.checked })} />} label="QR Scanned?" />
      <CustomInput
        multiline={true}
        rows={3}
        disabled={loading}
        value={fields.installer_remarks}
        onChange={(e) =>
          setFields({ ...fields, err: "", installer_remarks: e.target.value })
        }
        type="text"
        placeholder="type NA if nothing to write"
        label={"Installer Remarks*"}
      />


      <Typography>Installer Signature</Typography>
      <Box  >

        <SignatureCanvas
          onChange={() => { setFields({ ...fields, signatureRefRefreshKey: "IS" + Math.random() }) }}
          ref={installerSignatureCanavsRef}
        ></SignatureCanvas>
      </Box>


    </Box>

  );
});
const FormStepSecond = forwardRef(({ fields, setFields, data, dataFetchLoading, loading, customerSignatureRef, installerSignatureCanavsRef, validateFun, setQuestions, quenstions }, ref) => {


  return (

    <Box component={Paper} p={3} flexDirection="column" sx={{ display: "flex", flex: 1, overflow: "auto" }}>




      <Box  >

        {/* <Typography>Terms</Typography> */}
        <Typography variant="caption" >
          <Box mb={2} variant="outlined" bgcolor="#f3f3f3" component={Paper} sx={{ maxHeight: "200px", overflow: "auto" }} p={2}
            dangerouslySetInnerHTML={{ __html: data?.configs?.after_installation_text ?? "NA" }}

          >

          </Box>
        </Typography>
        <QuestionsAsnwerComponents defaultQuestions={quenstions} setQuestions={setQuestions} />

        <FormControlLabel control={<Checkbox checked={!!fields.acceptTerms} onChange={(e) => setFields({ ...fields, acceptTerms: e.target.checked })} />} label="I agree Terms & condition" />
        <Typography>Customer Signature</Typography>
        <SignatureCanvas
          onChange={() => { setFields({ ...fields, customerSignatureRefKey: "CS" + Math.random() }) }}
          ref={customerSignatureRef}
        ></SignatureCanvas>
      </Box>


    </Box>

  );
});

const CompleteInstallationFormUi = forwardRef(({ fields, setFields, onSubmit, data, dataFetchLoading, loading, customerSignatureRef, installerSignatureCanavsRef, validateFun, questions, setQuestions }, ref) => {

  const navigate = useNavigate();
  return (
    <>
      <Box
        mb={2}
        sx={{
          padding: "10px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          border: "1px solid #ccc",
          // position:"fixed"
        }}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/jobs")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>jobs</Typography>
          </Link>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/installation")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>
              Installation
            </Typography>
          </Link>
          <Typography sx={{ color: "text.primary" }}>Complete</Typography>
        </Breadcrumbs>
      </Box>
      {
        dataFetchLoading && <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      }
      {!dataFetchLoading && data?._id &&
        <>
          <Paper component={Box} p={2} mb={1} >
            <Typography>{data?.project_id?.uid}</Typography>
            <Typography typography="body2" > <Place fontSize="inherit" /> {data?.project_id?.full_address}</Typography>
          </Paper>
          {<>
            <Box display={fields.step != 1 ? "none" : 'flex'} flexDirection="column" flex={1} overflow="hidden">
              <FormStepFirst
                fields={fields}
                setFields={setFields}
                onSubmit={onSubmit}
                data={data}
                dataFetchLoading={dataFetchLoading}
                loading={loading}
                customerSignatureRef={customerSignatureRef}
                installerSignatureCanavsRef={installerSignatureCanavsRef}
                validateFun={validateFun}
              />
              <Box mt={1} display="flex" component={Paper} >

                <Button key={fields.signatureRefRefreshKey} variant="contained" disabled={!validateFun(0)} fullWidth onClick={() => setFields({ ...fields, step: 2 })} >Next</Button>
              </Box>
            </Box>
          </>}


          {
            <>
              <Box display={fields.step != 2 ? "none" : 'flex'} flexDirection="column" flex={1} overflow="hidden">
                <FormStepSecond
                  quenstions={questions}
                  setQuestions={setQuestions}
                  fields={fields}
                  setFields={setFields}
                  onSubmit={onSubmit}
                  data={data}
                  dataFetchLoading={dataFetchLoading}
                  loading={loading}
                  customerSignatureRef={customerSignatureRef}
                  installerSignatureCanavsRef={installerSignatureCanavsRef}
                  validateFun={validateFun}
                />
                <Box mt={1} display="flex" component={Paper} >
                  <Button startIcon={<ArrowBack />} sx={{ mr: 1 }} variant="outlined" fullWidth onClick={() => { setFields({ ...fields, step: 1 }) }}>Back</Button>
                  <SubmitButton key={fields.customerSignatureRefKey} variant="contained" loading={loading} disabled={!validateFun(1) || loading} fullWidth onClick={onSubmit} title="Submit" />
                </Box>
              </Box>
            </>
          }
        </>
      }


      {!dataFetchLoading && !data?._id && <Box p={3} sx={{ backgroundColor: "white", display: "flex", flex: 1 }}>

        <NoDataComponent message="Invalid job" />
      </Box>}
    </>
  );
});

export default CompleteInstallationFormUi;
