import { actions } from "../../utils/constants/constants";

export const hardRefreshPostInstallationData = () => {
  return { type: actions.HARD_REFRESH_POST_INSTALLATION_DATA}
}
export const updatePostInstallationFilters = (filters) => {
  return { type: actions.UPDATE_POST_INSTALLATION_DATA_FILTERS,value:filters }
}
export const updatePostInstallationCountsData = (data) => {
  return { type: actions.UPDATE_POST_INSTALLATION_DATA_COUNTS,value:data }
}
export const updatePostInstallationListData = (data) => {
  return { type: actions.UPDATE_POST_INSTALLATION_DATA_LIST,value:data }
}
