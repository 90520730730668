import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import snackBarReducer from "./snackbarReducer";
import fetchVersionReducer from "./fetchVersionReducer";
import inspectionReducer from "./inspectionReducer";
import postInstallationReducer from "./postInstallationReducer";
import assignedInstallationReducer from "./assignedInstallationReducer";
import installercalendarReducer from "./installercalendarReducer";



const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,
    notifications: notificationReducer,
    snackBar: snackBarReducer,
    version: fetchVersionReducer,
    inspection: inspectionReducer,
    postInstallation: postInstallationReducer,
    assignedInstallation: assignedInstallationReducer,
    installerCalendar: installercalendarReducer
})
export default rootReducer;