import { Link, useNavigate, useParams } from "react-router-dom"
import { APPROVAL_REQUEST_TYPE } from "../../utils/constants/approvalRequest.constant"
import CreateController from "./CreateController"
import { Box, Breadcrumbs, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { hardRefreshInspectionData } from "../../store/actions/inspectionAction"

const CreateApprovalRequestPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return <Box sx={{ minHeight: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}  >

        <Box display="flex" flex={1} width="100%" flexDirection="column" >
            <CreateController
                id={params?.id}
                type={params.type}

                callBack={() => {
                    navigate(-1);
                    dispatch(hardRefreshInspectionData())
                }}
            />
        </Box>
    </Box>
}
export default CreateApprovalRequestPage