import { styled } from '@mui/material/styles';

import { Box } from '@mui/system';

const OneViewBox = styled(Box)(({ theme }) => ({

    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",

}));

export const CenteredBox = styled(Box)(({ theme }) => ({

    height: "100%",
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"


}));

export default OneViewBox