
import CustomInput from '../../components/inputs/CustomInput';
import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import OneViewBox from '../../components/layouts/OneViewBox';
import SubmitButton from '../../components/button/SubmitButton';
import { center } from '../../assets/css/theme/common';
import SolarBg from '../../assets/images/solar-bg.webp'
// import Logo from '../../assets/images/logo.png'
import ImageComponent from '../../components/ImageComponent';



const absolutePosition = { position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%", zIndex: -1 }
const SignInBoxUi = styled(Box)(({ theme }) => ({
    display: "flex",
    maxWidth: "90%",
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(255,255,255,0.1)',
    backdropFilter: "blur(6px)",
    width: "600px",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid white"

}));


const SignInUI = ({ state, setState, onSubmit, loading }) => {
    const Logo = (process.env.PUBLIC_URL+'/'+process.env.REACT_APP_LOGO_SLUG)
    return (

        <>
            <OneViewBox sx={{ overflow: "hidden" }}  >

                <Box sx={absolutePosition}>
                    <ImageComponent isStatic noClickEffect={true} sx={{}} src={SolarBg} alt={'Background'} />
                </Box>
                <Box sx={{ ...center, width: "100%", height: "100%" }} >
                    <SignInBoxUi p={4} component={"form"} width={"100%"} onSubmit={onSubmit}>
                        <Box sx={{ width: "200px", height: "auto" }}>  <ImageComponent isStatic noClickEffect={true} sx={{ height: "auto", objectFit: "contain" }} src={Logo} alt={'Background'} /></Box>
                        <Typography align='center' variant="h5" color={"red"} mb={1}>
                            {state.err}
                        </Typography>
                        <Box>
                            <CustomInput
                                variant="filled"
                                disabled={loading}
                                value={state.email}
                                onChange={(e) => setState({ ...state, err: '', email: e.target.value })}
                                type="text"                                
                                label={"Email"}
                            />
                            <CustomInput
                                variant="filled"
                                disabled={loading}
                                value={state.password}
                                onChange={(e) => setState({ ...state, err: '', password: e.target.value })}
                                type="password"                                
                                label={"Password"}
                            />
                            <Box mt={4}>
                                <SubmitButton variant="contained" loading={loading} disabled={loading} type='submit' title='Sign In' />
                            </Box>
                        </Box>
                    </SignInBoxUi>
                </Box>

                {/* <CustomContainer sx={{ marginBottom: "100px" }}>
                    <Grid container spacing={2} sx={{ display: "flex" }}>
                        <Grid item xs={12} md={6}>
                            <AuthLeftContainer />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <AuthRighttContainer >
                                <Box component={"form"} width={"100%"} onSubmit={onSubmit}>

                                    <Typography variant="h2" mb={2}>
                                        Log In
                                    </Typography>
                                    <Typography variant="h4" color={"red"} mb={1}>
                                        {state.err}
                                    </Typography>
                                    <Box>
                                        <CustomInput
                                            disabled={loading}
                                            value={state.email}
                                            onChange={(e) => setState({ ...state, err: '', email: e.target.value })}
                                            type="text"
                                            label={"Email"}
                                        />
                                        <CustomInput
                                            disabled={loading}
                                            value={state.password}
                                            onChange={(e) => setState({ ...state, err: '', password: e.target.value })}
                                            type="password"
                                            label={"Password"}
                                        />

                                    </Box>
                                    <Box mt={4}>
                                        <SubmitButton variant="contained" loading={loading} disabled={loading} type='submit' title='Sign In' />
                                    </Box>

                                </Box>
                            </AuthRighttContainer>
                        </Grid>
                    </Grid>




                </CustomContainer> */}
            </OneViewBox>


        </>
    )
}
export default SignInUI