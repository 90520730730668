import { Search } from "@mui/icons-material"
import { Avatar, Box, Input, styled, TextField, Typography } from "@mui/material"
import AsyncDropDown from "./AsyncDropDown"


export const StyledInput = styled(TextField)(({ theme }) => ({
  

  background: theme.palette.grey.main,
  borderRadius: theme.shape.borderRadius,
  lineHeight: "100%",
  display: "flex",
  alignItems: "center",

  boxShadow: theme.shadows[1],





  '::before': {
    content: "none",

  },
  ":focus": {

    '::before': {
      content: "none",
      borderRadius: theme.shape.borderRadius,
    },


  },
  '.MuiOutlinedInput-root': {
    padding: '0px !important',
  },
  'fieldset': {
    padding: '0px !important',
    border: "none !important"
  },
  ':after': {
    display: 'none',
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  color: theme.palette.grey.light,
  outline: "none",
  borderRadius: theme.shape.borderRadius,


  paddingBottom: theme.spacing(3) + " !important",
}))


export const StyledSearchBar = styled(TextField)(({ theme }) => ({
  

  background: theme.palette.grey['200'],
  borderRadius: theme.shape.borderRadius,
  lineHeight: "100%",
  display: "flex",
  alignItems: "center",

  '::before': {
    content: "none",

  },
  ":focus": {

    '::before': {
      content: "none",
      borderRadius: theme.shape.borderRadius,
    },


  },
  "&-MuiInputBase-input-MuiOutlinedInput-input":{
    padding: theme.spacing(0) + " !important",
  },
  'fieldset': {
    padding: '0px !important',
    border: "none !important",

  },
  ':after': {
    display: 'none',
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  color: theme.palette.grey.light,
  outline: "none",
  borderRadius: theme.shape.borderRadius,
  


}))

