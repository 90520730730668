export const THIRD_PART_APPS_USED = {
  none: "none",

  google_map: "google_map",
  // facebook_lead: "facebook_lead",
  // uniden_portal: "uniden_portal",
};
export const USER_ROLES = {
  director: 9,
  partner_admin: 7,
  admin: 1,
  sub_admin: 2,
  sales: 3,
  tl: 8,
};
export const NOTIFICATION_TYPE = {
  general: "general",
  project: "project",
  lead: "lead",
};
export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const ROOF_TYPES = {
  "-1": "None",
  6: "Composition / Asphalt Shingle",
  7: "Flat Concrete",
  8: "Flat Foam",
  9: "Membrane EPDM",
  10: "Membrane PVC",
  11: "Membrane TPO",
  12: "Metal Decramastic",
  13: "Metal Shingle",
  14: "Metal Standing Seam",
  15: "Metal Stone Coated",
  16: "Tin colorbond",
  17: "Tar and Gravel / Bitumen",
  18: "Thatched",
  19: "Tile Taracotta",
  20: "Tile cement",
  21: "Tile Slate",
  22: "Wood/Shake Shingle",
  23: "Other",
  24: "Kliplock",
};

export const CUSTOMER_STATUS = {
  PENDING: 1,
  COMPLETED: 2,
  REJECTED: -1,
};

export const AUS_STATES = ["WA", "VIC", "NSW", "QLD", "TAS", "SA"];
export const CALENDAR_ITEMS_TYPES = {
  HOLIDAYS: 1,
  LOGS: 2,
  TASK: 3,
  PROJECT: 4,
  LEAD: 5,  
  SHIFT: 7,
};

export const APP_TYPES = {
  WEB: 1,
  APP: 2,
};

export const actions = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  SET_HOLIDAY_COUNT_DATA: "SET_HOLIDAY_COUNT_DATA",
  START_HOLIDAY_COUNT_LOADING: "START_HOLIDAY_COUNT_LOADING",

  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",

  SET_VERSION_DATA: "SET_VERSION_DATA",
  SET_VERSION_DATA_ONDELETE: "SET_VERSION_DATA_ONDELETE",
  START_VERSION_LOADING: "START_VERSION_LOADING",

  UPDATE_INSPECTION_DATA_FILTERS: "UPDATE_INSPECTION_DATA_FILTERS",
  HARD_REFRESH_INSPECTION_DATA: "HARD_REFRESH_INSPECTION_DATA",
  UPDATE_INSPECTION_DATA_LIST: "UPDATE_INSPECTION_DATA_LIST",
  UPDATE_INSPECTION_DATA_COUNTS: "UPDATE_INSPECTION_DATA_COUNTS",

  UPDATE_POST_INSTALLATION_DATA_FILTERS: "UPDATE_POST_INSTALLATION_DATA_FILTERS",
  HARD_REFRESH_POST_INSTALLATION_DATA: "HARD_REFRESH_POST_INSTALLATION_DATA",
  UPDATE_POST_INSTALLATION_DATA_LIST: "UPDATE_POST_INSTALLATION_DATA_LIST",
  UPDATE_POST_INSTALLATION_DATA_COUNTS: "UPDATE_POST_INSTALLATION_DATA_COUNTS",

  UPDATE_ASSIGNED_INSTALLATION_DATA_FILTERS: "UPDATE_ASSIGNED_INSTALLATION_DATA_FILTERS",
  HARD_REFRESH_ASSIGNED_INSTALLATION_DATA: "HARD_REFRESH_ASSIGNED_INSTALLATION_DATA",
  UPDATE_ASSIGNED_INSTALLATION_DATA_LIST: "UPDATE_ASSIGNED_INSTALLATION_DATA_LIST",
  UPDATE_ASSIGNED_INSTALLATION_DATA_COUNTS: "UPDATE_ASSIGNED_INSTALLATION_DATA_COUNTS",

  SET_CALENDAR_DATA: "SET_CALENDAR_DATA",
  SET_CALENDAR_LOADING: "SET_CALENDAR_LOADING",
  ADD_AVAILABLITY: "ADD_AVAILABLITY",
  REMOVE_AVAILABLITY: "REMOVE_AVAILABLITY",
  SET_CALENDAR_DATA_FILTERS: "SET_CALENDAR_DATA_FILTERS",
  
};

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};

export const EMAIL_BOX_TYPE = {
  INBOX: "INBOX",
  STARRED: "[Gmail]/Starred",
  ALL_MAIL: "[Gmail]/All Mail",
  SENT: "[Gmail]/Sent Mail",
  BIN: "[Gmail]/Bin",
  // IMPORTANT:"[Gmail]/Important",
  SPAM: "[Gmail]/Spam",
};

export const LOG_TYPE = {
  CHECK_IN: 1,
  CHECK_OUT: 2,
};

export const TEMPLATE_CATEGORY = {
  EMAIL: 1,
  WHATSUP: 2,
  MESSAGE: 3,
};

export const MODULES_TYPES = {
  PANNELS: 1,
  INVERTERS: 2,
  BATTERIES: 3,
  OTHERS: 4,
};

export const STATUS_TYPE = {
  PENDING: 1,
  INSPECTED: 2,
  PENDING_APPROVAL: 3,
  COMPLETED: 4,
};

export const SIGN_STATUS = {
  SIGNED: 1,
  UNSIGNED: 2,
};

export const POST_INSTALLATION_STATUS = {
  PENDING: 1,
  SOLVED: 2,
  PENDING_APPROVAL: 3,
  COMPLETED: 4,
};


export const ASSIGNED_INSTALALTION_STATUS = {
  PENDING: 1,
  INSTALLATION_STARTED: 2,
  INSTALLATION_COMPLETED: 3,
  PENDING_APPROVAL: 4,
  COMPLETED: 5,
};

export const ASSIGNED_INSTALALTION_STATUS_COLOR = {
  [ASSIGNED_INSTALALTION_STATUS.PENDING]: 'default',
  [ASSIGNED_INSTALALTION_STATUS.INSTALLATION_STARTED]: 'info',
  [ASSIGNED_INSTALALTION_STATUS.INSTALLATION_COMPLETED]: 'info',
  [ASSIGNED_INSTALALTION_STATUS.PENDING_APPROVAL]: 'warning',
  [ASSIGNED_INSTALALTION_STATUS.COMPLETED]: 'success',
}
export const CATEGORY = {
  INVERTER_ISSUE: 1,
  LEAKAGE_ISSUE: 2,
  SOLAR_PANAL_NOT_WORKING: 3,
  WIFI_CONFIGRATION: 4,
  OTHERS: 5,
};

export const LECKAGE_REASON = {
  "-1": "None",
  1: "Leakage due to solar installation",
  2: "Leakage not related to solar installation",
};

export const INVOICE_TYPE = {
  DEPOSIT: 1,
  GENERAL: 3,
  EXTRAA_CHARGE: 4,
  CUSTOMER_INVOICE: 5,
  ELECTRICIAN_INVOICE: 6,
  // STC_INVOICE: 7,
  BULK_INVOICE: 8,
  INCENTIVES_INVOICE: 9,
  // SOLAR_VICTORIA_INVOICE: 9,
  SUPPLIER_INVOICE: 10,
  APPLICATION_AND_DELIVERY_FEES_INVOICE: 11,
  REFUND_INVOICE: 12,
  LOSS_INVOICE: 13
}

export const INVOICE_STATUS = {
  PAID: 1,
  PENDING: 2,
  PARTIALLY_PAID: 3
}


export const DEFAULT_PRODUCT_CATEGORY = {
  PANELS: "PANELS",
  INVERTERS: "INVERTERS",
  BATTERIES: "BATTERIES",
  OTHER: "OTHER"
}
export const CUSTOMER_ACKNOWLEDGEMENT_QUESTIONS_TYPE = {
  TEXT_INPUT: 1,
  RATING: 2

}

export const FILE_TYPES = {
  IMAGES: 1,
  PDFS: 2
}