import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateInspectionCountsData } from "../../../store/actions/inspectionAction";
import { callApiAction } from "../../../store/actions/commonAction";
import { Card, CardContent, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getPostInstallationDataCountrApi } from "../../../apis/postinstallation.api";
import { updatePostInstallationCountsData } from "../../../store/actions/postInstallationAction";
import { getAssignedInstallationDataCountrApi } from "../../../apis/assignedinstallation.api";
import { updateAssignedInstallationCountsData } from "../../../store/actions/assignedInstallationAction";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
const CountTabs = ({ activeStatus, setStatus, ...itemProps }) => {
    const theme = useTheme();
    return (
        <Grid item xs={4}>
            <Card
                onClick={() => setStatus(itemProps.status)}
                variant="outlined"
                sx={{
                    height: "100%",
                    backgroundColor:
                        activeStatus === itemProps.status
                            ? theme.palette.primary.main
                            : "white",
                    border: "1px solid " + theme.palette.primary.main,
                    cursor: "pointer",
                    p: 2,
                    color: activeStatus === itemProps.status ? "white" : "unset",
                }}
            >
                <CenteredBox flexDirection="column" >
                    <Typography variant="subtitle2" align="center">
                        {itemProps?.count}
                    </Typography>
                    <Typography variant="caption" align="center">
                        {itemProps?.label}
                    </Typography>
                </CenteredBox>
            </Card>
        </Grid>
    );
};
const AssignedInstallationCountsController = ({ filters, setFilters }) => {
    const location = useLocation()

    const { assignedInstallation } = useSelector(state => state)
    const { filters: appLevelFilters, counts: defaultCounts } = assignedInstallation
    const [counts, setCounts] = useState(defaultCounts ?? [])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const fetchCounts = () => {
        const filtersToBepassed = { ...filters }
        delete filtersToBepassed['status']
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getAssignedInstallationDataCountrApi(filtersToBepassed),
                (response) => {
                    setCounts(response)
                    dispatch(updateAssignedInstallationCountsData(response))
                    setLoading(false)

                },
                (err) => {
                    setLoading(false)
                }
            )
        );
    }
    useEffect(() => {

        const applevelFiltersInstance = { ...appLevelFilters }
        const localFiltersInstance = { ...filters }
        delete applevelFiltersInstance['status']
        delete localFiltersInstance['status']
        if (JSON.stringify(applevelFiltersInstance) != JSON.stringify(localFiltersInstance) || (filters.refresh && !assignedInstallation.filters?.refresh)) {
            fetchCounts()
        }
    }, [filters])


    return <>

        <Grid container spacing={2} display={"flex"} flexDirection={"row"}>
            {loading
                ? [0, 1, 2, 3].map((row) => (
                    <Grid item xs={5.8}>
                        <Skeleton
                            variant="rectangular"
                            width={"auto"}
                            height={"15vh"}
                        />
                    </Grid>
                ))
                : counts?.map((item) => (
                    <CountTabs
                        key={item.status}
                        activeStatus={filters.status}
                        setStatus={() => setFilters({ ...filters, status: item.status })}
                        {...item}
                    />
                ))}
        </Grid>
    </>
}

export default AssignedInstallationCountsController;