import { act } from "react-dom/test-utils";
import { actions } from "../../utils/constants/constants";

const initialState = {
  version_data: {},
  version_loading: false,
  version_filters: {},
};

const versionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.START_VERSION_LOADING:
      return { ...state, version_loading: true, version_data: [] };
    case actions.SET_VERSION_DATA:
      return {
        ...state,
        version_loading: false,
        version_data: action.value.data,
        version_filters: action.value.filters,
      };
    case actions.SET_VERSION_DATA_ONDELETE:
      return {
        ...state,
        version_data: action.value.data,
        version_filters: action.value.filters,
      };

    default:
      return { ...state };
  }
};

export default versionReducer;
