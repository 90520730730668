import { createTheme } from "@mui/material"

const colorTheme = {
    palette: {
        primary: {
            main: "#002050",
            light: '#ffdfaf',
        },
        secondary: {
            main: "rgb(0, 22, 56,0.08)",
        },
        dark: {
            main: "#000",
            200: 'rgba(158, 158, 158, 0.12)'
        },
        light: {
            main: "#fff",
            200: "#E5E5E5"
        },
        grey: {
            main: "#e5e5e5",

        },
        text: {
            primary: 'rgb(33, 43, 54)',
            secondary: "gray",
            invert:"#fff",
            light:"rgb(99, 115, 129)"
        },
        orange: {
            main: "rgba(255, 62, 29, 1)"
        },
        tab:{
            main:'#9fb1e8'
        },
        projectStatusCodes:{
            'initial':'grey',
            'underpre':'#0066ff',//lightblue
            'completedpre':"#000099",//dark blue
            'underinst':"#8600b3",
            'completedinst':"#79d2a6",
            'underpost':"#e69900",
            'completedpost':"#664400"
        }
    }

}

export default colorTheme