import React, { useEffect, useMemo, useState } from "react";
import InspectionFormUI from "./InspectionFormUI";
import { useLocation, useNavigate } from "react-router-dom";
import useValidate from "../../store/hooks/useValidator";
import { updateInstallerFormField } from "../../apis/installer.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";

const InspectionFormController = () => {
  const validate = useValidate();
  const location = useLocation();
  const userId = location.state?.userId;
  const [originalDocument, setOriginalDocument] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    err:"",
    id: userId,
    inspection_data: {
      meet: "",
      property: {
        front: "",
        back: "",
        side: "",
      },
      switch: {
        switch_board: "",
        metor_box: "",
        remarks: "",
      },

      roof: [],
      roof_type: null,
      roof_condition: "",
      is_roof_accessible: "",
      panel_orientation_feasibility: "",
      inverter_location_feasibility: "",
      inspection_summary: "",
    },
  });

  useEffect(() => {
    localStorage.setItem("inspectionFields", JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    const storedFields = localStorage.getItem("inspectionFields");
    if (storedFields) {
      const fields = JSON.parse(storedFields);
      console.log("this is localpsaldl", fields);
      setFields({ ...fields });
    }
  }, []);

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.inspection_data.meet,
        field: "Meet",
      },

      {
        required: true,
        value: fields.inspection_data.property.front,
        field: "Front",
      },
      {
        required: true,
        value: fields.inspection_data.property.back,
        field: "Back",
      },
      {
        required: true,
        value: fields.inspection_data.property.side,
        field: "Side",
      },

      {
        required: true,
        value: fields.inspection_data.switch.switch_board,
        field: "Switch Board",
      },
      {
        required: true,
        value: fields.inspection_data.switch.metor_box,
        field: "Meter Box",
      },
      {
        required: true,
        value: fields.inspection_data.switch.remarks,
        field: "Remarks",
      },
      // {
      //   required: true,
      //   value: fields.inspection_data.roof,
      //   field: "Roof",
      // },
      {
        required: true,
        value: fields.inspection_data.roof_type,
        field: "Roof Type",
      },
      {
        required: true,
        value: fields.inspection_data.roof_condition,
        field: "Roof Condition",
      },
      {
        required: true,
        value: fields.inspection_data.is_roof_accessible,
        field: "Is Roof Accessible",
      },
      {
        required: true,
        value: fields.inspection_data.panel_orientation_feasibility,
        field: "Panel Orientation Feasibility",
      },
      {
        required: true,
        value: fields.inspection_data.inverter_location_feasibility,
        field: "Inverter Location Feasibility",
      },
      {
        required: true,
        value: fields.inspection_data.inspection_summary,
        field: "Inspection Summary",
      },
    ],
    [fields]
  );

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id: userId };

    for (let field in originalDocument) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
    }
    const inspection_data = fields.inspection_data;

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () =>
            await updateInstallerFormField({
              id: userId,
              inspection_data,
            }),
          async (response) => {
            setLoading(false);
            navigate("/Site-Installer");

            dispatch(
              callSnackBar(
                "Data Update successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
      dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (userId) updateFunction();
  };

  return (
    <InspectionFormUI
      setFields={setFields}
      fields={fields}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default InspectionFormController;
