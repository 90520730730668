import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getAggrementApi = async params => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const downloadAggrementPdf = async params => {
  const callResponse = await axios({
    url: endpoints.aggrementPDFs,
    method: "get",
    headers: getHeaders(),
    responseType:"blob",
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getAggrementByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.aggrementById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addAggrementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateAggrementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateActiveAggrementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementActive,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteAggrementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateAggrementSignatureApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementSignature,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



// export default getAggrementApi