import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Business, QueuePlayNext, SolarPower } from "@mui/icons-material";
import { INSTALLATION_CALENDAR_ITEM_TYPE, jobItemColor } from "../../utils/constants/installercalendar.constant";

const CreateUi = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        mb={2}
        sx={{
          padding: "10px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          border: "1px solid #ccc",
          // margin: "10px 0",
        }}
      >
        <Breadcrumbs>
          <Link onClick={() => navigate("/jobs")} sx={{ cursor: "pointer" }}>
            <Typography sx={{ color: "text.primary" }}>jobs</Typography>
          </Link>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={2} sx={{ marginTop: "3px" }}>
      <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "125px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              border: "1px solid lightgray",
             
              borderLeft: 10,
              borderLeftColor: jobItemColor[INSTALLATION_CALENDAR_ITEM_TYPE.ASSIGNED_INSTALLATION],
              cursor: "pointer",
              color:jobItemColor[INSTALLATION_CALENDAR_ITEM_TYPE.ASSIGNED_INSTALLATION]
            }}
            onClick={() => navigate("/installation")}
          >
            <CardContent>
            <Typography variant="h4" align="center">
               <SolarPower fontSize="inherit" />
              </Typography>
              <Typography variant="h6" align="center">
                Installation
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "125px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              border: "1px solid lightgray",
             
              borderLeft: 10,
              borderLeftColor: jobItemColor[INSTALLATION_CALENDAR_ITEM_TYPE.INSPECTION],
              cursor: "pointer",
              color:jobItemColor[INSTALLATION_CALENDAR_ITEM_TYPE.INSPECTION]
            }}
            onClick={() => {
              navigate("/site-installer");
            }}
          >
            <CardContent>
            <Typography variant="h4" align="center">
               <Business fontSize="inherit" />
              </Typography>
              <Typography variant="h6" align="center">
                Site Inspection
              </Typography>
            </CardContent>
          </Card>
        </Grid>

     
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "125px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              border: "1px solid lightgray",
             
              borderLeft: 10,
              borderLeftColor: jobItemColor[INSTALLATION_CALENDAR_ITEM_TYPE.POST_INSTALLATION],
              cursor: "pointer",
              color:jobItemColor[INSTALLATION_CALENDAR_ITEM_TYPE.POST_INSTALLATION]
            }}
            // onClick={() => handleCardClick("Post Inspection")}
            onClick={() => {
              navigate("/post-installation");
            }}
          >
            <CardContent>
            <Typography variant="h4" align="center">
               <QueuePlayNext fontSize="inherit" />
              </Typography>
              <Typography variant="h6" align="center">
                Post Installation
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* </Box> */}
    </>
  );
};

export default CreateUi;
