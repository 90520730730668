import React, { forwardRef, memo, useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ArrowBack, Delete, HideImageOutlined, Image, Place, Upload } from "@mui/icons-material";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import ImageComponent from "../../../components/ImageComponent";
import FileInputImage from "../../../components/inputs/FileInputImage";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import CustomInput from "../../../components/inputs/CustomInput";
import {

  DEFAULT_PRODUCT_CATEGORY,
  SNACK_BAR_VARIETNS,
} from "../../../utils/constants/constants";

import {
  groupByArray,
  titleCase,
  toTitleCase,
} from "../../../utils/helpers/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NoDataComponent from "../../../components/layouts/NoDataComponent";
import SignatureCanvas from "../../../components/inputs/CustomSignatureCanvas";
import SubmitButton from "../../../components/button/SubmitButton";
import ImageUploadComponent from "./components/ImageUploadComponent";

const ProductListItem = ({ product, code, quantity }) => {
  return <Grid container spacing={2} >
    <Grid item xs={10} >
      <Typography variant="caption" >{product}({code})</Typography>
    </Grid>
    <Grid item xs={2} >
      <Typography variant="caption" >X {quantity}</Typography>
    </Grid>
  </Grid>
}
const ProductsListingComponent = memo(({ products }) => {
  const productObj = products ? groupByArray(products, 'productcategory') : {}
  if (!products || products?.length == 0)
    return <></>
  return <Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
      <Typography variant="caption" fontWeight={600}>Panels: </Typography>
      <Box width="100%" >
        {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)]?.map((item) =>
          <ProductListItem key={item?._id} product={item?.product ?? ""} code={item?.code ?? ""} quantity={item?.quantity} />
        ) : "NA"}
      </Box>
    </Box>


    <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
      <Typography variant="caption" fontWeight={600}>Inverters: </Typography>
      <Box width="100%" >
        {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)]?.map((item) =>
          <ProductListItem key={item?._id} product={item?.product ?? ""} code={item?.code ?? ""} quantity={item?.quantity} />
        ) : "NA"}
      </Box>
    </Box>

    <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
      <Typography variant="caption" fontWeight={600}>Batteries: </Typography>
      <Box width="100%" >
        {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)]?.map((item) =>
          <ProductListItem key={item?._id} product={item?.product ?? ""} code={item?.code ?? ""} quantity={item?.quantity} />
        ) : "NA"}
      </Box>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
      <Typography variant="caption" fontWeight={600}>Other Components: </Typography>
      <Box width="100%" >
        {Object.keys(productObj)
          ?.filter((productCategory) => ![titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS), titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS), titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)].includes(productCategory))
          ?.map((productCategory) => {
            const items = productObj[productCategory]

            return items?.map((item) => <ProductListItem key={item?._id} product={item?.product ?? ""} code={item?.code ?? ""} quantity={item?.quantity} />)
          })}
      </Box>
    </Box>
  </Box>
})
const FormStepFirst = forwardRef(({ fields, setFields, data, dataFetchLoading, loading, customerSignatureRef, installerSignatureCanavsRef, validateFun }, ref) => {


  return (

    <Box component={Paper} p={3} flexDirection="column" sx={{ display: "flex", flex: 1, overflow: "auto" }}>

      <Box width="50%" >
        <FileInputImage
          disabled={loading}
          onlyImage
          defaults={
            fields?.selfie ? [fields?.selfie] : undefined
          }
          componentProps={{
            name: "Selfie :",
            url: fields?.selfie,
          }}
          onChange={(newUrl) => {
            setFields({
              ...fields,
              selfie: newUrl
            });
          }}
          onDelete={() => {
            setFields({ ...fields, selfie: "" });
          }}
          FileComponent={ImageUploadComponent}
        />

      </Box>
      <Box mt={3} >
        <Typography>Design</Typography>
        <Grid container spacing={2} >
          <Grid item xs={6} >
            <FileInputImage
              disabled={true}
              onlyImage
              defaults={
                data?.project_id?.selected_system?.system_img ? [data?.project_id?.selected_system?.system_img] : undefined
              }
              componentProps={{
                name: "propsed design",
                url: fields?.selfie,
                disabled: true
              }}
              onChange={(newUrl) => {
                setFields({
                  ...fields,
                  selfie: newUrl
                });
              }}
              onDelete={() => {
                setFields({ ...fields, selfie: "" });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>
          <Grid item xs={6} >

            <FileInputImage
              disabled={loading}
              onlyImage
              defaults={
                fields?.new_design ? [fields?.new_design] : undefined
              }
              componentProps={{
                name: "To Be Installed Design",
                subtitle: "Same as proposed",
                url: fields?.new_design,
              }}
              onChange={(newUrl) => {
                setFields({
                  ...fields,
                  new_design: newUrl
                });
              }}
              onDelete={() => {
                setFields({ ...fields, new_design: null });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} >
        <CustomInput
          disabled={loading}
          value={fields.inverter_location}
          onChange={(e) =>
            setFields({ ...fields, err: "", inverter_location: e.target.value })
          }
          type="text"
          label={"Inverter Location*"}
        />
        <CustomInput
          multiline={true}
          rows={3}
          disabled={loading}
          value={fields.installer_remarks}
          onChange={(e) =>
            setFields({ ...fields, err: "", installer_remarks: e.target.value })
          }
          type="text"
          placeholder="type NA if nothing to write"
          label={"Installer Remarks*"}
        />
      </Box>
      <Typography>Installer Signature</Typography>
      <Box  >

        <SignatureCanvas
          onChange={() => { setFields({ ...fields, signatureRefRefreshKey: "IS" + Math.random() }) }}
          ref={installerSignatureCanavsRef}
        ></SignatureCanvas>
      </Box>


    </Box>

  );
});
const FormStepSecond = forwardRef(({ fields, setFields, data, dataFetchLoading, loading, customerSignatureRef, installerSignatureCanavsRef, validateFun }, ref) => {


  return (

    <Box component={Paper} p={3} flexDirection="column" sx={{ display: "flex", flex: 1, overflow: "auto" }}>




      <Box  >
        <Box  >
          <Grid container spacing={2} >

            <Grid item xs={6} >
              <Paper variant="outlined" >
                <Typography variant="caption" display="block" align="center" px={2}>Propsed System</Typography>
                {data?.project_id?.selected_system?.system_img && <ImageComponent
                  sx={{ width: "100%", height: "150px", objectFit: "contain" }}
                  src={data?.project_id?.selected_system?.system_img}
                />}
              </Paper>
            </Grid>
            <Grid item xs={6} >
              <Paper variant="outlined" >
                <Typography variant="caption" display="block" align="center" px={2}>To be installed system</Typography>

                {(data?.project_id?.selected_system?.system_img || (fields.new_design && fields.new_design != '')) && <ImageComponent
                  sx={{ width: "100%", height: "150px", objectFit: "contain" }}
                  src={fields.new_design || data?.project_id?.selected_system?.system_img}
                />}
              </Paper>
            </Grid>

          </Grid>
        </Box>
        <Box  >
          <Typography>System To Be install</Typography>
          <Paper variant="outlined" component={Box} p={2}>
            <CenteredBox p={2}>
              {data?.project_id?.selected_system_size ?? 0} KW
            </CenteredBox>
            <ProductsListingComponent products={data?.project_id?.selected_system?.products ?? []} />
          </Paper>


        </Box>
        <Typography>Terms</Typography>
        <Typography variant="caption" >
          <Box mb={2} variant="outlined" bgcolor="#f3f3f3" component={Paper} sx={{ maxHeight: "200px", overflow: "auto" }} p={2}
            dangerouslySetInnerHTML={{ __html: data?.configs?.before_installation_text ?? "NA" }}

          >

          </Box>
        </Typography>
        <FormControlLabel control={<Checkbox checked={!!fields.acceptTerms} onChange={(e) => setFields({ ...fields, acceptTerms: e.target.checked })} />} label="I agree all above terms." />
        <Typography>Customer Signature</Typography>
        <SignatureCanvas
          onChange={() => { setFields({ ...fields, customerSignatureRefKey: "CS" + Math.random() }) }}
          ref={customerSignatureRef}
        ></SignatureCanvas>
      </Box>


    </Box>

  );
});

const StartInstallationFormUi = forwardRef(({ fields, setFields, onSubmit, data, dataFetchLoading, loading, customerSignatureRef, installerSignatureCanavsRef, validateFun }, ref) => {

  const navigate = useNavigate();
  return (
    <>
      <Box
        mb={2}
        sx={{
          padding: "10px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          border: "1px solid #ccc",
          // position:"fixed"
        }}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/jobs")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>jobs</Typography>
          </Link>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/installation")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>
              Installation
            </Typography>
          </Link>
          <Typography sx={{ color: "text.primary" }}>Start</Typography>
        </Breadcrumbs>
      </Box>
      {
        dataFetchLoading && <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      }
      {!dataFetchLoading && data?._id &&
        <>
          <Paper component={Box} p={2} mb={1} >
            <Typography>{data?.project_id?.uid}</Typography>
            <Typography typography="body2" > <Place fontSize="inherit" /> {data?.project_id?.full_address}</Typography>
          </Paper>
          {<>
            <Box display={fields.step != 1 ? "none" : 'flex'} flexDirection="column" flex={1} overflow="hidden">
              <FormStepFirst
                fields={fields}
                setFields={setFields}
                onSubmit={onSubmit}
                data={data}
                dataFetchLoading={dataFetchLoading}
                loading={loading}
                customerSignatureRef={customerSignatureRef}
                installerSignatureCanavsRef={installerSignatureCanavsRef}
                validateFun={validateFun}
              />
              <Box mt={1} display="flex" component={Paper} >
                
                <Button key={fields.signatureRefRefreshKey} variant="contained" disabled={!validateFun(0)} fullWidth onClick={() => setFields({ ...fields, step: 2 })} >Next</Button>
              </Box>
            </Box>
          </>}


          {
            <>
              <Box display={fields.step != 2 ? "none" : 'flex'} flexDirection="column" flex={1} overflow="hidden">
                <FormStepSecond
                  fields={fields}
                  setFields={setFields}
                  onSubmit={onSubmit}
                  data={data}
                  dataFetchLoading={dataFetchLoading}
                  loading={loading}
                  customerSignatureRef={customerSignatureRef}
                  installerSignatureCanavsRef={installerSignatureCanavsRef}
                  validateFun={validateFun}
                />
                 <Box mt={1} display="flex" component={Paper} >
                 <Button startIcon={<ArrowBack />} sx={{mr:1}} variant="outlined" fullWidth onClick={()=>{setFields({...fields,step:1})}}>Back</Button>
                  <SubmitButton key={fields.customerSignatureRefKey} variant="contained" loading={loading} disabled={!validateFun(1) || loading} fullWidth onClick={onSubmit} title="Submit" />
                </Box>
              </Box>
            </>
          }
        </>
      }


      {!dataFetchLoading && !data?._id && <Box p={3} sx={{ backgroundColor: "white", display: "flex", flex: 1 }}>

        <NoDataComponent message="Invalid job" />
      </Box>}
    </>
  );
});

export default StartInstallationFormUi;
