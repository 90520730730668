import { useTheme } from "@emotion/react";
import {
  AccountCircle,
  AttachMoney,
  Business,
  CalendarMonth,
  CurrencyRupee,
  Place,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Skeleton,
  styled,
  Card,
  TablePagination,
  CardContent,
  Tooltip,
  ButtonBase,
  Chip,
} from "@mui/material";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import {
  findObjectKeyByValue,
  invoiceStatusColor,
} from "../../utils/helpers/helper";
import { INVOICE_STATUS } from "../../utils/constants/constants";

const InvoiceUi = ({
  list,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  setFilters,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <Box>
        <Box
          mb={2}
          sx={{
            padding: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
            border: "1px solid #ccc",
            // position:"fixed"
          }}
        >
          <Breadcrumbs>
            <Link
              underline="hover"
              color="black"
              onClick={() => navigate("/invoice-view")}
              sx={{ cursor: "pointer", color: "black" }}
            >
              <Typography sx={{ color: "text.primary" }}>Invoice</Typography>
            </Link>
          </Breadcrumbs>
        </Box>
        <Paper component={Box} p={3} mt={2}>
          <Grid
            mt={3}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
            sx={{ scrollbarWidth: "none" }}
          >
            {loading ? (
              [0].map((row) => (
                <Grid item xs={5.8}>
                  <Skeleton
                    variant="rectangular"
                    width={"auto"}
                    height={"30vh"}
                  />
                </Grid>
              ))
            ) : list.total > 0 ? (
              list.result?.map((item) => (
                // <InstallationCards
                //   {...item}
                //   setParams={setParams}
                // />

                <Card
                  variant="outlined"
                  sx={{
                    border: "1px solid " + theme.palette.primary.main,
                    borderRadius: "10px",
                  }}
                  component={Box}
                  mb={1}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="body1">
                        {item?.project?.uid} ({item.invoice_no})
                      </Typography>

                      <Chip
                        sx={{ borderRadius: 1 }}
                        size="small"
                        color={invoiceStatusColor(item?.status)}
                        // label="ddddddd"
                        // label={(item?.status)}
                        label={findObjectKeyByValue(
                          item?.status,
                          INVOICE_STATUS
                        )}
                      />
                      {/* <PostInstallationStatusComponent
            onlyview
            params={item}
            setParams={setParams}
          /> */}
                    </Box>

                    <Typography
                      color={"grey"}
                      variant="subtitle2"
                      alignItems="center"
                      display="flex"
                    >
                      <Business fontSize="inherit" />
                      &nbsp;
                      {item.company_id.name}
                    </Typography>
                    <Typography
                      color={"grey"}
                      variant="subtitle2"
                      alignItems="center"
                      display="flex"
                    >
                      <CalendarMonth fontSize="inherit" />
                      &nbsp;
                      {moment(item.due_date).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography
                      color={"grey"}
                      variant="subtitle2"
                      alignItems="center"
                      display="flex"
                    >
                      <AttachMoney fontSize="inherit" />
                      &nbsp;
                      {item.amount}
                    </Typography>
                  </CardContent>
                  <Box
                    p={2}
                    pt={0}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    {
                      <>
                        <Tooltip title="View Details">
                          {/* <Button
                onClick={() => {
                  navigate("/invoice-view", {
                    state: { data: item },
                  });
                }}
              >
                <RemoveRedEye />
              </Button> */}
                          <ButtonBase
                            onClick={() => {
                              navigate("/invoice-view", {
                                state: { data: item },
                              });
                            }}
                          >
                            <Typography
                              sx={{ textDecoration: "underline" }}
                              mx={3}
                              variant="body2"
                            >
                              View {">>"}
                            </Typography>
                          </ButtonBase>
                        </Tooltip>
                        <Box>
                          {/* {item.status == POST_INSTALLATION_STATUS.PENDING && ( */}
                          {/* <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate("/post-installation-form", {
                      state: { userId: item._id },
                    });
                  }}
                >
                  Start Installation
                </Button> */}
                          {/* )} */}
                          {/* {item.status == POST_INSTALLATION_STATUS.SOLVED && ( */}
                          {/* <RequestApprovalButton
                  type={APPROVAL_REQUEST_TYPE.POST_INSTALLATION}
                  id={item?._id}
                /> */}
                          {/* )} */}
                        </Box>
                      </>
                    }
                  </Box>
                </Card>
                // </>
              ))
            ) : (
              <Grid item xs={12}>
                <Card
                  width="100%"
                  variant="outlined"
                  sx={{
                    border: "1px solid #002050",
                    borderRadius: "10px",
                    marginBottom: "10px",
                    height: "30vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CardContent>
                    <Typography> No Data Available!</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={list?.total}
            rowsPerPage={filters?.pageSize}
            page={filters?.pageNo - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default InvoiceUi;
