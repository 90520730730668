import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/helpers/helper";

export const createApprovalRequest = async (data) => {
    const callResponse = await axios({
      url: endpoints.approvalRequestBase,
      method: "POST",
      headers: getHeaders(),
  
      data,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  };