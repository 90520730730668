import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({ setIsUpdate, setUpdate }) => {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsUpdate(false)
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "300px",
            height: "200px",
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", color: "#333" }}>
          Update Profile
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography sx={{ color: "#555", fontSize: "16px" }}>
              To continue enjoying our services, please update your profile
              details.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setIsUpdate(false);
              handleClose();
            }}
          >
            Skip
          </Button>
          <Button
            // sx={{
            //   backgroundColor: "black",
            //   color: "white",
            //   fontWeight: "bold",
            //   borderRadius: "5px",
            //   textTransform: "uppercase",
            //   padding: "8px 16px",
            //   "&:hover": {
            //     backgroundColor: "primary",
            //   },
            // }}
            disableElevation
            variant="contained"
            onClick={() => {
              setUpdate(true);
              setIsUpdate(false);
              handleClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialogSlide;
