import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/helpers/helper";

export const getPostInstallationListApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.postInstallationDataFetch,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  };
  
  export const getPostInstallationDataCountrApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.postInstallationDataCount,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  };
  
  export const getPostInstallationByIdApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.postInstallationDataFetchById,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  }

  export const updatePostInstallationApi = async data => {
    const callResponse = await axios({
      url: endpoints.postInstallationUpdate,
      method: "PATCH",
      headers: getHeaders(),
      data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  