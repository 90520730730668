import { useCallback, useEffect, useState,useMemo, memo } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"

import CreateController from "./CreateController"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
// import { getActivityApi } from "../../apis/activity.api"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import {getAggrementApi,deleteAggrementApi } from "../../apis/aggrement.api"
import moment from "moment"
import MessageDilog from "../../components/MessageDilog"
import { Box, CircularProgress, IconButton } from "@mui/material"
import { Delete, Edit } from "@mui/icons-material"

import UpdateActiveAggrementButton from "./UpdateActiveAggrementButton"
import { SIGN_STATUS } from "../../utils/constants/constants"
import AggrementDetailViewUi from "./AggrementDetailsUi"
import UpdateSignatureController from "../signature/UpdateSignatureController"

const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(0)
    const onDelete = (e) => {
        e?.preventDefault?.()
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteAggrementApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onDeleteBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={onDelete} title="Alert!" message="Are you sure to delete??" />, "xs", undefined))
    }
    const onEditBtnClick = () => {
        dispatch(openModal(<CreateController id={params?._id} callBack={(res,editableData)=>setParams({...params,...editableData})} />, "xs", undefined,"add-aggrement"))
    }

    if (loading)
        return <CircularProgress />
    return <Box sx={{ width: "100%", display: "flex" }}>

        {<IconButton disableElevation variant="contained" size="small" onClick={onEditBtnClick}>
            <Edit color="info" />
        </IconButton>}
        {<IconButton disableElevation variant="contained" size="small" onClick={onDeleteBtnClick}>
            <Delete color="error" />
        </IconButton>}



    </Box>
})

const ListController = () => {
    const dispatch = useDispatch()
    const installer = useSelector(state=>state.user)
    
    const installer_id = installer.data._id;
    const title = "Aggrement";
    const modalKey = "aggrement-list";
    const fetchApi = getAggrementApi
    // const fetchByIdApi = getInstalleByIdApi;

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 2,
        search: '',
        searchable: ['title'],
        sort: 'expiry_date',
        installer_id,
        sortDirection: -1,
        signed_date:"",
        status:SIGN_STATUS.UNSIGNED,
        active:false,
    })
    // do not change 

    
  

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const fetchList =() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({...filters, }),
            (response) => {
                console.log("resposness",response)
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

   
    useEffect(() => {
        fetchList()
    }, [filters])

  
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController installer_id={installer_id} callBack={fetchList} />, 'xs', undefined,"add-aggrement"))
    }
  

    return (
        <>
            <ListUi
                title={title}
                modalKey={modalKey}
                // modal={modal}
                // isActive={isActive}
                // setIsActive={setIsActive}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                setList={setList}
                // onViewMore={onViewMore}
                // onActive={onActive}
                // columns={columns}
                callBack={fetchList}

            />

        </>
    )
}
export default ListController