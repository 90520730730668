import {
  Box,

  Fade,

  Grow,

  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { memo } from "react";
import AppModeLabel from "../../texts/AppModeLabel";
import PopUpModal from "../../Modal";
import Header from "./Header";

import VersionDetailsController from "../../../pages/versions/VersionDetailsController";
import { openModal } from "../../../store/actions/modalAction";
import { useEffect, useMemo, useState } from "react";

import { lastReadVersion } from "../../../utils/helpers/helper";
import NavigationComponent from "./NavigationComponent";
import DashboardController from "../../../pages/dashboard/DashboardController";


const workAreaStyle = (theme, overflow = "unset") => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  overflow,
  flex: 1,

  // background: theme.palette.grey.main,
  borderTopLeftRadius: theme.shape.borderRadius * 1,
});

const AppContainer = ({ overflow, ...props }) => {
  const { user } = useSelector((state) => state);
  const location = useLocation()
  const [navBar, setNavbar] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    const lastVersion = lastReadVersion.get();
    const splittedVersion = lastVersion?.split?.("-") ?? [];

    if (
      user.data.web_version &&
      (!lastVersion ||
        splittedVersion[0] != user.data.web_version?.main ||
        splittedVersion[1] != user.data.web_version?.sub)
    ) {
      dispatch(
        openModal(
          // title: "New Update",
          <VersionDetailsController />,
          "md",
          undefined,
          "version-details"
        )
      );
    }
  }, []);

  return (
    <>
      {process.env.REACT_APP_APP_MODE != "production" && <AppModeLabel />}
      <PopUpModal />
      <Box sx={{
        height: "100%",
        maxWidth: theme.breakpoints.values.sm,
        border: !isSmallScreen ? "1px solid grey" : undefined,
        margin: "auto",
        width: "100%", display: "flex", flexDirection: "column", overflow: "hidden",
      }}>

        <Header open={navBar} setOpen={setNavbar} />
        <DashboardController>
          <Grow in={true} key={location.pathname} >
            <Box p={3} sx={(theme) => workAreaStyle(theme, overflow)}>

              <Outlet />
              {props.children}

            </Box>
          </Grow>
        </DashboardController>
        <NavigationComponent />
      </Box>
    </>
  );
};
export default memo(AppContainer);
