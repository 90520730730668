import { actions } from "../../utils/constants/constants";

const initialState = {
  filters: {

  },

  counts: [],
  data: {}

};
const postInstallationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_POST_INSTALLATION_DATA_FILTERS:
      return { ...state, filters: action.value };
    case actions.HARD_REFRESH_POST_INSTALLATION_DATA:
      const filters = {...state.filters}
      delete filters['refresh'] 
      return { ...state, filters: filters };
    case actions.UPDATE_POST_INSTALLATION_DATA_COUNTS:
      return { ...state, counts: action.value };
    case actions.UPDATE_POST_INSTALLATION_DATA_LIST:
      return { ...state, data: action.value };
    default:
      return { ...state };
  }
};

export default postInstallationReducer;
