import { useTheme } from "@emotion/react";
import { AccountCircle, CalendarMonth, Download, PendingActions, Place } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { getInspectionByIdApi } from "../../../apis/inspection.api";
import { findObjectKeyByValue } from "../../../utils/helpers/helper";
import {
  APPROVAL_REQUEST_STATUS,
  APPROVAL_REQUEST_TYPE,
} from "../../../utils/constants/approvalRequest.constant";
import PaymentStatusComponent from "../detailed-view/PaymentStatusComponent";
import FileDownloadComponent from "../../../components/FileDownloadComponent";
import { getPostInstallationByIdApi } from "../../../apis/postinstallation.api";
import { PaymentDetailsComponent } from "../ViewDetailsUi";
import { getAssignedInstallationByIdApi } from "../../../apis/assignedinstallation.api";
import JobDetailsUi from "../detailed-view/JobDetailsUI";
import { ASSIGNED_INSTALALTION_STATUS } from "../../../utils/constants/constants";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import NoDataComponent from "../../../components/layouts/NoDataComponent";
import ImageComponent from "../../../components/ImageComponent";
import SubmitButton from "../../../components/button/SubmitButton";

const JobComponent = ({ data }) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();


  return (
    <>
      <Paper component={Box} p={2}>

        {
          data?.status >= ASSIGNED_INSTALALTION_STATUS.INSTALLATION_STARTED ? <>
            <Grid container spacing={2} >
              <Grid item xs={6} >
                <Paper variant="outlined" >
                  <Typography variant="caption" pl={1}  >Before Installation Selfie</Typography>
                  <Divider />
                  {data?.data?.before_installation_selfie ? <ImageComponent src={data?.data?.before_installation_selfie} sx={{ width: "100%", height: "100px", objectFit: "contain" }} /> : <CenteredBox>
                    <Typography>NA</Typography>
                  </CenteredBox>}
                </Paper>

                {data?.data?.caf_1 && <FileDownloadComponent   CustomComponent={({loading,...remained})=><SubmitButton {...remained} title="CAF 1" loading={loading} startIcon={<Download/>} size="small" fullWidth variant="contained" disableElevation mt={1}> 
                  CAF1
                </SubmitButton>} src={data?.data?.caf_1?.files?.[0]} />}
              </Grid>
              <Grid item xs={6} >

                <Paper variant="outlined" >
                  <Typography variant="caption" pl={1}  >After Installation Selfie</Typography>
                  <Divider />
                  {data?.data?.after_installation_selfie ? <ImageComponent src={data?.data?.after_installation_selfie} sx={{ width: "100%", height: "100px", objectFit: "contain" }} /> : <CenteredBox>
                    <Typography>NA</Typography>
                  </CenteredBox>}
                  <CenteredBox>
                  {data?.data?.caf_2 && <FileDownloadComponent   CustomComponent={({loading,...remained})=><SubmitButton {...remained} title="CAF 2" loading={loading} startIcon={<Download/>} size="small" fullWidth variant="contained" disableElevation mt={1}> 
                  
                </SubmitButton>} src={data?.data?.caf_2?.files?.[0]} />}
                </CenteredBox>
                </Paper>
              </Grid>
            </Grid>
          </> : <CenteredBox component={Paper} variant="outlined">
            <NoDataComponent iconSize={{ color: "grey" }} iconVariant="h3" Icon={PendingActions} variant="body1" message="Installation Not Started Yet" />
          </CenteredBox>
        }

      </Paper>
    </>
  );
};

const InstallationViewDetailsUi = () => {

  return <Box>

    <JobDetailsUi
      JobDetailComponent={JobComponent}
      title="Installation"
      parentPath="installation"
      apiFun={getAssignedInstallationByIdApi} />
  </Box>
}
export default InstallationViewDetailsUi;
