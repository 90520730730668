import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getInvoiceApi } from "../../apis/invoice.api";
import InvoiceUi from "./InvoiceUi";

const InvoiceController = () => {
  const dispatch = useDispatch();
  const fetchApi = getInvoiceApi;

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    role: "",
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  console.log("list",list);

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <InvoiceUi
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
      />
    </>
  );
};
export default InvoiceController;
