import { useState } from "react"
import SubmitButton from "../../components/button/SubmitButton"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { downloadAggrementPdf } from "../../apis/aggrement.api"
import fileDownload from "js-file-download"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"

const DownloadPdfButton = ({ id, title }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const onClick = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await downloadAggrementPdf({ id }),
                async (response) => {

                    fileDownload(response, title + ".pdf")

                    setLoading(false)
                    dispatch(callSnackBar("Aggrement Downloaded", SNACK_BAR_VARIETNS.suceess))

                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err ?? "OOPS! something wrong", SNACK_BAR_VARIETNS.error))
                },
                true
            )
        )
    }
    return <SubmitButton disabled={loading} onClick={onClick} loading={loading} title={"Download"} />

}
export default DownloadPdfButton