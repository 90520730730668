import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { Fragment, memo } from "react";


import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import FileInput from "../../components/inputs/FileInput";

import { APPROVAL_REQUEST_TYPE } from "../../utils/constants/approvalRequest.constant";
import { Link, useNavigate } from "react-router-dom";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Paper component={'form'} onSubmit={onSubmit} p={2} sx={{ minHeight: "100%", display: "flex", flexDirection: "column", p: 2 }} >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Box display="flex" flexDirection="column" flex={1} >
              <Typography mb={2} fontWeight={600} variant="h6">
                {title}
              </Typography>
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.invoice_no}
                onChange={(e) =>
                  setFields({ ...fields, err: "", invoice_no: e.target.value })
                }
                type="text"
                label={"Invoice No*"}
              />
              {
                <CustomInput
                  disabled={loading}
                  value={fields.amount}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", amount: e.target.value })
                  }
                  type="number"
                  label={"Amount*"}
                />
              }
              <FileInput
                defaults={
                  fields.attached_invoice ? [fields.attached_invoice] : []
                }
                title="Invoice*"
                subTitle="Only .pdf less than 2mb are valid."
                accept=".pdf"
                onChange={(newUrl) => {
                  setFields({ ...fields, attached_invoice: newUrl });
                }}
              />
              {fields.type == APPROVAL_REQUEST_TYPE.INSTALLATION && <>

                <FileInput
                  defaults={
                    fields.ces_certi ? [fields.ces_certi] : []
                  }
                  title="CES*"
                  subTitle="Only .pdf less than 2mb are valid."
                  accept=".pdf"
                  onChange={(newUrl) => {
                    setFields({ ...fields, ces_certi: newUrl });
                  }}
                />
                <FileInput
                  defaults={
                    fields.signed_ewr ? [fields.signed_ewr] : []
                  }
                  title="Signed EWR*"
                  subTitle="Only .pdf less than 2mb are valid."
                  accept=".pdf"
                  onChange={(newUrl) => {
                    setFields({ ...fields, signed_ewr: newUrl });
                  }}
                />
              </>}
            </Box>
            <Box display="flex" >
              <Box display="flex" flex={1} >
                <Button fullWidth disableElevation variant="outlined" onClick={() => navigate(-1, { replace: true })}> Back </Button>
              </Box>
              <Box display="flex" flex={1} ml={2} >
                <Button fullWidth disableElevation type="submit" variant="contained" >Submit</Button>
              </Box>

            </Box>
          </>
        )}
      </Paper>
    </>
  );
};
export default memo(CreateUi);
