import { useTheme } from "@emotion/react";

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getPostInstallationByIdApi } from "../../../apis/postinstallation.api";
import { findObjectKeyByValue, groupByArray, titleCase } from "../../../utils/helpers/helper";
import ImageComponent from "../../../components/ImageComponent";
import { CATEGORY } from "../../../utils/constants/constants";
import JobDetailsUi from "../detailed-view/JobDetailsUI";



const InstallationComponents = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const props = location?.state?.data;

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: "1px solid " + theme.palette.primary.main,
          borderRadius: "10px",
        }}
        component={Box}
        mt={2}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
              >
                Category :-&nbsp;
                {findObjectKeyByValue(props?.installation_data?.category, CATEGORY) ?? "NA"}
              </Typography>
            </Grid>
          </Grid>

          {props?.installation_data?.category == CATEGORY.INVERTER_ISSUE &&
            <>
              {/* old Inverter Photos Section */}
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Old Inverter Photos
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Front Photo: &nbsp;&nbsp;
                    {props?.installation_data?.old_inverter_photos?.front_photo ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.old_inverter_photos?.front_photo}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Inverter Photo: &nbsp;&nbsp;
                    {props?.installation_data?.old_inverter_photos?.inverter_screen_photo ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.old_inverter_photos?.inverter_screen_photo}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Serial Label Number : &nbsp;&nbsp;
                    {props?.installation_data?.old_inverter_photos?.serial_number_label ?? "NA"}
                  </Typography>
                </Grid>
              </Grid>

              {/* New Inverter Photos Section */}

              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  New Inverter Photos
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Front Photo: &nbsp;&nbsp;
                    {props?.installation_data?.new_inverter_photos?.front_photo ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.new_inverter_photos?.front_photo}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Inverter Photo: &nbsp;&nbsp;
                    {props?.installation_data?.new_inverter_photos?.inverter_screen_photo ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.new_inverter_photos?.inverter_screen_photo}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Serial Label Number : &nbsp;&nbsp;
                    {props?.installation_data?.new_inverter_photos?.serial_number_label ?? "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    AC Volatage Photo: &nbsp;&nbsp;
                    {props?.installation_data?.new_inverter_photos?.ac_voltage_photo ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.new_inverter_photos?.ac_voltage_photo}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    DC Volatage Photo: &nbsp;&nbsp;
                    {props?.installation_data?.new_inverter_photos?.dc_voltage_photo ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.new_inverter_photos?.dc_voltage_photo}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
          {props?.installation_data?.category == CATEGORY.LEAKAGE_ISSUE &&
            <>
              {/* Image Celling Leakage */}
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Images Ceiling Leakage
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 1 : &nbsp;&nbsp;
                    {props?.installation_data?.images_ceiling_leakage?.[0] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.images_ceiling_leakage?.[0]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 3 : &nbsp;&nbsp;
                    {props?.installation_data?.images_ceiling_leakage?.[2] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.images_ceiling_leakage?.[2]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 2 : &nbsp;&nbsp;
                    {props?.installation_data?.images_ceiling_leakage?.[1] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.images_ceiling_leakage?.[1]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 4 : &nbsp;&nbsp;
                    {props?.installation_data?.images_ceiling_leakage?.[3] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.images_ceiling_leakage?.[3]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Leakage Reason : &nbsp;&nbsp;
                    {props?.installation_data?.leakage_reason ?? "NA"}
                  </Typography>

                </Grid>
              </Grid>

              {/* Issue Photos */}
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Issues Photos
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 1 : &nbsp;&nbsp;
                    {props?.installation_data?.issue_photos?.[0] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.issue_photos?.[0]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 3 : &nbsp;&nbsp;
                    {props?.installation_data?.issue_photos?.[2] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.issue_photos?.[2]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 2 : &nbsp;&nbsp;
                    {props?.installation_data?.issue_photos?.[1] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.issue_photos?.[1]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 4 : &nbsp;&nbsp;
                    {props?.installation_data?.issue_photos?.[3] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.issue_photos?.[3]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Issues Description : &nbsp;&nbsp;
                    {props?.installation_data?.issue_description ?? "NA"}
                  </Typography>

                </Grid>
              </Grid>

              {/* Repair Photos */}
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Repair Photos
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 1 : &nbsp;&nbsp;
                    {props?.installation_data?.repair_photos?.[0] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={data?.installation_data?.repair_photos?.[0]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 3 : &nbsp;&nbsp;
                    {props?.installation_data?.repair_photos?.[2] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.repair_photos?.[2]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 2 : &nbsp;&nbsp;
                    {props?.installation_data?.repair_photos?.[1] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.repair_photos?.[1]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 4 : &nbsp;&nbsp;
                    {props?.installation_data?.repair_photos?.[3] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.repair_photos?.[3]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Repair Description : &nbsp;&nbsp;
                    {props?.installation_data?.repair_description ?? "NA"}
                  </Typography>

                </Grid>
              </Grid>
            </>}

          {props?.installation_data?.category == CATEGORY.SOLAR_PANAL_NOT_WORKING &&
            <>
              {/* AC DC Volatage Power Photos */}
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  AC DC Power Volatage Photos
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 1 : &nbsp;&nbsp;
                    {props?.installation_data?.ac_dc_power_voltage_photos?.[0] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.ac_dc_power_voltage_photos?.[0]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 3 : &nbsp;&nbsp;
                    {props?.installation_data?.ac_dc_power_voltage_photos?.[2] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.ac_dc_power_voltage_photos?.[2]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 2 : &nbsp;&nbsp;
                    {props?.installation_data?.ac_dc_power_voltage_photos?.[1] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.ac_dc_power_voltage_photos?.[1]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 4 : &nbsp;&nbsp;
                    {props?.installation_data?.ac_dc_power_voltage_photos?.[3] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.ac_dc_power_voltage_photos?.[3]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Is Panel Damage : &nbsp;&nbsp;
                    {(props?.installation_data?.panel_damage === true ? "Yes" : "No") ?? "NA"}
                  </Typography>

                </Grid>
              </Grid>

              {/* Panel Damage Photos */}
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Panel Damage Photos
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 1 : &nbsp;&nbsp;
                    {props?.installation_data?.panel_damage_photos?.[0] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.panel_damage_photos?.[0]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 3 : &nbsp;&nbsp;
                    {props?.installation_data?.panel_damage_photos?.[2] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.panel_damage_photos?.[2]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 2 : &nbsp;&nbsp;
                    {props?.installation_data?.panel_damage_photos?.[1] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.panel_damage_photos?.[1]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Image 4 : &nbsp;&nbsp;
                    {props?.installation_data?.panel_damage_photos?.[3] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.panel_damage_photos?.[3]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Reason For Panel Not Working : &nbsp;&nbsp;
                    {props?.installation_data?.reason_panel_not_working ?? "NA"}
                  </Typography>

                </Grid>
              </Grid>
            </>
          }

          {props?.installation_data?.category == CATEGORY.WIFI_CONFIGRATION &&
            <>
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Wifi Configuration
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Wifi Configuration : &nbsp;&nbsp;
                    {props?.installation_data?.wifi_configuration ?? "NA"}
                  </Typography>

                </Grid>


              </Grid>

            </>}

          {props?.installation_data?.category == CATEGORY.OTHERS &&
            <>
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  Others Details
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Reason : &nbsp;&nbsp;
                    {props?.installation_data?.reason ?? "NA"}
                  </Typography>

                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    Photos (Minimum 2)
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Photo 1 : &nbsp;&nbsp;
                    {props?.installation_data?.photos?.[0] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.photos?.[0]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Photo 3 : &nbsp;&nbsp;
                    {props?.installation_data?.photos?.[2] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.photos?.[2]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Photo 2 : &nbsp;&nbsp;
                    {props?.installation_data?.photos?.[1] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.photos?.[1]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                  // display="flex"
                  >
                    Photo 4 : &nbsp;&nbsp;
                    {props?.installation_data?.photos?.[3] ? (
                      <Box
                        sx={{
                          position: "relative",
                          height: "150px",
                        }}
                      >
                        <ImageComponent
                          sx={{
                            layout: "fill",
                            objectFit: "cover",
                          }}
                          src={props?.installation_data?.photos?.[3]}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f0f0f0",
                          color: "#777",
                        }}
                      >
                        No image found
                      </Box>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={"grey"}
                    variant="subtitle2"
                    alignItems="center"
                    display="flex"
                  >
                    Resolution : &nbsp;&nbsp;
                    {props?.installation_data?.resolution ?? "NA"}
                  </Typography>

                </Grid>
              </Grid>

            </>}
        </CardContent>
      </Card>
    </>
  );
};



const InstallationViewDetailsUi = () => {
  return <Box>

    <JobDetailsUi
      JobDetailComponent={InstallationComponents}
      title="Post Installation"
      parentPath="post-installation"
      apiFun={getPostInstallationByIdApi} />
  </Box>
}
export default InstallationViewDetailsUi;
