import { Tab, Tabs, styled } from "@mui/material";

export const CustomTabs = styled(({...props}) => <Tabs
    TabIndicatorProps={{
        style: {
            display: "none"
        }
    }}
    {...props}
/>)(({ theme }) => ({
    background: theme.palette.primary.light,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    padding:2,
    paddingBottom:0,
}))
export const CustomTab = styled(Tab)(({ theme ,active,selected}) => ({
    background: (selected||active) ? "white" : "",
    border: 5,
    borderBottom: 0,
    opacity: 1,
    color: (selected||active) ? theme.palette.dark.main : theme.palette.dark.main,
    borderColor: theme.palette.primary.light,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2
}))