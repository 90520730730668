import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";

import CreateUi from "./CreateUi";

import { createApprovalRequest } from "../../apis/approvalreq.api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import { APPROVAL_REQUEST_TYPE } from "../../utils/constants/approvalRequest.constant";

const CreateController = ({ title = "Request Payment", callBack = () => { }, type, id }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();


  const createApi = createApprovalRequest;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    type,
    attached_invoice: null,
    amount: null,
    signed_ewr: null,
    invoice_no: ""
  });

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.type,
        field: "Type",
      },

      {
        required: true,
        value: fields.invoice_no,
        field: "Invoice No",
      },
      {
        required: true,
        value: fields.attached_invoice,
        field: "Invoice",
      },
      {
        required: true,
        value: fields.amount,
        field: "Amount",
      },
      {

        value: fields.ces_certi,
        field: "Ces Certi",
        custom: () => {
          if (fields.type == APPROVAL_REQUEST_TYPE.INSTALLATION && (!fields.ces_certi || fields.ces_certi == ''))
            return "Ces Certificate is required"
          return true
        }
      }, {

        value: fields.signed_ewr,
        field: "Signed EWR",
        custom: () => {
          if (fields.type == APPROVAL_REQUEST_TYPE.INSTALLATION && (!fields.signed_ewr || fields.signed_ewr == ''))
            return "Signed EWR is required"
          return true
        }
      }
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
      dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error))
    }
  };



  const onSubmit = async (e) => {
    e.preventDefault();

    createFunction();
  };


  return (
    <CreateUi
      title={title}

      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
