import React, { useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  AccountCircle,
  Business,
  CalendarMonth,
  Place,
} from "@mui/icons-material";
import { POST_INSTALLATION_STATUS } from "../../../utils/constants/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RequestApprovalButton from "../../approval-request/RequestApprovalButton";
import { APPROVAL_REQUEST_TYPE } from "../../../utils/constants/approvalRequest.constant";
import PostInstallationCountsController from "./PostInstallationCountsController";
import PostInstallationStatusComponent from "./StatusComponent";
import { postInstallationStatusColor } from "../../../utils/helpers/helper";

const InstallationCards = ({ setParams, ...itemProps }) => {
  
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Card
      variant="outlined"
      sx={{
        border: "1px solid " + theme.palette.grey.main,
        borderRadius: "10px",
        //  postInstallationStatusColor(itemProps.status),
        borderLeft: 5,
        borderLeftColor:
          itemProps.status == POST_INSTALLATION_STATUS.PENDING
            ? "gray"
            : itemProps.status == POST_INSTALLATION_STATUS.SOLVED
            ? "#2196f3"
            : itemProps.status == POST_INSTALLATION_STATUS.PENDING_APPROVAL
            ? "#ed6c02"
            : "green",
      }}
      component={Box}
      mb={1}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">{itemProps?.project_id?.uid}</Typography>
          <PostInstallationStatusComponent
            onlyview
            params={itemProps}
            setParams={setParams}
          />
        </Box>
        <Typography
          color={"grey"}
          variant="subtitle2"
          alignItems="center"
          display="flex"
        >
          <CalendarMonth fontSize="inherit" />
          &nbsp;
          {moment(itemProps.assigned_date).format("DD/MM/YYYY HH:mm")}
        </Typography>
        <Typography
          color={"grey"}
          variant="subtitle2"
          alignItems="center"
          display="flex"
        >
          <Business fontSize="inherit" />
          &nbsp;
          {itemProps.company_id?.name}
        </Typography>
        <Typography
          color={"grey"}
          variant="subtitle2"
          alignItems="center"
          display="flex"
        >
          <Place fontSize="inherit" />
          &nbsp;
          {itemProps.project_id.address}
        </Typography>
      </CardContent>
      <Box
        p={2}
        pt={0}
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        {
          <>
            {/* <Tooltip title="View Details">
              <Button
                onClick={() => {
                  navigate("/post-installation-view", {
                    state: { data: itemProps },
                  });
                }}
              >
                <RemoveRedEyeIcon />
              </Button>
            </Tooltip> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }} mx={2}>
              <ButtonBase onClick={() => {
                  navigate("/post-installation-view", {
                    state: { data: itemProps },
                  });
                }}>
                <Typography sx={{ textDecoration: "underline" }}>
                  View
                </Typography>
              </ButtonBase>
            </Box>
            <Box>
              {itemProps.status == POST_INSTALLATION_STATUS.PENDING && (
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate("/post-installation-form", {
                      state: { userId: itemProps._id },
                    });
                  }}
                >
                  Start Installation
                </Button>
              )}
              {itemProps.status == POST_INSTALLATION_STATUS.SOLVED && (
                <RequestApprovalButton
                  type={APPROVAL_REQUEST_TYPE.POST_INSTALLATION}
                  id={itemProps?._id}
                />
              )}
            </Box>
          </>
        }
      </Box>
    </Card>
  );
};

const PostInstallationUi = ({
  list,
  filters,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  setFilters,
}) => {
  const navigate = useNavigate();
  const activeStatus = filters.status;
  return (
    <>
      <Box>
        <Box
          mb={2}
          sx={{
            padding: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
            border: "1px solid #ccc",
            // position:"fixed"
          }}
        >
          <Breadcrumbs>
            <Link
              underline="hover"
              color="black"
              onClick={() => navigate("/jobs")}
              sx={{ cursor: "pointer", color: "black" }}
            >
              <Typography sx={{ color: "text.primary" }}>jobs</Typography>
            </Link>
            <Typography sx={{ color: "text.primary" }}>
              Post Installation
            </Typography>
          </Breadcrumbs>
        </Box>
        {/*  */}

        <Paper component={Box} p={3}>
          <PostInstallationCountsController
            filters={filters}
            setFilters={setFilters}
          />
        </Paper>
        <Paper component={Box} p={3} mt={2}>
          <Grid
            mt={3}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
            sx={{ scrollbarWidth: "none" }}
          >
            {loading ? (
              [0].map((row) => (
                <Grid item xs={5.8}>
                  <Skeleton
                    variant="rectangular"
                    width={"auto"}
                    height={"30vh"}
                  />
                </Grid>
              ))
            ) : list.total > 0 ? (
              list.result?.map((item) => (
                <InstallationCards
                  {...item}
                  // setParams={setParams}
                />
              ))
            ) : (
              <Grid item xs={12}>
                <Card
                  width="100%"
                  variant="outlined"
                  sx={{
                    border: "1px solid #002050",
                    borderRadius: "10px",
                    marginBottom: "10px",
                    height: "30vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CardContent>
                    <Typography> No Data Available!</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={list?.total}
            rowsPerPage={filters?.pageSize}
            page={filters?.pageNo - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default PostInstallationUi;
