import { Box, Button } from "@mui/material";
import { forwardRef, useEffect } from "react";
import SignaturePad from 'react-signature-canvas'

const SignatureCanvas = forwardRef(({ ...props }, ref) => {

    const onClear = ()=>{
        ref.current?.clear()
        props?.onChange?.()
    }
    useEffect(()=>{
        props?.onChange?.()
    },[])
    return <>

        <Box>
            <SignaturePad height="100px" width="300px" onEnd={()=>{props?.onChange?.()}} canvasProps={{ height: "100px", width: "300px", style: { border: "1px solid black", } }} {...props }
                ref={ref}
            />
        </Box>
        <Box sx={{}} >
            <Button disabled={ref?.current?.isEmpty?.()} variant="contained" onClick={onClear}>
                Clear
            </Button>
        </Box>
    </>
})
export default SignatureCanvas