import { memo, useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import ImageComponent from "./../../components/ImageComponent";
import FileDownloadComponent from "./../../components/FileDownloadComponent";
import { FILE_TYPES } from "../../utils/constants/constants";

const ProjectDocumentRowContainer = styled(Box)(({ theme, hasFile }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  border:
    "1px solid " +
    (hasFile ? theme.palette.success.main : theme.palette.primary.main),

  position: "relative",
  "::after": {
    content: "' '",
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    backgroundColor: hasFile
      ? theme.palette.success.main
      : theme.palette.primary.main,
    opacity: 0.1,
    zIndex: 0,
  },
  ".document-title": {},
}));

const FileView = memo(({ id, dispatch, name, accept, type, files }) => {
  if (files?.[0]) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        {type == FILE_TYPES.IMAGES ? (
          <ImageComponent
            viewOnClickOnly
            src={files[0]}
            sx={{
              height: "25px",
              width: "25px",
              objectFit: "cover",
              borderRadius: "100%",
            }}
          ></ImageComponent>
        ) : (
          <Box display="flex">
            <FileDownloadComponent
              color="primary"
              src={files[0]}
              fileNameDefault={
                name +
                "." +
                (files[0]?.split(".")[files[0]?.split(".")?.length - 1] ??
                  "pdf")
              }
              sx={{ padding: 0 }}
            />
            <Box ml={1} />

            <FileDownloadComponent
              view
              color="primary"
              src={files[0]}
              fileNameDefault={
                name +
                "." +
                (files[0]?.split(".")[files[0]?.split(".")?.length - 1] ??
                  "pdf")
              }
              sx={{ padding: 0 }}
            />
          </Box>
        )}
      </Box>
    );
  }

  return <></>;
});

const ProjectDocumentRow = ({ files, id, type, name, updatedAt, dispatch }) => {
  if (!files?.[0]) return <></>;

  return (
    <>
      <ProjectDocumentRowContainer hasFile={files?.length > 0} p={2} mb={1}>
        <Box display="flex" flex={1} zIndex={1}>
          <Typography
            className="document-title"
            variant="subtitle2"
            align="center"
          >
            {name}
          </Typography>
        </Box>
        <Box zIndex={1}>
          {type == FILE_TYPES.IMAGES ? (
            <FileView
              id={id}
              files={files}
              dispatch={dispatch}
              type={FILE_TYPES.IMAGES}
              name={name}
              accept={"image/*"}
            />
          ) : (
            <FileView
              id={id}
              files={files}
              dispatch={dispatch}
              type={FILE_TYPES.PDFS}
              accept={".pdf"}
              name={name}
            />
          )}
        </Box>
      </ProjectDocumentRowContainer>
    </>
  );
};
export default memo(ProjectDocumentRow);
