import moment from "moment";
import { actions } from "../../utils/constants/constants";
import { INSTALLATION_CALENDAR_ITEM_TYPE } from "../../utils/constants/installercalendar.constant";


const initialState = {
    loading: true,
    filters: {
        date: moment().valueOf()
    },
    data: []

}
const installercalendarReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SET_CALENDAR_DATA: return { ...state, data: action.data, loading: false };
        case actions.SET_CALENDAR_LOADING: return { ...state, loading: action.value };
        case actions.SET_CALENDAR_DATA_FILTERS: {
            const filters = { ...state.filters, ...action.filters }
            return { ...state, filters };
        }

        case actions.ADD_AVAILABLITY: {
            const data = [...state.data]
            data.push({ ...(action.value ?? {}), calendarItemType: INSTALLATION_CALENDAR_ITEM_TYPE.AVAILABILITY })
            return { ...state, data };
        }
        case actions.REMOVE_AVAILABLITY: {
            const data = [...state.data]
            const index = data.findIndex((item) => item?._id == action?.id)
            if (index !== -1) {
                data.splice(index, 1)
            }

            return { ...state, data };
        }

        default: return { ...state }
    }

}
export default installercalendarReducer