import { useTheme } from "@emotion/react";
import {
  Download,
  ElectricBolt,
  Paid,
  ShortText,
  
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  APPROVAL_REQUEST_STATUS,
} from "../../../utils/constants/approvalRequest.constant";
import PaymentStatusComponent from "./PaymentStatusComponent";
import FileDownloadComponent from "../../../components/FileDownloadComponent";

const PaymentDetailCardComponent = memo(({ setParams, ...item }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <Card
        variant="outlined"
        sx={{
          border: "1px solid " + theme.palette.primary.main,
          borderRadius: "10px",
        }}
        component={Box}
        mb={2}
      >
        <CardContent sx={{ p: 2, pb: 0, margin: 0 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              color={"grey"}
              variant="subtitle2"
              alignItems="center"
              display="flex"
            >
              {item?.data?.invoice_no}
            </Typography>
            <PaymentStatusComponent onlyview item={item} setParams={setParams} />
          </Box>
          <Typography
            color={"grey"}
            variant="h6"
            alignItems="center"
            display="flex"
          >
            <Paid fontSize="inherit" />
            &nbsp;
            {item?.data?.amount}
          </Typography>
  
          {item?.rejection_reason && (
            <Typography
              color={"grey"}
              variant="subtitle2"
              alignItems="center"
              display="flex"
            >
              <ShortText fontSize="inherit" />
              &nbsp;&nbsp;
              {item?.rejection_reason}
            </Typography>
          )}
  
          <Box display="flex" justifyContent="space-between">
            <Typography
              color={"grey"}
              variant="subtitle2"
              alignItems="center"
              display="flex"
            >
              <FileDownloadComponent
                CustomComponent={({ ...btnProps }) => (
                  <ButtonBase {...btnProps}>
                    <Typography
                      variant="caption"
                      lineHeight="100%"
                      display="flex"
                      sx={{ textDecoration: "underline" }}
                    >
                      <Download fontSize="inherit" />
                      &nbsp;Invoice
                    </Typography>
                  </ButtonBase>
                )}
                src={item?.data?.attached_invoice}
              />
            </Typography>
            {item?.status != APPROVAL_REQUEST_STATUS.PENDING && (
              <Typography
                color={"grey"}
                variant="caption"
                alignItems="center"
                display="flex"
              >
                {item?.status === APPROVAL_REQUEST_STATUS.REJECTED
                  ? "Rejected By :"
                  : "Approved By :"}
                &nbsp;&nbsp;
                {item?.actioned_by?.name ?? "NA"}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  });
  
  
const PaymentDetailsUi = ({ setParams, data }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
  
    return (
      <>
        {data.approval_request.map((item) => (
          <PaymentDetailCardComponent
            {...item}
            key={item?._id}
            setParams={setParams}
          />
        ))}
      </>
    );
  };
  export default PaymentDetailsUi