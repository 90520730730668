import { useTheme } from "@emotion/react";
import {
    ElectricBolt,
    Paid,
    Warning,
} from "@mui/icons-material";
import {
    Box,
    Paper,
    Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {

    groupByArray,
    titleCase,
} from "../../utils/helpers/helper";
import ImageComponent from "../../components/ImageComponent";
import { DEFAULT_PRODUCT_CATEGORY } from "../../utils/constants/product.constant";
import NoDataComponent from "../../components/layouts/NoDataComponent";

const IconText = ({ Icon, text, ...props }) => {
    return (
        <Box display="flex" alignItems="flex-start" {...props}>
            <Typography color="grey" variant="caption" lineHeight="100%">
                <Icon fontSize="inherit" />
            </Typography>
            <Typography color="grey" ml={2} variant="caption" lineHeight="100%">
                {text}
            </Typography>
        </Box>
    );
};
const TitleValueText = ({ title, value, Icon, ...props }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            {...props}
        >
            <Typography
                color="grey"
                fontWeight={600}
                variant="caption"
                lineHeight="100%"
            >
                <Icon fontSize="inherit" /> {title}
            </Typography>
            <Typography color="grey" mt={1} variant="caption" lineHeight="100%">
                {value}
            </Typography>
        </Box>
    );
};


const ModuleBox = ({ title, products = [], ...props }) => {
    return (
        <Box display="flex" flexDirection="column" {...props}>
            <Typography
                color="grey"
                fontWeight={600}
                variant="subtitle2"
                lineHeight="100%"
            >
                {title}
            </Typography>
            {products?.length > 0 ? (
                products?.map((item) => (
                    <Paper
                        component={Box}
                        variant="outlined"
                        sx={{ borderTop: 5, borderColor: "primary.main" }}
                        key={item?._id}
                        mt={1}
                        p={2}
                    >
                        {item.logo && <ImageComponent isStatic src={item.logo} />}
                        <TitleValueText
                            mt={1}
                            Icon={() => <></>}
                            title={"Manufacturer Name/Product Title"}
                            value={item?.product}
                        />
                        <TitleValueText
                            mt={1}
                            Icon={() => <></>}
                            title={"Model No"}
                            value={item?.code}
                        />
                        <TitleValueText
                            mt={1}
                            Icon={() => <></>}
                            title={"Quantity"}
                            value={item?.quantity}
                        />

                        {/* <TitleValueText mt={1} Icon={()=>(<></>)} title={"Warrenty"} value={item?.name} /> */}
                    </Paper>
                ))
            ) : (
                <Paper variant="outlined">
                    <NoDataComponent
                        Icon={Warning}
                        iconVariant="body1"
                        variant="caption"
                        message={`No ${title}`}
                    />
                </Paper>
            )}
        </Box>
    );
};

const InstalledSystemUi = ({ projectData }) => {
    const theme = useTheme();
    const systemData = projectData.selected_system;
    const products = systemData?.products ?? [];
    const productObj = groupByArray(products, "productcategory");

    if (!systemData) return <></>;
    return (
        <>
            <Paper
                mb={2}
                variant="outlined"
                component={Box}
                sx={{ borderLeft: 5 }}
                p={2}
            >
                <Typography variant="body1">{systemData?.kw_stc} KW</Typography>

                <IconText
                    Icon={ElectricBolt}
                    text={Number(systemData?.annual_output_kwh).toFixed(2) + " kwh"}
                    mt={2}
                />
                <IconText
                    Icon={Paid}
                    text={Number(systemData?.amount_including_tax).toFixed(2) + " $"}
                    mt={2}
                />
            </Paper>


            <ModuleBox
                mt={2}
                title={"Panels"}
                products={
                    productObj?.[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)] ?? []
                }
            />
            <ModuleBox
                mt={2}
                title={"Inverters"}
                products={
                    productObj?.[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)] ?? []
                }
            />
            <ModuleBox
                mt={2}
                title={"Batteries"}
                products={
                    productObj?.[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)] ?? []
                }
            />
            <ModuleBox
                mt={2}
                title={"Others"}
                products={
                    productObj?.[titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)] ?? []
                }
            />

            {Object.keys(productObj)
                .filter(
                    (productCategory) =>
                        ![
                            titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS),
                            titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS),
                            titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES),
                            titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER),
                        ].includes(productCategory)
                )
                .map((productCategory) => {
                    return (
                        <ModuleBox
                            key={productCategory}
                            mt={2}
                            title={productCategory}
                            products={productObj?.[productCategory] ?? []}
                        />
                    );
                })}
        </>
    );
};

export default InstalledSystemUi