import { actions } from "../../utils/constants/constants";
import { accessToken,  refreshToken } from "../../utils/helpers/helper";


const initialState = {
    data: {},
   
    isLoggedIn: false,

}
const userReducer = (state = initialState, action) => {

    switch (action.type) {

        case actions.SET_USER: return { ...state, data: action.value, isLoggedIn: true };
        case actions.SIGN_IN: {
           
            return { ...state, data: action.value,  isLoggedIn: true };
        }
        case actions.SIGN_OUT: return { ...state, data: {},  isLoggedIn: false };
        case actions.SET_USER_LAST_READ_ANNOUNCEMENT: return { ...state, data: { ...state.data, last_read_announcement: action.value } };
        default: return { ...state }
    }

}
export default userReducer