import { Button, ButtonBase, ButtonGroup, Divider, Paper, Skeleton, Tab, TablePagination, Tabs, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";

import DataTable from "../../components/tables/DataTable";

import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { CUSTOMER_STATUS, SIGN_STATUS } from "../../utils/constants/constants";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";

import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import SubmitButton from "../../components/button/SubmitButton";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CustomTab, CustomTabs } from "../../components/layouts/common/Tabs";
import moment from "moment";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AggrementDetailViewUi from "./AggrementDetailsUi";
import { useEffect } from "react";
import { callApiAction } from "../../store/actions/commonAction";
import { getAggrementByIdApi } from "../../apis/aggrement.api";
import { useNavigate, useNavigation } from "react-router-dom";
import { Business, CalendarMonth, DocumentScanner, LockClock } from "@mui/icons-material";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const getBorderColor = (expiryDate) => {
  const today = moment();
  const expiry = moment(expiryDate);
  const daysDiff = expiry.diff(today, 'days');

  if (daysDiff < 0) {
    return "red";
  } else if (daysDiff < 15) {
    return "#ed6c02";
  } else {
    return "gray";
  }
};
const AggrementCard = ({ ...item }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onViewMore = (id) => {

    // dispatch(openModal(<AggrementDetailViewUi params={id} />, 'xs', undefined, "view-aggrement"))
    navigate(`/aggrement/${id}`);
  }
  const today = moment();
  return <Box
    // mt={2}
    // mb={2}
    my={3}
    component={Paper}
    p={2}
    variant="outlined"
    sx={{
      borderLeft: 5,
      borderLeftColor: getBorderColor(item.expiry_date),

    }}
  >

    <Typography
      variant="subtitle1"
      lineHeight="100%"
      fontWeight={600}
      ml={2}
      mb={2}
    >
      {item.title}
    </Typography>
    <Typography component={Box} variant="subtitle2" display="flex" alignItems="center" lineHeight="100%" ml={2} mb={2}>
      <Business fontSize="inherit" />&nbsp;{item.company_id?.name}
    </Typography>
    <Typography component={Box} variant="subtitle2" display="flex" alignItems="center" lineHeight="100%" ml={2} >
      <LockClock fontSize="inherit" />&nbsp;{moment(item.date).format("DD/MM/YYYY")} - {moment(item.expiry_date).format("DD/MM/YYYY")}
    </Typography>
    <Box sx={{ display: "flex", justifyContent: "flex-end" }} mx={2}>

      <ButtonBase onClick={() => onViewMore(item._id)}>
        <Typography sx={{ textDecoration: "underline" }}>
          View
        </Typography>
      </ButtonBase>
    </Box>
  </Box>
}
const ListUi = ({
  installer_id,
  title,
  modalKey,
  filters,
  setFilters,
  list,
  loading,
  setLoading,
  onCreateBtnClick,
  // onViewMore,
  columns,
}) => {


  const dispatch = useDispatch()
  const navigate = useNavigate();

  return (
    <>
      <Paper component={Box} p={3}  height="100%">
        <Box  height="100%">
          <Divider />
          <CustomTabs
            TabScrollButtonProps
            textColor="default"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            value={filters.status}
            variant="scrollable"
            scrollButtons="auto"
            // value={fields.sub_type}
            onChange={(e, val) => {
              setFilters({
                ...filters,
                status: val ? Number(val) : null,
                signed_date: null,
                // assigned_supplier: null,
              });
            }}
          >
            {Object.keys(SIGN_STATUS)?.map((status) => (
              <CustomTab
                label={status}
                value={Number(SIGN_STATUS[status])}
                key={status}
              ></CustomTab>
            ))}
          </CustomTabs>
          {filters.status == SIGN_STATUS.SIGNED &&
            <FiltersBox mt={3} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
              <PaddingBoxInDesktop mb={2}  >
                <ButtonGroup disableElevation>
                  <Button variant={filters.active == true ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, active: true })}>Active</Button>
                  <Button variant={filters.active == false ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, active: false })}>InActive</Button>
                </ButtonGroup>
              </PaddingBoxInDesktop>
            </FiltersBox>}

          {!loading && list?.result?.map((item) =>
            <AggrementCard key={item?._id} {...item} />
          )}
          {loading && [0, 1, 2, 3].map((item) => <Skeleton height="100px" variant="rounded" sx={{ my: 1 }} />
          )}
          {
            list?.total==0 && <CenteredBox display="flex" flex={1} >
              <NoDataComponent message="No aggrement found" Icon={DocumentScanner} variant="body1" iconVariant="h6" />
            </CenteredBox>
          }
          {list?.total > filters.pageSize &&
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={list?.total}
              rowsPerPage={filters?.pageSize}
              page={filters?.pageNo - 1}
              onPageChange={(e, newVal) => setFilters({ ...filters, pageNo: newVal + 1 })}
              onRowsPerPageChange={(e) => setFilters({ ...filters, pageNo: 1, pageSize: e.target.value })}
            />
          }
        </Box>
      </Paper>
      {/* </CustomDialog> */}
    </>
  );
};
export default ListUi;
