import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import CreateUi from "./CreateUi";
import moment from "moment";
import { addAggrementApi, getAggrementByIdApi, updateAggrementApi } from "../../apis/aggrement.api";

const CreateController = ({ callBack, id ,installer_id}) => {
  console.log("IDIIOOOOOO",installer_id)
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Aggrement ";
  const modalKey = "add-aggrement"
  const createApi = addAggrementApi;
  const updateApi = updateAggrementApi;
  const getByIdApi = getAggrementByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    installer_id,
    title: "",
    date: "",
    expiry_date: "",
    aggrement_file: "",
    // is_company_supplier:
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.title,
        field: "Title",
      },

      {
        required: true,
        value: fields.date,
        field: "Date",
      },
      {
        required: true,
        value: fields.expiry_date,
        field: "Expiry Date",
      },
     
      {
        required: true,
        value: fields.aggrement_file,
        field: "Aggrement File ",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
        {
            required: true,
            value: fields.title,
            field: "Title",
          },
    
          {
            required: true,
            value: fields.date,
            field: "Date",
          },
          {
            required: true,
            value: fields.expiry_date,
            field: "Expiry Date",
          },
         
          {
            required: true,
            value: fields.aggrement_file,
            field: "Aggrement File ",
          },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi({...fields,installer_id}),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in originalDocument) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi({installer_id,...updatedData}),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      title={title}
      modalKey={modalKey}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
