import axios from "axios";
import endpoints from "./endpoints";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";

export const getAssignedInstallationListApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.assignedInstallationDataFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getAssignedInstallationDataCountrApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.assignedInstallationDataCount,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getAssignedInstallationByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.assignedInstallationDataFetchById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
}

export const getStartInstallationDataApi = async params => {
  const callResponse = await axios({
    url: endpoints.startInstallationUpdate,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const startInstallationApi = async data => {
  const callResponse = await axios({
    url: endpoints.startInstallationUpdate,
    method: "POST",
    headers: getFileHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getCompleteInstallationDataApi = async params => {
  const callResponse = await axios({
    url: endpoints.completeInstallationUpdate,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const completeInstallationApi = async data => {
  const callResponse = await axios({
    url: endpoints.completeInstallationUpdate,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
