const APPROVAL_REQUEST_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};
const APPROVAL_REQUEST_TYPE = {
  SITE_INSPECTION: 1,
  INSTALLATION: 2,
  POST_INSTALLATION: 3,
};
const APPROVAL_REQUEST_TYPE_COLOR = {
  [APPROVAL_REQUEST_TYPE.SITE_INSPECTION]: "#344955",
  [APPROVAL_REQUEST_TYPE.INSTALLATION]: "#6200EE",
  [APPROVAL_REQUEST_TYPE.POST_INSTALLATION]: "#442C2E",
}
export { APPROVAL_REQUEST_STATUS, APPROVAL_REQUEST_TYPE ,APPROVAL_REQUEST_TYPE_COLOR};
