let domain = process.env.REACT_APP_BASE_DOMAIN;


const endpoints = {
  root: `${domain}`,
  calendarBase: `${domain}api/installer-app/calendar`,
  domain: `${domain}`,

  fileBase: `${domain}api/installer-file`,
  fileFile: `${domain}api/installer-file/file`,
  fileImage: `${domain}api/installer-file/image`,

  getautoComplete: `${domain}api/location/autocomplete`,
  getlatandLon: `${domain}api/location/lat-lon`,

  resetPassword: `${domain}api/installer-app/reset-password`,
  resetToken: `${domain}api/installer-app/reset-token`,
  userAndSystemFetchByToken: `${domain}api/installer-app/fetch-by-token`,
  signIn: `${domain}api/installer-app/login`,

  taskBase: `${domain}api/task/`,
  taskById: `${domain}api/task/by-id`,
  taskStatus: `${domain}api/task/status`,

  versions: `${domain}api/installer-version/latest`,

  inspectionDataFetch: `${domain}api/installer-app/inspection`,
  inspectionDataCount: `${domain}api/installer-app/inspection/count`,
  inspectionDataById: `${domain}api/installer-app/inspection/id`,

  inspectionStatusUpdate: `${domain}api/installer-app/inspection/status`,

  installerDataUpdate: `${domain}api/installer-app/inspection-data`,

  installerUpdate: `${domain}api/installer-app/update`,
  installerFetchByid: `${domain}api/installer-app/by-id`,
  shiftBase: `${domain}api/installer-app/shift`,
  

  aggrementBase: `${domain}api/installer-app/aggrement`,
  aggrementPDFs: `${domain}api/installer-app/aggrement-pdf`,
  aggrementById: `${domain}api/installer-app/aggrement/by-id`,
  aggrementSignature: `${domain}api/installer-app/aggrement-signature`,

  approvalRequestBase: `${domain}api/installer-app/approval-req`,

  postInstallationDataFetch: `${domain}api/installer-app/post-installation`,
  postInstallationDataCount: `${domain}api/installer-app/post-installation/count`,
  postInstallationDataFetchById:`${domain}api/installer-app/post-installation/id`,
  postInstallationUpdate:`${domain}api/installer-app/post-installation/installation-data`,



  assignedInstallationDataFetch: `${domain}api/installer-app/installation`,
  assignedInstallationDataCount: `${domain}api/installer-app/installation/count`,
  assignedInstallationDataFetchById:`${domain}api/installer-app/installation/id`,
  startInstallationUpdate:`${domain}api/installer-app/installation/start-installation`,
  completeInstallationUpdate:`${domain}api/installer-app/installation/complete-installation`,
  // postInstallationDataById: `${domain}api/installer-app/post-installation/id`,

  invoiceFetch:`${domain}api/installer-app/invoice`
};

export default endpoints;
