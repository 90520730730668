import { Component, memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";

import Calendar from "../../components/layouts/Calendar";
import CalendarBox from "../installer-shift/CalendarInnerBox";
import moment from "moment";

import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";
import { Box, MenuItem, Paper, Typography } from "@mui/material";
import SubmitButton from "../../components/button/SubmitButton";
import { CenteredBox } from "../../components/layouts/common/boxes";
import {
  CALENDAR_ITEMS_TYPES,
  SNACK_BAR_VARIETNS,
  USER_ROLES,
} from "../../utils/constants/constants";
import { fetchInstallerCalendaData, setCalendarFiltersValue } from "../../store/actions/installationcalendarAction";
import { INSTALLATION_CALENDAR_ITEM_TYPE, jobItemColor, jobItemTitle } from "../../utils/constants/installercalendar.constant";
// const CalendarComponent = 
const ShiftCalendarController = ({  }) => {
  const { installerCalendar, user } = useSelector((state) => state);
  const { filters, data, loading: listLoading } = installerCalendar




  const [list, setList] = useState([]);


  const dispatch = useDispatch();



  const createCalendarList = () => {
    
    const finalList = [];

    if (Array.isArray(data)) {

      data.forEach((item) => {



        if (item.calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.AVAILABILITY) {
          finalList.push({
            ...item,
            installer_id: item.installer_id,
            start: new Date(item.date),
            end: new Date(item.date),
            relatedId: item._id,
            calendarItemType: item.calendarItemType,

          });
        }
        if (item.calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.INSPECTION || item.calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.ASSIGNED_INSTALLATION || item.calendarItemType == INSTALLATION_CALENDAR_ITEM_TYPE.POST_INSTALLATION) {
         
          finalList.push({
            ...item,
            installer_id: item.installer_id,
            start: new Date(item.assigned_date),
            end: new Date(item.assigned_date),
            relatedId: item._id,
            calendarItemType: item.calendarItemType,

          });
        }


      });
    }    

    setList(finalList);

  };

  const firstTimeLoad = useRef(false)
  useEffect(() => {
    if (!firstTimeLoad.current) {
      if (data?.length == 0){
        dispatch(fetchInstallerCalendaData())
       
      }
      firstTimeLoad.current = true
    }
    else {
      
      dispatch(fetchInstallerCalendaData())
     
    }
  }, [filters]);

  useEffect(() => {
    createCalendarList()
  }, [data])

  const calendarTimeObj = useMemo(() => moment(filters.date), [filters.date])

  return (
    <>
      <Paper component={Box} p={3} mb={3}>
        <Box mb={2} sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", flex: 1 }}>
            <CustomMonthPicker
              date={calendarTimeObj}
              setDate={(val) => dispatch(setCalendarFiltersValue('date', val?.valueOf()))}
            />
          </Box>
        </Box>
        <CenteredBox>
          
          <Calendar
            loading={listLoading}
            data={list}
            Component={memo((props) =><CalendarBox calendarDate={calendarTimeObj.toDate()} {...props} />)
              // Component ? <Component calendarDate={calendarTimeObj.toDate()} {...props} /> : 
            }
            calendarTimeObj={calendarTimeObj.toDate()}
          />
        </CenteredBox>
        <Box>
          {
            Object.keys(jobItemColor).map((jobItem)=><Box alignItems="center" display="flex" >
              
              <Box height="10px" width="10px" borderRadius="100%" bgcolor={jobItemColor[jobItem]} ></Box>
                 <Typography variant="caption" color="grey" ml={1} >{jobItemTitle[jobItem]}</Typography>
            </Box>)
          }
        </Box>
      </Paper>
    </>
  );
  return <></>;
};
export default memo(ShiftCalendarController);
