import { Menu, Message } from "@mui/icons-material";
import { AppBar, Badge, Box, Container, IconButton, Paper, Toolbar, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import Profile from "./Profile";

import { memo } from "react";
import Logo from "./Logo";

const headerStyle = (theme) => ({
  width: "100%",
  background: theme.palette.primary.main,
  position: "sticky",
  top: "0px",
  display: "flex",
  alignItems: "center",
  pt: 2,
  pb: 2,
  pr: 2,
  borderBottom: "1px solid " + theme.palette.primary.main,
  zIndex: 111,
  borderRadius: "0px",
});

const Header = ({ open, setOpen }) => {
  const { user, chat } = useSelector((state) => state);
  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo />
          <Box display="flex" flex={1} >

          </Box>
          <Profile />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default memo(Header);
