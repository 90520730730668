import { actions } from "../../utils/constants/constants";

export const hardRefreshAssignedInstallationData = () => {
  return { type: actions.HARD_REFRESH_ASSIGNED_INSTALLATION_DATA}
}
export const updateAssignedInstallationFilters = (filters) => {
  return { type: actions.UPDATE_ASSIGNED_INSTALLATION_DATA_FILTERS,value:filters }
}
export const updateAssignedInstallationCountsData = (data) => {
  return { type: actions.UPDATE_ASSIGNED_INSTALLATION_DATA_COUNTS,value:data }
}
export const updateAssignedInstallationListData = (data) => {
  return { type: actions.UPDATE_ASSIGNED_INSTALLATION_DATA_LIST,value:data }
}
