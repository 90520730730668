import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { callApiAction } from "../../store/actions/commonAction";
import VersionDetailsUi from "./VersionDetailsUi";
import { APP_TYPES, SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import { lastReadVersion } from "../../utils/helpers/helper";
import moment from "moment";
import { fetchLatestVersion } from "../../apis/version.api";

const VersionDetailsController = ({ date }) => {
  const dispatch = useDispatch();
  const modalkey = "version-details";
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const fetchList = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchLatestVersion({ app_type: APP_TYPES.APP }),
        async (response) => {
          
          setLoading(false);
          setList(response);
          if (response) {
            lastReadVersion.set(
              response?.main_version + "-" + response?.sub_version
            );
          }
        },
        (err) => {
          console.log(err)
          setLoading(false);

          setList({});
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };
  useEffect(() => {
    fetchList();
  }, []);
  return (
    <>
      <VersionDetailsUi
        modalkey={modalkey}
        filters={filters}
        setFilters={setFilters}
        list={list}
        loading={loading}
      />
    </>
  );
};
export default VersionDetailsController;
