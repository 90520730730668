
import { CalendarMonthOutlined, Circle, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material"
import { IconButton, Paper, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import CustomDatePicker from "./CustomDatePicker"
import { useEffect, useState } from "react"





const CustomMonthPicker = ({ date, setDate }) => {

    const [dateVal, setDateVal] = useState(date)

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (dateVal != date) {
                setDate(dateVal)
            }
        }, 300)
        return () => clearTimeout(timeout)

    }, [dateVal])
    return <Box component={Paper} elevation={0} sx={{ display: "flex", padding: 1, flex: 1, border: 1, borderColor: 'gray' }} >
        <Box sx={{ display: "flex", flex: 1, mr: 2, borderRight: "1px solid gray", justifyContent: "space-around", alignItems: 'center' }}>
            <IconButton onClick={() => (setDateVal(moment().set("months", dateVal.get('month')).set("years", dateVal.get('year') - 1)))}>
                <KeyboardDoubleArrowLeft color="primary" />
            </IconButton>
            <IconButton
                onClick={() => (setDateVal(moment().set("years", dateVal.get('year')).set("months", dateVal.get('month') - 1)))}
                disabled={dateVal.get('month') == 0}

            >
                <KeyboardArrowLeft color={dateVal.get('month') == 0 ? "grey" : "primary"} />
            </IconButton>

            <Typography variant="h7" >
                {dateVal && dateVal.format("MMM, YYYY")}
            </Typography>


            <IconButton
                onClick={() => (setDateVal(moment().set("years", dateVal.get('year')).set("months", dateVal.get('month') + 1)))}
                disabled={dateVal.get('month') == 11}
            >
                <KeyboardArrowRight
                    color={dateVal.get('month') == 11 ? "grey" : "primary"}
                />
            </IconButton>

            <IconButton onClick={() => (setDateVal(moment().set("months", dateVal.get('month')).set("years", dateVal.get('year') + 1)))}
                disabled={moment().get('year') <= dateVal.get('year') || (moment().get('year') <= dateVal.get('year') + 1 && moment().get('month') < dateVal.get('month'))}>
                <KeyboardDoubleArrowRight color={moment().get('year') <= dateVal.get('year') || (moment().get('year') <= dateVal.get('year') + 1 && moment().get('month') < dateVal.get('month')) ? "grey" : "primary"} />
            </IconButton>
        </Box>

        <CustomDatePicker date={dateVal} setDate={setDateVal} />


    </Box>
}
export default CustomMonthPicker