//this is constants are made accoriding to reponse 
export const INSTALLATION_CALENDAR_ITEM_TYPE = {
    AVAILABILITY: 'availability',
    INSPECTION: 'inspection',
    POST_INSTALLATION: 'post_installation',
    ASSIGNED_INSTALLATION: 'assigned_installation',

}

export const jobItemColor = {
    [INSTALLATION_CALENDAR_ITEM_TYPE.INSPECTION]: "#87CEEB",
    [INSTALLATION_CALENDAR_ITEM_TYPE.ASSIGNED_INSTALLATION]: "#32CD32",
    [INSTALLATION_CALENDAR_ITEM_TYPE.POST_INSTALLATION]: "#9370DB",
}
export const jobItemTitle = {
    [INSTALLATION_CALENDAR_ITEM_TYPE.INSPECTION]: "Site Inspection",
    [INSTALLATION_CALENDAR_ITEM_TYPE.ASSIGNED_INSTALLATION]: "Installation",
    [INSTALLATION_CALENDAR_ITEM_TYPE.POST_INSTALLATION]: "Post Installation",
}