import { Box, Paper, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { center } from "../../../assets/css/theme/common";

const CenteredBox = styled(Box)(() => ({
    ...center
}))

const TitleBox = ({ title, children,titleProps={},childrenProps={}, ...props }) => {
    const theme = useTheme()
    return <Paper component={Box} width="100%" variant="outlined" sx={{ height: "100%", display: "flex", borderColor: "primary.main", flexDirection: "column", overflow: "hidden" }} {...props} >
        <Box sx={{ background: theme.palette.primary.light, borderBottom: 1 }} p={2} {...titleProps} >
            {title}
        </Box>
        <Box sx={{ display: "flex", flex: 1, overflowY: "auto",flexDirection:"column" }} p={2} {...childrenProps} >
            {children}
        </Box>
    </Paper>
}
export { CenteredBox, TitleBox }