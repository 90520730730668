import { memo } from "react";
import { Chip } from "@mui/material";
import {
  findObjectKeyByValue,
} from "../../../utils/helpers/helper";
import { ASSIGNED_INSTALALTION_STATUS, ASSIGNED_INSTALALTION_STATUS_COLOR } from "../../../utils/constants/constants";

const PostInstallationStatusComponent = memo(({ params, setParams, onlyview }) => {
  return (
    <Chip
      sx={{ borderRadius: 1 }}
      size="small"
      color={ASSIGNED_INSTALALTION_STATUS_COLOR[params.status] ?? "default"}
      label={findObjectKeyByValue(params?.status, ASSIGNED_INSTALALTION_STATUS)}
    />
  );
});
export default PostInstallationStatusComponent;
