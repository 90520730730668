
import CustomInput from '../../components/inputs/CustomInput';
import { Box, CircularProgress, styled } from '@mui/material';
import { CenteredBox } from '../../components/layouts/OneViewBox';

import CustomDialog from '../../components/layouts/common/CustomDialog';



const absolutePosition = { position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%", zIndex: -1 }
const SignInBoxUi = styled(Box)(({ theme }) => ({
    display: "flex",
    maxWidth: "90%",
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(255,255,255,0.5)',
    backdropFilter: "blur(10px)",
    width: "600px",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid white"

}));


const ResetTokenUi = ({ state, setState, onSubmit, loading }) => {

    return (

        <>
            <CustomDialog

                loading={loading}
                err={state.err}
                onSubmit={onSubmit}
                title={`Reset Password`}
                closeText="Close"
                confirmText={`Reset`}
            >

                {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                    <>
                        <CustomInput
                            autoFocus={true}
                            disabled={loading}
                            value={state.current_pass}
                            onChange={(e) => setState({ ...state, err: '', current_pass: e.target.value })}
                            type="text"
                            label={"Old Password*"}

                        />

                        <CustomInput
                            autoFocus={true}
                            disabled={loading}
                            value={state.new_pass}
                            onChange={(e) => setState({ ...state, err: '', new_pass: e.target.value })}
                            type="text"
                            label={"New Password*"}

                        />

                    </>}

            </CustomDialog>


        </>
    )
}
export default ResetTokenUi