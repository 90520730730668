import React, { memo, useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Delete, HideImageOutlined, Image, Upload } from "@mui/icons-material";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import ImageComponent from "../../components/ImageComponent";
import FileInputImage from "../../components/inputs/FileInputImage";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import CustomInput from "../../components/inputs/CustomInput";
import {
  ROOF_TYPES,
  SNACK_BAR_VARIETNS,
} from "../../utils/constants/constants";
import { YES_NO } from "../../utils/constants/task.constants";
import { toTitleCase } from "../../utils/helpers/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SelectDropDown from "../../components/inputs/SelectDropDown";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageUploadComponent = memo(
  ({ loading, onChange, percentage, onDeleteFile, files, name, camp_id }) => {
    // const selectedComponet = useSelector((state) => state.selectedComponent.ivalue);

    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);

    const deleteRecordFromDb = async (callBack = () => {}) => {
      dispatch(
        callApiAction(
          // async () => await deleteCampImagesField({ camp_id, image_name: name }),
          async (response) => {
            callBack(response);
          },
          (err) => {
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
            callBack();
          }
        )
      );
    };

    const onDelete = () => {
      setDeleteLoading(true);
      onDeleteFile(files[0], () => {
        deleteRecordFromDb(() => {
          setDeleteLoading(false);
        });
      });
    };

    return (
      <Box component={Paper} sx={{ width: "100%", background: "#e5e5e5" }}>
        <Box px={2} pt={1} pb={1}>
          <Typography variant="subtitle2" fontWeight={600} lineHeight="100%">
            {toTitleCase(name?.replace(/_/g, " "))}
          </Typography>
        </Box>
        <Box
          component={Paper}
          elevation={0}
          mt={1}
          mb={1}
          sx={{
            display: "flex",
            width: "100%",
            position: "relative",
            paddingTop: "75%",
            border: "1px solid gray",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              width: "100%",
              height: "100%",
            }}
          >
            {files[0] && !loading && !deleteLoading ? (
              <ImageComponent
                imgStyle={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={files[0]}
              />
            ) : (
              <CenteredBox>
                {loading ? (
                  <LinearProgress
                    sx={{ zIndex: 1, width: "100%" }}
                    variant="determinate"
                    value={percentage}
                  />
                ) : deleteLoading ? (
                  <CircularProgress />
                ) : (
                  <HideImageOutlined />
                )}
              </CenteredBox>
            )}
          </Box>
        </Box>
        <Box>
          <Box>
            {!files[0] && (
              <Button
                sx={{ width: "100%" }}
                component="label"
                mt={2}
                startIcon={<Upload />}
                disableElevation
                disabled={loading || deleteLoading}
                variant="contained"
              >
                {/* <Upload />Upload */}
                <Typography sx={{ fontSize: "12px" }}>Upload</Typography>
                <VisuallyHiddenInput
                  // accept="image/*"
                  accept="image/*"
                  capture="camera"
                  onChange={onChange}
                  type="file"
                />
              </Button>
            )}

            {files[0] && (
              <Button
                onClick={onDelete}
                component="label"
                color="error"
                mt={2}
                startIcon={<Delete />}
                disableElevation
                disabled={loading || deleteLoading}
                variant="contained"
                fullWidth
              >
                {/* <Upload />Upload */}
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

const InspectionFormUI = ({ fields, setFields, loading, onSubmit }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const navigate = useNavigate();
  return (
    <>
      <Box
        mb={2}
        sx={{
          padding: "10px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          border: "1px solid #ccc",
          // position:"fixed"
        }}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/jobs")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>jobs</Typography>
          </Link>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/Site-Installer")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>
              Site-Installer
            </Typography>
          </Link>
          <Typography sx={{ color: "text.primary" }}>Form</Typography>
        </Breadcrumbs>
      </Box>

      <Box p={3} sx={{ backgroundColor: "white" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              color={"error"}
              sx={{    fontSize: "15px" }}
            >
              {fields.err}
            </Typography>
            <Box>
              {
                <CustomInput
                  // autoFocus={true}
                  disabled={loading}
                  value={fields.inspection_data.meet}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      inspection_data: {
                        ...fields.inspection_data,
                        meet: e.target.value,
                      },
                    })
                  }
                  type="text"
                  label={"Whom Did You Meet ?*"}
                />
              }
            </Box>
          </Grid>
        </Grid>

        <Typography
          sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
        >
          Property
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FileInputImage
              disabled={loading}
              onlyImage
              defaults={fields.front ? [fields.front] : undefined}
              componentProps={{
                name: "Front :",
                url: fields.front,
              }}
              onChange={(newUrl) => {
                console.log("new URL", newUrl);
                setFields({
                  ...fields,
                  inspection_data: {
                    ...fields.inspection_data,
                    property: {
                      ...fields.inspection_data.property,
                      front: newUrl,
                    },
                  },
                });
              }}
              onDelete={() => {
                setFields({ ...fields, front: "" });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>

          <Grid item xs={6}>
            <FileInputImage
              disabled={loading}
              onlyImage
              defaults={fields.back ? [fields.back] : undefined}
              componentProps={{
                name: "Back :",
                url: fields.back,
              }}
              onChange={(newUrl) => {
                setFields({
                  ...fields,
                  inspection_data: {
                    ...fields.inspection_data,
                    property: {
                      ...fields.inspection_data.property,
                      back: newUrl,
                    },
                  },
                });
              }}
              onDelete={() => {
                setFields({ ...fields, back: "" });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>
          <Grid item xs={6}>
            <FileInputImage
              disabled={loading}
              onlyImage
              defaults={fields.side ? [fields.side] : undefined}
              componentProps={{
                name: "Side :",
                url: fields.side,
              }}
              onChange={(newUrl) => {
                setFields({
                  ...fields,
                  inspection_data: {
                    ...fields.inspection_data,
                    property: {
                      ...fields.inspection_data.property,
                      side: newUrl,
                    },
                  },
                });
              }}
              onDelete={() => {
                setFields({ ...fields, side: "" });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>
        </Grid>

        <Typography
          mt={3}
          sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
        >
          Switch Board And MeterBox
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FileInputImage
              disabled={loading}
              onlyImage
              defaults={fields.switch_board ? [fields.switch_board] : undefined}
              componentProps={{
                name: "Switch Board :",
                url: fields.switch_board,
              }}
              onChange={(newUrl) => {
                setFields({
                  ...fields,
                  inspection_data: {
                    ...fields.inspection_data,
                    switch: {
                      ...fields.inspection_data.switch,
                      switch_board: newUrl,
                    },
                  },
                });
              }}
              onDelete={() => {
                setFields({ ...fields, switch_board: "" });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>

          <Grid item xs={6}>
            <FileInputImage
              disabled={loading}
              onlyImage
              defaults={fields.metor_box ? [fields.metor_box] : undefined}
              componentProps={{
                name: "Meter Box :",
                url: fields.metor_box,
              }}
              onChange={(newUrl) => {
                setFields({
                  ...fields,
                  inspection_data: {
                    ...fields.inspection_data,
                    switch: {
                      ...fields.inspection_data.switch,
                      metor_box: newUrl,
                    },
                  },
                });
              }}
              onDelete={() => {
                setFields({ ...fields, metor_box: "" });
              }}
              FileComponent={ImageUploadComponent}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <CustomInput
            // autoFocus={true}
            disabled={loading}
            value={fields.inspection_data.switch?.remarks || ""}
            onChange={(e) =>
              setFields({
                ...fields,
                err: "",
                inspection_data: {
                  ...fields.inspection_data,
                  switch: {
                    ...fields.inspection_data.switch,
                    remarks: e.target.value,
                  },
                },
              })
            }
            type="text"
            multiline
            rows={3}
            label={"Remarks For Switch Board*"}
          />
        </Box>

        <Grid container mt={2}>
          <Typography
            sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
          >
            Roof (minimum 2)
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FileInputImage
                disabled={loading}
                onlyImage
                defaults={fields.roof ? [fields.roof] : undefined}
                componentProps={{
                  name: "Image 1.",
                  url: fields.roof,
                }}
                onChange={(newUrl) => {
                  setFields({
                    ...fields,
                    inspection_data: {
                      ...fields.inspection_data,
                      roof: [...fields.inspection_data.roof, newUrl],
                    },
                  });
                }}
                onDelete={() => {
                  setFields({ ...fields, roof: "" });
                }}
                FileComponent={ImageUploadComponent}
              />
            </Grid>

            <Grid item xs={6}>
              <FileInputImage
                disabled={loading}
                onlyImage
                defaults={fields.roof ? [fields.roof] : undefined}
                componentProps={{
                  name: "Image 2.",
                  url: fields.roof,
                }}
                onChange={(newUrl) => {
                  setFields({
                    ...fields,
                    inspection_data: {
                      ...fields.inspection_data,
                      roof: [...fields.inspection_data.roof, newUrl],
                    },
                  });
                }}
                onDelete={() => {
                  setFields({ ...fields, roof: "" });
                }}
                FileComponent={ImageUploadComponent}
              />
            </Grid>
            <Grid item xs={6}>
              <FileInputImage
                disabled={loading}
                onlyImage
                defaults={fields.roof ? [fields.roof] : undefined}
                componentProps={{
                  name: " Image 3.",
                  url: fields.roof,
                }}
                onChange={(newUrl) => {
                  setFields({
                    ...fields,
                    inspection_data: {
                      ...fields.inspection_data,
                      roof: [...fields.inspection_data.roof, newUrl],
                    },
                  });
                }}
                onDelete={() => {
                  setFields({ ...fields, roof: "" });
                }}
                FileComponent={ImageUploadComponent}
              />
            </Grid>

            <Grid item xs={6}>
              <FileInputImage
                disabled={loading}
                onlyImage
                defaults={fields.roof ? [fields.roof] : undefined}
                componentProps={{
                  name: "4",
                  url: fields.roof,
                }}
                onChange={(newUrl) => {
                  setFields({
                    ...fields,
                    inspection_data: {
                      ...fields.inspection_data,
                      roof: [...fields.inspection_data.roof, newUrl],
                    },
                  });
                }}
                onDelete={() => {
                  setFields({ ...fields, roof: "" });
                }}
                FileComponent={ImageUploadComponent}
              />
            </Grid>
          </Grid>

          <Grid item mt={3} xs={12}>
            <SelectDropDown
              disabled={loading}
              title="Roof Type"
              value={fields.inspection_data.roof_type}
              onChange={(e) => {
                setFields({
                  ...fields,
                  err: "",
                  inspection_data: {
                    ...fields.inspection_data,
                    roof_type: parseInt(e.target.value),
                  },
                });
              }}
              data={Object.keys(ROOF_TYPES).map((item) => ({
                value: item,
                label: ROOF_TYPES[item],
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            {
              <CustomInput
                rows={2}
                multiline
                // autoFocus={true}
                disabled={loading}
                value={fields.inspection_data.roof_condition}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    inspection_data: {
                      ...fields.inspection_data,
                      roof_condition: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Roof Condition*"}
              />
            }
          </Grid>

          <Grid item mt={2} xs={12}>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormLabel
                sx={{ fontSize: "14px" }}
                id="demo-radio-buttons-group-label"
              >
                Is Roof Accessible ? : &nbsp;{" "}
              </FormLabel>
              <RadioGroup
                row
                // sx={{ marginLeft: "10px" }}
                value={fields.inspection_data.is_roof_accessible}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    inspection_data: {
                      ...fields.inspection_data,
                      is_roof_accessible: e.target.value,
                    },
                  })
                }
                name="radio-buttons-group"
              >
                {Object.keys(YES_NO).map((item) => (
                  <FormControlLabel
                    // disabled={loading}
                    key={item}
                    value={YES_NO[item]}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item mt={2} xs={12}>
            {
              <CustomInput
                rows={2}
                multiline
                // autoFocus={true}
                disabled={loading}
                value={fields.inspection_data.panel_orientation_feasibility}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    inspection_data: {
                      ...fields.inspection_data,
                      panel_orientation_feasibility: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Panel Orientation & Feasibility*"}
              />
            }
          </Grid>

          <Grid item xs={12}>
            {
              <CustomInput
                rows={2}
                multiline
                // autoFocus={true}
                disabled={loading}
                value={fields.inspection_data.inverter_location_feasibility}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    inspection_data: {
                      ...fields.inspection_data,
                      inverter_location_feasibility: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Invertor Location & Feasibility*"}
              />
            }
          </Grid>

          <Grid item xs={12}>
            {
              <CustomInput
                rows={2}
                multiline
                // autoFocus={true}
                disabled={loading}
                value={fields.inspection_data.inspection_summary}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    inspection_data: {
                      ...fields.inspection_data,
                      inspection_summary: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Inspection Summary*"}
              />
            }
          </Grid>

          <Grid item mt={2} xs={12}>
            <Box
              width={"100%"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{ width: "100%" }}
              >
                <Typography>Submit</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InspectionFormUI;
