import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import { unEscapeStr } from "../../utils/helpers/helper";
import CustomDialog from "../../components/layouts/common/CustomDialog";

const OverViewBox = ({ title, value }) => {
  return (
    <Paper component={Box} p={2} sx={{ border: "1px solid gray" }}>
      <Typography variant="h3" align="center">
        {value}
      </Typography>
      <Typography variant="body2" align="center">
        {title}
      </Typography>
    </Paper>
  );
};

const VersionDetailsUi = ({
  modalkey,
  loading,
  list,
  date,
  filters,
  setFilters,
}) => {
  

  return (
    <CustomDialog
      loading
      title={"New Version"}
      id={modalkey}
      // onClose={modalkey}
    >
      {loading && (
        <CenteredBox mt={4} mb={4}>
          <CircularProgress />
        </CenteredBox>
      )}
      {!loading && list && list["_id"] && (
        <Box>
          <Box mb={4}>
            <Typography fontWeight="Bold" variant="body1">
              Version Name:
            </Typography>
            <Typography variant="body2" mt={1}>
              {list.name}
            </Typography>

            <Typography fontWeight="Bold" variant="body1">
              Version Code:
            </Typography>
            <Typography variant="body2" mt={1}>
              {list.main_version}.{list.sub_version}
            </Typography>

            <Typography fontWeight="Bold" variant="body1" mt={3}>
              What's New?:
            </Typography>
            <Box
              sx={{ background: "#f2f2f2" }}
              mt={2}
              p={2}
              dangerouslySetInnerHTML={{
                __html: unEscapeStr(list.description),
              }}
            ></Box>
          </Box>
        </Box>
      )}
      

      {!loading && (!list || Object.keys(list).length == 0) && (
        <CenteredBox mt={4} mb={4}>
          <Typography variant="h3"></Typography>
        </CenteredBox>
      )}
    </CustomDialog>
  );
};
export default VersionDetailsUi;
