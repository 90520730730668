import { useTheme } from "@emotion/react";
import {
    AccountCircle,
    CalendarMonth,
    ElectricBolt,
    Note,
    Paid,
    Place,
    ShortText,
    Warning,
} from "@mui/icons-material";
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Paper,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";

import { CenteredBox } from "../../../components/layouts/OneViewBox";
import InstalledSystemUi from "../InstalledSystemUi";

import ProjectDocumentRow from "../ProjectDocumentRow";
import PaymentDetailsUi from "./PaymentDetailUi";




const SiteDetailsComponent = ({ data }) => {
    const theme = useTheme();
    const location = useLocation();


    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    border: "1px solid " + theme.palette.primary.main,
                    borderRadius: "10px",
                }}
                component={Box}
                mb={2}
            >
                <CardContent>
                    <Box mb={2} display="flex" justifyContent="space-between">
                        <Typography variant="body1">Customer information</Typography>
                    </Box>
                    <Typography
                        color={"grey"}
                        variant="subtitle2"
                        alignItems="center"
                        display="flex"
                    >
                        <CalendarMonth fontSize="inherit" />
                        &nbsp;&nbsp;
                        {moment(data?.assigned_date).format("DD/MM/YYYY HH:mm")}
                    </Typography>
                    <Typography
                        color={"grey"}
                        variant="subtitle2"
                        alignItems="center"
                        display="flex"
                    >
                        <AccountCircle fontSize="inherit" />
                        &nbsp;&nbsp;
                        {data?.project_id?.contacts[0]?.full_name}
                    </Typography>
                    <Typography
                        color={"grey"}
                        variant="subtitle2"
                        alignItems="center"
                        display="flex"
                    >
                        <Place fontSize="inherit" />
                        &nbsp;&nbsp;
                        {data?.project_id?.address}
                    </Typography>
                    <Typography
                        color={"grey"}
                        variant="subtitle2"
                        alignItems="center"
                        display="flex"
                    >
                        <EmailIcon fontSize="inherit" />
                        &nbsp;&nbsp;
                        {data?.project_id?.contacts[0]?.email}
                    </Typography>
                    <Typography
                        color={"grey"}
                        variant="subtitle2"
                        alignItems="center"
                        display="flex"
                    >
                        <LocalPhoneIcon fontSize="inherit" />
                        &nbsp;&nbsp;
                        {data?.project_id?.contacts[0]?.phone}
                    </Typography>
                </CardContent>
            </Card>

            <Card
                variant="outlined"
                sx={{
                    border: "1px solid " + theme.palette.primary.main,
                    borderRadius: "10px",
                }}
                component={Box}
                mb={2}
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="body1">Installation Note</Typography>
                    </Box>
                    <Typography
                        color={"grey"}
                        variant="subtitle2"
                        alignItems="center"
                        display="flex"
                    >
                        <ShortText size="16px" />
                        &nbsp;&nbsp;
                        {data?.remarks}
                    </Typography>

                    {data?.notes?.length > 0 &&
                        <>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2">Other Notes</Typography>
                            </Box>
                            {
                                data?.notes?.map((item) => <Typography
                                    color={"grey"}
                                    variant="subtitle2"
                                    alignItems="center"
                                    display="flex"
                                >
                                    <ShortText size="16px" />
                                    &nbsp;&nbsp;
                                    {item?.note}
                                </Typography>)
                            }
                        </>
                    }
                </CardContent>
            </Card>

            <Card
                variant="outlined"
                sx={{
                    border: "1px solid " + theme.palette.primary.main,
                    borderRadius: "10px",
                }}
                component={Box}
                mb={1}
            >
                <CardContent>
                    <Box
                        sx={{ marginBottom: 2 }}
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="body1">Documents</Typography>
                    </Box>

                    {data?.document?.length > 0 ? (
                        data?.document?.map((item) => (
                            <ProjectDocumentRow
                                key={item?._id}
                                files={item?.files}
                                updatedAt={item?.updatedAt}
                                id={item?._id}
                                name={item?.name}
                                type={item?.type}
                                dispatch={() => { }}
                            />
                        ))
                    ) : (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        // height="100px"
                        >
                            <Typography variant="body2" color="textSecondary">
                                No document found
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </>
    );
};




const JobDetailsUi = ({ apiFun, title, parentPath, JobDetailComponent = () => <></> }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const props = location.state?.data;
    const [value, setValue] = useState(1);
    const dispatch = useDispatch();
    const [data, setData] = useState();

    const fetchById = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await apiFun({ id: props._id }),
                async (response) => {
                    setData({ ...response });
                    setLoading(false);
                },
                (err) => {
                    // setFields({ ...fields, err });
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        fetchById();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    if (loading) {
      return  <CenteredBox>
            <CircularProgress />
        </CenteredBox>
    }
    return (
        <>
            <Box>
                <Box
                    mb={2}
                    sx={{
                        padding: "10px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "4px",
                        border: "1px solid #ccc",

                        // position:"fixed"
                    }}
                >
                    <Breadcrumbs>
                        <Link
                            underline="hover"
                            color="black"
                            onClick={() => navigate("/jobs")}
                            sx={{ cursor: "pointer", color: "black" }}
                        >
                            <Typography sx={{ color: "text.primary" }}>jobs</Typography>
                        </Link>
                        <Link
                            underline="hover"
                            color="black"
                            onClick={() => navigate("/"+parentPath)}
                            sx={{ cursor: "pointer", color: "black" }}
                        >
                            <Typography sx={{ color: "text.primary" }}>
                                {title}
                            </Typography>
                        </Link>
                        <Typography sx={{ color: "text.primary" }}>View</Typography>
                    </Breadcrumbs>
                </Box>
                <Card component={Box} mb={2}>
                    {/* <Box> */}
                    <Tabs value={value} onChange={handleChange}>
                        <Tab
                            value={1}
                            label={
                                <Typography variant="caption">Site Details</Typography>
                            }
                            sx={{ minWidth: 0, flexShrink: 1 }}
                        />
                        <Tab
                            value={2}
                            label={<Typography variant="caption">System Details</Typography>}
                            sx={{ minWidth: 0, flexShrink: 1 }}
                        />
                        <Tab
                            value={3}
                            label={<Typography variant="caption">Job Details</Typography>}
                            sx={{ minWidth: 0, flexShrink: 1 }}
                        />
                        <Tab
                            value={4}
                            label={<Typography variant="caption">Payment Request</Typography>}
                            sx={{ minWidth: 0, flexShrink: 1 }}
                        />
                    </Tabs>
                    {/* </Box> */}
                </Card>
                {value == 1 && <SiteDetailsComponent data={data} />}
                {value == 2 && <InstalledSystemUi projectData={data?.project_id} />}
                {value == 3 && <JobDetailComponent data={data} />}
                {value == 4 && <PaymentDetailsUi data={data} />}
            </Box>
        </>
    );
};

export default JobDetailsUi;
