import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  AccountCircle,
  Email,
  Label,
  People,
  PictureAsPdf,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../button/SubmitButton";
import { signOutAction } from "../../../store/actions/userReducerAction";
import { openModal } from "../../../store/actions/modalAction";
import ResetTokenController from "../../../pages/reset-token/ResetTokenController";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "../../../utils/constants/constants";
import CreateUi from "../../../pages/installer/CreateUi";
import DetailViewController from "../../../pages/installer/DetailViewController";

export default function Profile() {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onResetButtonClick = () => {
    dispatch(openModal(<ResetTokenController />, "xs"));
  };

  // const onViewInstallerButtonClick = () => {
  //   console.log("this is id herrrrtrr",user.data._id);
  //   dispatch(openModal(<DetailViewController id={user.data._id} />, "sm"));
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Badge
        color="success"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent=" "
        variant="dot"
      >
        <ButtonBase p={0} aria-describedby={id} onClick={handleClick}>
          <Avatar
            sx={(theme) => ({
              bgcolor: theme.palette.light.main,
              height: "30px",
              width: "30px",
              color: theme.palette.primary.main,
            })}
          >
            {user.data.name?.[0]}
          </Avatar>
        </ButtonBase>
      </Badge>
      <Popover
        // sx={(theme) => ({ marginTop: theme.spacing(3) })}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: "300px", maxWidth: "100%" }}>
          <MenuList>
            <MenuItem>
              <ListItemIcon>
                <AccountCircle fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ textTransform: "capitalize" }}>
                {user.data.name}
              </ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Email fontSize="small" />
              </ListItemIcon>
              <ListItemText>{user.data.email}</ListItemText>
            </MenuItem>

            <MenuItem>
              <ListItemIcon>
                <Label fontSize="small" />
              </ListItemIcon>
              <ListItemText>Installer</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem>
              <SubmitButton
                variant="outlined"
                title={"View Profile"}
                onClick={() => {
                  navigate(`/profile/${user.data._id}`);
                  handleClose();
                }}
                // onClick={onViewInstallerButtonClick}
              ></SubmitButton>
            </MenuItem>

            <MenuItem>
              <SubmitButton
                variant="outlined"
                title={"Reset Password"}
                onClick={onResetButtonClick}
              ></SubmitButton>
            </MenuItem>
            <MenuItem>
              <SubmitButton
                title={"log Out"}
                onClick={() => {
                  dispatch(signOutAction());
                }}
              ></SubmitButton>
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
}
