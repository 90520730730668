import React, { memo, useEffect, useState } from "react";
import DashboardUi from "./DashboardUi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { openModal } from "../../store/actions/modalAction";
import CreateController from "../installer/CreateController";
import AlertDialogSlide from "../../components/Dailog";
import { Navigate } from "react-router-dom";

const DashboardController = ({ children }) => {
  const { user } = useSelector((state) => state);
  const [isUpdate, setIsUpdate] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    name: "",
    email: "",
    phone: "",
    license_number: "",
    license_expiry: moment().valueOf(),
    license_file: "",
    // subcontractor_name: "",
    subcontractor_acn: "",
    // subcontractor_phone: null,
    // subcontractor_landPhone: null,
    // subcontractor_email: "",
    subcontractor_address: "",
    guaeantor_name: "",
    guaeantor_address: "",
    rec_license_no: "",
    rec_license_date: moment().toISOString(),
    rec_license_expiry_date: moment().toISOString(),
    electrician_license_no: "",
    electrician_license_date: moment().toISOString(),
    electrician_license_expiryDate: moment().toISOString(),
    accreditation_license_no: "",
    accreditation_license_date: moment().toISOString(),
    accreditation_license_expiryDate: moment().toISOString(),
    design_license_no: "",
    design_license_date: moment().toISOString(),
    design_license_expiryDate: moment().toISOString(),
    liability_company: "",
    liability_policy_no: "",
    liability_policy_date: moment().toISOString(),
    liability_policy_expiryDate: moment().toISOString(),
    wic_company: "",
    wic_policy_no: "",
    wic_policy_date: moment().toISOString(),
    wic_policy_expiryDate: moment().toISOString(),
    pasi_company: "",
    pasi_policy_no: "",
    pasi_policy_date: moment().toISOString(),
    pasi_policy_expiryDate: moment().toISOString(),
  });

  const onEdit = () => {
    dispatch(openModal(<CreateController id={user?.data?._id} />, "sm"));
  };

  useEffect(() => {
    if (update) {
      onEdit();
    }
  }, [update]);

  useEffect(() => {
    console.log('load')
    for (let field in fields) {
      if ((!user.data[field] || user.data[field] == '') || user.data[field]?.length === 0) {
        setIsUpdate(true);

        break;
      }
    }
  }, []);

  return (
    <>
      {isUpdate && (
        <AlertDialogSlide setUpdate={setUpdate} setIsUpdate={setIsUpdate} />
      ) }
      <>
          {children}
        </>
    </>
  );
};
export default memo(DashboardController);
