import { memo } from "react";
import { Chip } from "@mui/material";
import { STATUS_TYPE } from "../../utils/constants/constants";
import {
  findObjectKeyByValue,
  inspectionStatusColor,
} from "../../utils/helpers/helper";

const InspectionStatusComponent = memo(({ params, setParams, onlyview }) => {
  return (
    <Chip
    sx={{borderRadius:1}}
      size="small"
      color={inspectionStatusColor(params?.status)}
      label={findObjectKeyByValue(params?.status, STATUS_TYPE)}
    />
  );
});
export default InspectionStatusComponent;
