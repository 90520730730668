import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/helpers/helper";

export const getInspectionsListApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.inspectionDataFetch,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  };
  
  export const getInspectionDataCountrApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.inspectionDataCount,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  };
  
  export const getInspectionByIdApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.inspectionDataById,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  
    return callResponse;
  };
  