import { Box, CircularProgress } from "@mui/material"
import { getFile } from "../../apis/file.api"
import { useEffect, useState } from "react"

const ImageComponent =  ({ src, alt, imgStyle = {} }) => {
    const [img, setImage] = useState(false)
    const [loading, setLoading] = useState(true)

    const fetchImage = async () => {
        const file = await getFile(src)
        
        setImage(URL.createObjectURL(file))
        
        setLoading(false)
    }
    useEffect(()=>{
        fetchImage()
    },[src])
    if (loading)
        return <CircularProgress />
    return <Box sx={{ width: "100%", height: "100%", background: 'lightgrey' }}>
        <img src={img} style={{ height: "100%", width: "100%", objectFit: "contain", ...imgStyle }} />
    </Box>
}
export default ImageComponent