import { useTheme } from "@emotion/react";
import {
  AccountCircle,
  CalendarMonth,
  Download,
  ElectricBolt,
  Paid,
  Place,
  ShortText,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getInspectionByIdApi } from "../../apis/inspection.api";
import ImageComponent from "../../components/ImageComponent";
import { ROOF_TYPES } from "../../utils/constants/constants";

import JobDetailsUi from "./detailed-view/JobDetailsUI";





const InspectionComponents = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const props = data;
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: "1px solid " + theme.palette.primary.main,
          borderRadius: "10px",
        }}
        component={Box}
        mt={2}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
              >
                Whom Did You Meet :-&nbsp;
                {props?.inspection_data?.meet ?? "NA"}
              </Typography>
            </Grid>
          </Grid>

          {/* Property Section */}
          <Box mt={2} sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Property
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Front Photo: &nbsp;&nbsp;
                {props?.inspection_data?.property?.front ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.property?.front}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Back Photo: &nbsp;&nbsp;
                {props?.inspection_data?.property?.back ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.property?.back}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Side Photo: &nbsp;&nbsp;
                {props?.inspection_data?.property?.side ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.property?.side}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
          </Grid>

          {/* Switch Board and MeterBox Section */}
          <Box mt={2} sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Switch Board And MeterBox
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Switch Board : &nbsp;&nbsp;
                {props?.inspection_data?.switch?.switch_board ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.switch?.switch_board}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Meter Box : &nbsp;&nbsp;
                {props?.inspection_data?.switch?.metor_box ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.switch?.metor_box}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                display="flex"
              >
                Remarks : &nbsp;&nbsp;
                {props?.inspection_data?.switch?.remarks ?? "NA"}
              </Typography>
            </Grid>
          </Grid>

          {/* Roof Section */}
          <Box mt={3} sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Roof
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Roof 1 : &nbsp;&nbsp;
                {props?.inspection_data?.roof?.[0] ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.roof?.[0]}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Roof 3 : &nbsp;&nbsp;
                {props?.inspection_data?.roof?.[2] ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.roof?.[2]}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Roof 2 : &nbsp;&nbsp;
                {props?.inspection_data?.roof?.[1] ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.roof?.[1]}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                // display="flex"
              >
                Roof 4 : &nbsp;&nbsp;
                {props?.inspection_data?.roof?.[3] ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                    }}
                  >
                    <ImageComponent
                      sx={{
                        layout: "fill",
                        objectFit: "cover",
                      }}
                      src={props?.inspection_data?.roof?.[3]}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f0f0f0",
                      color: "#777",
                    }}
                  >
                    No image found
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                display="flex"
              >
                Roof Type :&nbsp;&nbsp;
                {ROOF_TYPES[props?.inspection_data?.roof_type] ?? "NA"}
              </Typography>

              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                display="flex"
              >
                Roof Condition : &nbsp;&nbsp;
                {props?.inspection_data?.roof_condition ?? "NA"}
              </Typography>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                display="flex"
              >
                Is Roof Accessible : &nbsp;&nbsp;
                {(props?.inspection_data?.is_roof_accessible == true
                  ? "Yes"
                  : "No") ?? "NA"}
              </Typography>
            </Grid>
          </Grid>

          {/* Other Details Section */}
          <Box mt={3} sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Other Details
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                display="flex"
              >
                Panel Orientation : &nbsp;&nbsp;
                {props?.inspection_data?.panel_orientation_feasibility ?? "NA"}
              </Typography>
              <Typography
                color={"grey"}
                variant="subtitle2"
                alignItems="center"
                display="flex"
              >
                Inspection Summary : &nbsp;&nbsp;
                {data?.inspection_data?.inspection_summary ?? "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const ViewDetailsUi = () => {


  
  return <Box>

  <JobDetailsUi
    JobDetailComponent={InspectionComponents}
    title="Inspection"
    parentPath="site-installer"
    apiFun={getInspectionByIdApi} />
</Box>
};

export default ViewDetailsUi;
