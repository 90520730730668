import {
  Paper,
  Typography,
  Box,
  Card,
  CardHeader,
  IconButton,
  Collapse,
  Container,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardContent,
  Grid,
} from "@mui/material";
import { memo, useState } from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import moment from "moment";
import {
  CalendarMonth,
  Call,
  CreditCard,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MailOutline,
  Place,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const CollapsableComponent = ({ title, defaultCollpaseValue, children }) => {
  const [isIn, setIn] = useState(defaultCollpaseValue ?? false)


  return <Card
    sx={{
      border: "1px solid #7F7F7F",
      marginTop: "20px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
      padding: "16px",
      borderRadius: "8px",
    }}
  >
    <CardHeader
      sx={{
        borderBottom: isIn ? "1px solid #7F7F7F" : "none",
        height: "auto",
        padding: "10px",
      }}
      title={
        <Typography sx={{ mt: 1, fontSize: "14px", fontWeight: "bold" }}>
          {title}
        </Typography>
      }
      onClick={() => setIn(!isIn)}
      action={
        <IconButton aria-label="expand" size="small">
          {isIn ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      }
    />

    <Collapse in={isIn} timeout="auto">
      <Box sx={{ p: 2 }}>
        {children}
      </Box>
    </Collapse>
  </Card>
}
const DetailsUI = memo(
  ({ state, id, callBack, modalKey, title, loading, fields }) => {
    
    return (
      // <CustomDialog
      //   id={modalKey}
      //   loading={loading}
      //   err={fields.err}
      //   // onSubmit={onSubmit}
      //   title={`${title}`}
      //   closeText="Close"
      //   confirmText={"Create"}
      // >
      <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
        <Typography>Profile</Typography>
        <CollapsableComponent title="Personal Details"  defaultCollpaseValue={true} >

          {[
            {
              icon: <AccountCircleIcon />,
              label: "Name",
              value: fields?.name,
            },
            { icon: <MailOutline />, label: "Email", value: fields?.email },
            { icon: <Call />, label: "Phone", value: fields?.phone },
            { icon: <Place />, label: "Address", value: fields?.subcontractor_address },
            {
              icon: <CreditCard />,
              label: "ACN No",
              value: fields?.subcontractor_acn,
            },
            {
              icon: <CreditCard />,
              label: "Licence No",
              value: fields?.license_number,
            },
            {
              icon: <CalendarMonth />,
              label: "Licence Expiry",
              value: fields?.license_expiry
                ? moment(fields.license_expiry).format("DD/MM/YYYY")
                : "NA",
            },
          ].map(({ icon, label, value }, index) => (
            <Grid container key={index} sx={{ mb: 1 }}>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "12px", sm: "15px" },
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {icon} &nbsp;&nbsp;{label}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", sm: "15px" },
                    whiteSpace: "nowrap", // Prevent text wrap
                  }}
                >
                  {value || "NA"}
                </Typography>
              </Grid>
            </Grid>
          ))}

        </CollapsableComponent>

        {/* <CollapsableComponent title="Subcontractor"  ></CollapsableComponent> */}
        <CollapsableComponent title="Guarantor  Details"  >
          <Grid container spacing={2}>


            {/* Guarantor Name */}
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                Guarantor Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "12px" }}>
                {fields?.guaeantor_name || "NA"}
              </Typography>
            </Grid>

            {/* Guarantor Address */}
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                Guarantor Address
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "12px" }}>
                {fields?.guaeantor_address || "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CollapsableComponent>
        <CollapsableComponent title="Public Liability Insurance"  >
          <Box >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#555",
                  }}
                >
                  Liability Company:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333",
                  }}
                >
                  {fields?.liability_company || "NA"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#555",
                  }}
                >
                  Policy No:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333",
                  }}
                >
                  {fields?.liability_policy_no || "NA"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#555",
                  }}
                >
                  Policy Issue Date:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333",
                  }}
                >
                  {fields?.liability_policy_date
                    ? moment(fields?.liability_policy_date).format(
                      "DD/MM/YYYY"
                    )
                    : "NA"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#555",
                  }}
                >
                  Policy Expiry Date:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#333",
                  }}
                >
                  {fields?.liability_policy_expiryDate
                    ? moment(fields?.liability_policy_expiryDate).format(
                      "DD/MM/YYYY"
                    )
                    : "NA"}
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </CollapsableComponent>
        <CollapsableComponent title="Workers Compensation Insurance"  >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                  lineHeight: "100%",
                }}
              >
                Company
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {fields?.wic_company ? fields?.wic_company : "NA"}
              </Typography>
            </Grid>

            {/* Policy No Field */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                  lineHeight: "100%",
                }}
              >
                Policy No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {fields?.wic_policy_no ? fields?.wic_policy_no : "NA"}
              </Typography>
            </Grid>

            {/* Policy Issue Date Field */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                  lineHeight: "100%",
                }}
              >
                Policy Issue Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.wic_policy_date
                  ? moment(fields?.wic_policy_date).format("DD/MM/YYYY")
                  : "NA"}
              </Typography>
            </Grid>

            {/* Policy Expiry Date Field */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                  lineHeight: "100%",
                }}
              >
                Policy Expiry Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.wic_policy_expiryDate
                  ? moment(fields?.wic_policy_expiryDate).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CollapsableComponent>
        <CollapsableComponent title="Personal Accident And Sickness Insurance"  >

          <Grid container spacing={1}>
            {/* Company */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" }, // Responsive font size
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Company
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" }, // Responsive font size
                  whiteSpace: "nowrap", // Ensures this content stays on one line
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {fields?.pasi_company ? fields?.pasi_company : "NA"}
              </Typography>
            </Grid>

            {/* Policy No */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {fields?.pasi_policy_no ? fields?.pasi_policy_no : "NA"}
              </Typography>
            </Grid>

            {/* Policy Issue Date */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy Issue Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {fields?.pasi_policy_date
                  ? moment(fields?.pasi_policy_date).format("DD/MM/YYYY")
                  : "NA"}
              </Typography>
            </Grid>

            {/* Policy Expiry Date */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy Expiry Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {fields?.pasi_policy_expiryDate
                  ? moment(fields?.pasi_policy_expiryDate).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CollapsableComponent>

        <CollapsableComponent title="Registered Electrical Contractor (REC)"  >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.rec_license_no ? fields?.rec_license_no : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License Issue Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.rec_license_date
                  ? moment(fields?.rec_license_date).format("DD/MM/YYYY")
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy Expiry Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.rec_license_expiry
                  ? moment(fields?.rec_license_expiry).format("DD/MM/YYYY")
                  : "NA"}
              </Typography>
            </Grid>
          </Grid></CollapsableComponent>
        <CollapsableComponent title="Electrician's License (A Class)"  >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.electrician_license_no
                  ? fields?.electrician_license_no
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License Issue Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.electrician_license_date
                  ? moment(fields?.electrician_license_date).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy Expiry Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.electrician_license_expiryDate
                  ? moment(fields?.electrician_license_expiryDate).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CollapsableComponent>
        <CollapsableComponent title="  SAA Accreditation"  >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields.accreditation_license_no
                  ? fields.accreditation_license_no
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License Issue Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields.accreditation_license_date
                  ? moment(fields.accreditation_license_date).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy Expiry Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields.accreditation_license_expiryDate
                  ? moment(fields.accreditation_license_expiryDate).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
          </Grid></CollapsableComponent>

        <CollapsableComponent title=" SAA Design License"  >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License No
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.design_license_no
                  ? fields?.design_license_no
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                License Issue Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.design_license_date
                  ? moment(fields?.design_license_date).format("DD/MM/YYYY")
                  : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  fontWeight: "bold",
                }}
                variant="subtitle2"
              >
                Policy Expiry Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  whiteSpace: "nowrap",
                }}
              >
                {fields?.design_license_expiryDate
                  ? moment(fields?.design_license_expiryDate).format(
                    "DD/MM/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CollapsableComponent>
      </Box>
      // </CustomDialog>
    );
  }
);

export default DetailsUI;
