import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import CustomInput from "./CustomInput"

const SelectDropDown = ({ title, value, onChange, data,...props }) => {


    return <FormControl  size="small" sx={{ background: "white" }} fullWidth  {...props}>
        <InputLabel id={title} >{title}</InputLabel>
        <Select
        labelId={title}
            disableClearable
            
            label={title}
            value={value}
           
            fullWidth
            onChange={onChange}

            renderInput={(params) => <CustomInput  {...params} size="small" />}
            {...props}
        >
            {
                data && Array.isArray(data) && data?.map((item) => <MenuItem value={item.value} >{item.label}</MenuItem>)
            }


        </Select>

    </FormControl>
}
export default SelectDropDown