import React, { memo, useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Delete, HideImageOutlined, Image, Upload } from "@mui/icons-material";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import ImageComponent from "../../../components/ImageComponent";
import FileInputImage from "../../../components/inputs/FileInputImage";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import CustomInput from "../../../components/inputs/CustomInput";
import {
  CATEGORY,
  LECKAGE_REASON,
  ROOF_TYPES,
  SNACK_BAR_VARIETNS,
} from "../../../utils/constants/constants";
import { YES_NO } from "../../../utils/constants/task.constants";
import {
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../../utils/helpers/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SelectDropDown from "../../../components/inputs/SelectDropDown";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageUploadComponent = memo(
  ({ loading, onChange, percentage, onDeleteFile, files, name, camp_id }) => {
    // const selectedComponet = useSelector((state) => state.selectedComponent.ivalue);

    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);

    const deleteRecordFromDb = async (callBack = () => {}) => {
      dispatch(
        callApiAction(
          // async () => await deleteCampImagesField({ camp_id, image_name: name }),
          async (response) => {
            callBack(response);
          },
          (err) => {
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
            callBack();
          }
        )
      );
    };

    const onDelete = () => {
      setDeleteLoading(true);
      onDeleteFile(files[0], () => {
        deleteRecordFromDb(() => {
          setDeleteLoading(false);
        });
      });
    };

    return (
      <Box component={Paper} sx={{ width: "100%", background: "#e5e5e5" }}>
        <Box px={2} pt={1} pb={1}>
          <Typography variant="subtitle2" fontWeight={600} lineHeight="100%">
            {toTitleCase(name?.replace(/_/g, " "))}
          </Typography>
        </Box>
        <Box
          component={Paper}
          elevation={0}
          mt={1}
          mb={1}
          sx={{
            display: "flex",
            width: "100%",
            position: "relative",
            paddingTop: "75%",
            border: "1px solid gray",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              width: "100%",
              height: "100%",
            }}
          >
            {files[0] && !loading && !deleteLoading ? (
              <ImageComponent
                imgStyle={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={files[0]}
              />
            ) : (
              <CenteredBox>
                {loading ? (
                  <LinearProgress
                    sx={{ zIndex: 1, width: "100%" }}
                    variant="determinate"
                    value={percentage}
                  />
                ) : deleteLoading ? (
                  <CircularProgress />
                ) : (
                  <HideImageOutlined />
                )}
              </CenteredBox>
            )}
          </Box>
        </Box>
        <Box>
          <Box>
            {!files[0] && (
              <Button
                sx={{ width: "100%" }}
                component="label"
                mt={2}
                startIcon={<Upload />}
                disableElevation
                disabled={loading || deleteLoading}
                variant="contained"
              >
                {/* <Upload />Upload */}
                <Typography sx={{ fontSize: "12px" }}>Upload</Typography>
                <VisuallyHiddenInput
                  // accept="image/*"
                  accept="image/*"
                  capture="camera"
                  onChange={onChange}
                  type="file"
                />
              </Button>
            )}

            {files[0] && (
              <Button
                onClick={onDelete}
                component="label"
                color="error"
                mt={2}
                startIcon={<Delete />}
                disableElevation
                disabled={loading || deleteLoading}
                variant="contained"
                fullWidth
              >
                {/* <Upload />Upload */}
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

const PostInstallationFormUI = ({ fields, setFields, onSubmit, loading }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  console.log("fieldsss", fields.installation_data.category);
  const navigate = useNavigate();
  return (
    <>
      <Box
        mb={2}
        sx={{
          padding: "10px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          border: "1px solid #ccc",
          // position:"fixed"
        }}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/jobs")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>jobs</Typography>
          </Link>
          <Link
            underline="hover"
            color="black"
            onClick={() => navigate("/Post-Installation")}
            sx={{ cursor: "pointer", color: "black" }}
          >
            <Typography sx={{ color: "text.primary" }}>
              Post-Installation
            </Typography>
          </Link>
          <Typography sx={{ color: "text.primary" }}>Form</Typography>
        </Breadcrumbs>
      </Box>

      <Box p={3} sx={{ backgroundColor: "white" }}>
        <Grid item mt={3} xs={12}>
          {/* <Typography
            color={"error"}
            sx={{  fontSize: "15px" }}
          >
            {fields.err}
          </Typography> */}
          <Autocomplete
            // disablePortal
            disabled={loading}
            onChange={(e, changedVal) => {
              setFields({
                ...fields,
                err: "",
                installation_data: {
                  ...fields.installation_data,
                  category: changedVal ? changedVal._id : null,
                },
              });
            }}
            value={findObjectKeyByValue(
              fields.installation_data.category,
              CATEGORY
            )}
            options={[
              // { label: "All", _id: null },
              ...Object.keys(CATEGORY).map((key) => ({
                label: titleCase(key),
                _id: CATEGORY[key],
              })),
            ]}
            sx={{
              width: "100%",
              // marginX:"5px",
              display: "flex",
              "*": { display: "flex", justifyContent: "center" },
            }}
            renderInput={(params) => (
              <CustomInput placeholder="Select Category" {...params} />
            )}
          />
        </Grid>
        {fields.installation_data.category == CATEGORY.INVERTER_ISSUE && (
          <>
            <Typography
              sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
            >
              Old Inverter Photos
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FileInputImage
                  disabled={loading}
                  onlyImage
                  defaults={
                    fields?.front_photo ? [fields?.front_photo] : undefined
                  }
                  componentProps={{
                    name: "Front Photo :",
                    url: fields?.front_photo,
                  }}
                  onChange={(newUrl) => {
                    setFields({
                      ...fields,
                      installation_data: {
                        ...fields?.installation_data,
                        old_inverter_photos: {
                          ...fields?.installation_data.old_inverter_photos,
                          front_photo: newUrl,
                        },
                      },
                    });
                  }}
                  onDelete={() => {
                    setFields({ ...fields, front_photo: "" });
                  }}
                  FileComponent={ImageUploadComponent}
                />
              </Grid>

              <Grid item xs={6}>
                <FileInputImage
                  disabled={loading}
                  onlyImage
                  defaults={
                    fields?.inverter_screen_photo
                      ? [fields?.inverter_screen_photo]
                      : undefined
                  }
                  componentProps={{
                    name: "Inverter Photo :",
                    url: fields?.inverter_screen_photo,
                  }}
                  onChange={(newUrl) => {
                    setFields({
                      ...fields,
                      installation_data: {
                        ...fields?.installation_data,
                        old_inverter_photos: {
                          ...fields?.installation_data?.old_inverter_photos,
                          inverter_screen_photo: newUrl,
                        },
                      },
                    });
                  }}
                  onDelete={() => {
                    setFields({ ...fields, inverter_screen_photo: "" });
                  }}
                  FileComponent={ImageUploadComponent}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  // autoFocus={true}
                  disabled={loading}
                  value={
                    fields?.installation_data?.old_inverter_photos
                      ?.serial_number_label
                  }
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      installation_data: {
                        ...fields?.installation_data,
                        old_inverter_photos: {
                          ...fields?.installation_data?.old_inverter_photos,
                          serial_number_label: e.target.value,
                        },
                      },
                    })
                  }
                  type="text"
                  label={"Serial Number Label *"}
                />
              </Grid>
            </Grid>

            <Typography
              sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
            >
              New Inverter Photos
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FileInputImage
                  disabled={loading}
                  onlyImage
                  defaults={
                    fields?.front_photo ? [fields?.front_photo] : undefined
                  }
                  componentProps={{
                    name: "Front Photo :",
                    url: fields?.front_photo,
                  }}
                  onChange={(newUrl) => {
                    setFields({
                      ...fields,
                      installation_data: {
                        ...fields?.installation_data,
                        new_inverter_photos: {
                          ...fields?.installation_data?.new_inverter_photos,
                          front_photo: newUrl,
                        },
                      },
                    });
                  }}
                  onDelete={() => {
                    setFields({ ...fields, front_photo: "" });
                  }}
                  FileComponent={ImageUploadComponent}
                />
              </Grid>

              <Grid item xs={6}>
                <FileInputImage
                  disabled={loading}
                  onlyImage
                  defaults={
                    fields?.inverter_screen_photo
                      ? [fields?.inverter_screen_photo]
                      : undefined
                  }
                  componentProps={{
                    name: "Inverter Photo :",
                    url: fields?.inverter_screen_photo,
                  }}
                  onChange={(newUrl) => {
                    setFields({
                      ...fields,
                      installation_data: {
                        ...fields?.installation_data,
                        new_inverter_photos: {
                          ...fields?.installation_data?.new_inverter_photos,
                          inverter_screen_photo: newUrl,
                        },
                      },
                    });
                  }}
                  onDelete={() => {
                    setFields({ ...fields, inverter_screen_photo: "" });
                  }}
                  FileComponent={ImageUploadComponent}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  // autoFocus={true}
                  disabled={loading}
                  value={
                    fields?.installation_data?.new_inverter_photos
                      ?.serial_number_label
                  }
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      installation_data: {
                        ...fields?.installation_data,
                        new_inverter_photos: {
                          ...fields?.installation_data?.new_inverter_photos,
                          serial_number_label: e.target.value,
                        },
                      },
                    })
                  }
                  type="text"
                  label={"Serial Number Label*"}
                />
              </Grid>
              <Grid item xs={6}>
                <FileInputImage
                  disabled={loading}
                  onlyImage
                  defaults={
                    fields.ac_voltage_photo
                      ? [fields.ac_voltage_photo]
                      : undefined
                  }
                  componentProps={{
                    name: "AC Voltage Photo :",
                    url: fields.ac_voltage_photo,
                  }}
                  onChange={(newUrl) => {
                    setFields({
                      ...fields,
                      installation_data: {
                        ...fields.installation_data,
                        new_inverter_photos: {
                          ...fields.installation_data.new_inverter_photos,
                          ac_voltage_photo: newUrl,
                        },
                      },
                    });
                  }}
                  onDelete={() => {
                    setFields({ ...fields, ac_voltage_photo: "" });
                  }}
                  FileComponent={ImageUploadComponent}
                />
              </Grid>
              <Grid item xs={6}>
                <FileInputImage
                  disabled={loading}
                  onlyImage
                  defaults={
                    fields.dc_voltage_photo
                      ? [fields.dc_voltage_photo]
                      : undefined
                  }
                  componentProps={{
                    name: "DC Voltage Photo :",
                    url: fields.dc_voltage_photo,
                  }}
                  onChange={(newUrl) => {
                    setFields({
                      ...fields,
                      installation_data: {
                        ...fields.installation_data,
                        new_inverter_photos: {
                          ...fields.installation_data.new_inverter_photos,
                          dc_voltage_photo: newUrl,
                        },
                      },
                    });
                  }}
                  onDelete={() => {
                    setFields({ ...fields, dc_voltage_photo: "" });
                  }}
                  FileComponent={ImageUploadComponent}
                />
              </Grid>
            </Grid>
          </>
        )}
        {fields.installation_data.category == CATEGORY.LEAKAGE_ISSUE && (
          <>
            <Grid container mt={2}>
              <Typography
                sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
              >
                Images Ceiling Leakage
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.images_ceiling_leakage
                        ? [fields.images_ceiling_leakage]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 1.",
                      url: fields.images_ceiling_leakage,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          images_ceiling_leakage: [
                            ...fields.installation_data.images_ceiling_leakage,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, images_ceiling_leakage: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.images_ceiling_leakage
                        ? [fields.images_ceiling_leakage]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 2.",
                      url: fields.images_ceiling_leakage,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          images_ceiling_leakage: [
                            ...fields.installation_data.images_ceiling_leakage,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, images_ceiling_leakage: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.images_ceiling_leakage
                        ? [fields.images_ceiling_leakage]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 3.",
                      url: fields.images_ceiling_leakage,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          images_ceiling_leakage: [
                            ...fields.installation_data.images_ceiling_leakage,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, images_ceiling_leakage: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.images_ceiling_leakage
                        ? [fields.images_ceiling_leakage]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 4.",
                      url: fields.images_ceiling_leakage,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          images_ceiling_leakage: [
                            ...fields.installation_data.images_ceiling_leakage,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, images_ceiling_leakage: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <SelectDropDown
                    disabled={loading}
                    title="Leakage Reason"
                    value={fields.installation_data.leakage_reason}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        err: "",
                        installation_data: {
                          ...fields.installation_data,
                          leakage_reason: parseInt(e.target.value),
                        },
                      });
                    }}
                    data={Object.keys(LECKAGE_REASON).map((item) => ({
                      value: item,
                      label: LECKAGE_REASON[item],
                    }))}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Typography
                sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
              >
                Issues Photos
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.issue_photos ? [fields.issue_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 1.",
                      url: fields.issue_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          issue_photos: [
                            ...fields.installation_data.issue_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, issue_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.issue_photos ? [fields.issue_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 2.",
                      url: fields.issue_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          issue_photos: [
                            ...fields.installation_data.issue_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, issue_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.issue_photos ? [fields.issue_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 3.",
                      url: fields.issue_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          issue_photos: [
                            ...fields.installation_data.issue_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, issue_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.issue_photos ? [fields.issue_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 4.",
                      url: fields.issue_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          issue_photos: [
                            ...fields.installation_data.issue_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, issue_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <CustomInput
                    // autoFocus={true}
                    disabled={loading}
                    value={fields.installation_data.issue_description}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        installation_data: {
                          ...fields.installation_data,
                          issue_description: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label={"Issues Description*"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Typography
                sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
              >
                Repair Photos
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.repair_photos ? [fields.repair_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 1.",
                      url: fields.repair_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          repair_photos: [
                            ...fields.installation_data.repair_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, repair_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.repair_photos ? [fields.repair_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 2.",
                      url: fields.repair_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          repair_photos: [
                            ...fields.installation_data.repair_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, repair_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.repair_photos ? [fields.repair_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 3.",
                      url: fields.repair_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          repair_photos: [
                            ...fields.installation_data.repair_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, repair_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.repair_photos ? [fields.repair_photos] : undefined
                    }
                    componentProps={{
                      name: "Image 4.",
                      url: fields.repair_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          repair_photos: [
                            ...fields.installation_data.repair_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, repair_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <CustomInput
                    // autoFocus={true}
                    disabled={loading}
                    value={fields.installation_data.repair_description}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        installation_data: {
                          ...fields.installation_data,
                          repair_description: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label={"Repair Description *"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {fields.installation_data.category ==
          CATEGORY.SOLAR_PANAL_NOT_WORKING && (
          <>
            <Grid container mt={2}>
              <Typography
                sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
              >
                AC DC Power Volatage Photos
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.ac_dc_power_voltage_photos
                        ? [fields.ac_dc_power_voltage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 1.",
                      url: fields.ac_dc_power_voltage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          ac_dc_power_voltage_photos: [
                            ...fields.installation_data
                              .ac_dc_power_voltage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, ac_dc_power_voltage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.ac_dc_power_voltage_photos
                        ? [fields.ac_dc_power_voltage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 2.",
                      url: fields.ac_dc_power_voltage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          ac_dc_power_voltage_photos: [
                            ...fields.installation_data
                              .ac_dc_power_voltage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, ac_dc_power_voltage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.ac_dc_power_voltage_photos
                        ? [fields.ac_dc_power_voltage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 3.",
                      url: fields.ac_dc_power_voltage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          ac_dc_power_voltage_photos: [
                            ...fields.installation_data
                              .ac_dc_power_voltage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, ac_dc_power_voltage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.ac_dc_power_voltage_photos
                        ? [fields.ac_dc_power_voltage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 4.",
                      url: fields.ac_dc_power_voltage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          ac_dc_power_voltage_photos: [
                            ...fields.installation_data
                              .ac_dc_power_voltage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, ac_dc_power_voltage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item mt={2} xs={12}>
              <FormControl fullWidth>
                <FormLabel>Is Panel Damage?</FormLabel>
                <RadioGroup
                  row
                  value={fields.installation_data.panel_damage}
                  onChange={(e) => {
                    setFields({
                      ...fields,
                      err: "",
                      installation_data: {
                        ...fields.installation_data,
                        panel_damage: e.target.value,
                      },
                    });
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container mt={2}>
              <Typography
                sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
              >
                Panel Damage Photos
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.panel_damage_photos
                        ? [fields.panel_damage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 1.",
                      url: fields.panel_damage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          panel_damage_photos: [
                            ...fields.installation_data,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, panel_damage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.panel_damage_photos
                        ? [fields.panel_damage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 2.",
                      url: fields.panel_damage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          panel_damage_photos: [
                            ...fields.installation_data.panel_damage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, panel_damage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.panel_damage_photos
                        ? [fields.panel_damage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 3.",
                      url: fields.panel_damage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          panel_damage_photos: [
                            ...fields.installation_data.panel_damage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, panel_damage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={
                      fields.panel_damage_photos
                        ? [fields.panel_damage_photos]
                        : undefined
                    }
                    componentProps={{
                      name: "Image 4.",
                      url: fields.panel_damage_photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          panel_damage_photos: [
                            ...fields.installation_data.panel_damage_photos,
                            newUrl,
                          ],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, panel_damage_photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <CustomInput
                    // autoFocus={true}
                    disabled={loading}
                    value={fields.installation_data.reason_panel_not_working}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        installation_data: {
                          ...fields.installation_data,
                          reason_panel_not_working: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label={"Reason For Panel Not Working *"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {fields.installation_data.category == CATEGORY.WIFI_CONFIGRATION && (
          <>
            <Grid item xs={12} mt={1}>
              <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields.installation_data.wifi_configuration}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    installation_data: {
                      ...fields.installation_data,
                      wifi_configuration: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Wifi Configuration *"}
              />
            </Grid>
          </>
        )}

        {fields.installation_data.category == CATEGORY.OTHERS && (
          <>
            <Grid item xs={12} mt={1}>
              <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields.installation_data.reason}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    installation_data: {
                      ...fields.installation_data,
                      reason: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Reason*"}
              />
            </Grid>

            <Grid container mt={2}>
              <Typography
                sx={{ fontWeight: "bold", color: "black", fontSize: "20px" }}
              >
                Photos (Minimum 2)
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={fields.photos ? [fields.photos] : undefined}
                    componentProps={{
                      name: "Image 1.",
                      url: fields.photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          photos: [...fields.installation_data.photos, newUrl],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={fields.photos ? [fields.photos] : undefined}
                    componentProps={{
                      name: "Image 2.",
                      url: fields.photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          photos: [...fields.installation_data.photos, newUrl],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={fields.photos ? [fields.photos] : undefined}
                    componentProps={{
                      name: "Image 3.",
                      url: fields.photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          photos: [...fields.installation_data.photos, newUrl],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FileInputImage
                    disabled={loading}
                    onlyImage
                    defaults={fields.photos ? [fields.photos] : undefined}
                    componentProps={{
                      name: "Image 4.",
                      url: fields.photos,
                    }}
                    onChange={(newUrl) => {
                      setFields({
                        ...fields,
                        installation_data: {
                          ...fields.installation_data,
                          photos: [...fields.installation_data.photos, newUrl],
                        },
                      });
                    }}
                    onDelete={() => {
                      setFields({ ...fields, photos: "" });
                    }}
                    FileComponent={ImageUploadComponent}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} mt={1}>
              <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields.installation_data.resolution}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    installation_data: {
                      ...fields.installation_data,
                      resolution: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Resolution*"}
              />
            </Grid>
          </>
        )}

        <Grid item mt={2} xs={12}>
          <Box
            width={"100%"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{ width: "100%" }}
            >
              <Typography>Submit</Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default PostInstallationFormUI;
